export const ErrorCodes = {
  INCORRECT_PASSWORD: 'INCORRECT_PASSWORD',
  USER_ALREADY_EXIST: 'USER_ALREADY_EXIST',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
  USER_CREATED: 'USER_CREATED',
  DELETED_ACCOUNT: 'DELETED_ACCOUNT',
  PROJECT_NOT_FOUND: 'PROJECT_NOT_FOUND',
  SUBSCRIPTION_NOT_FOUND: 'SUBSCRIPTION_NOT_FOUND',
  ALREADY_EMPLOYEE: 'ALREADY_EMPLOYEE',
  success: 'SUCCESS',
  INVALID_DATA: 'INVALID_DATA',
  INVALID_TOKEN: 'INVALID_TOKEN',
  NO_TOKEN_PROVIDE: 'NO_TOKEN_PROVIDE',
  RESOURCE_ALREADY_EXISTS: 'RESOURCE_ALREADY_EXISTS',
  PERMISSION_ALREADY_EXISTS: 'PERMISSION_ALREADY_EXISTS',
  COMPANY_BILLING_ADDRESS_NOT_CREATED: 'COMPANY_BILLING_ADDRESS_NOT_CREATED',
  COMPANY_ADDRESS_NOT_CREATED: 'COMPANY_ADDRESS_NOT_CREATED',
  COMPANY_NOT_CREATED: 'COMPANY_NOT_CREATED',
  SUBSCRIPTION_NOT_CREATED: 'SUBSCRIPTION_NOT_CREATED',
  USER_NOT_HAVE_SUBSCRIPTION: 'USER_NOT_HAVE_SUBSCRIPTION',
  NOT_VALID_TOKEN: 'NOT_VALID_TOKEN',
  PROMO_CODE_ALREADY_USED: 'PROMO_CODE_ALREADY_USED',
  PROMO_CODE_NOT_FOUND: 'PROMO_CODE_NOT_FOUND',
  NEWSLETTER_USER_ALREADY_EXISTS: 'NEWSLETTER_USER_ALREADY_EXISTS',
  GIFT_CARD_ALREADY_REDEEMED: 'GIFT_CARD_ALREADY_REDEEMED'
};
