import { Component, Inject, OnInit } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { BaseComponent } from 'src/app/pages/_base/base/base.component';
import { environment } from 'src/environments/environment';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-expert-dialog',
  templateUrl: './expert-dialog.component.html',
  styleUrls: ['./expert-dialog.component.scss']
})
export class ExpertDialogComponent extends ModalComponent implements OnInit {
  lang: string = localStorage.getItem('lang') || "en";
  faClose = faClose;
  updated: boolean = false;
  utm_medium: string = 'HP_topnav';
  projects = ['/projects','/about/restoration','/about/preservation','/about/renewable_energy','/about/marine_restoration']
  others = ['/about','/nft','/gift','/termsConditions','/privacyPolicy','/cookiePolicy','/monthlyClimateSubscription']

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ExpertDialogComponent>, private gtmService: GoogleTagManagerService) {
    super(data);
  }

  ngOnInit(): void {
  }

  createBookADemoLink(){
    let path = window.location.pathname;
    const pathMap = {
      '/': "HP_topnav",
      '/carbon_neutral_solutions': 'Solutions_topnav',
      '/carbon_footprint_management': 'Carbon_topnav',
      '/carbon-offset-biodiversity-token': 'Offset_topnav',
      '/climatePositiveSubscription': 'Positive_topnav',
      '/sustainability_plugin': 'Plugin_topnav',
      '/whitelabel_environmental_communication': 'WL_topnav',
      '/environmental-sustainability-advisory' : 'Advisory_topnav',
      '/climate-solutions-partners' : 'CSPartners_topnav',
      '/strategic-partners': 'StrategicPartners_topnav',
      ...Object.fromEntries(
        this.projects.map(project=> [project, 'Projects_topnav'])
      ),
      '/decarbonisation_tech_alliance': 'DTA_topnav',
      '/faq': 'Contact_topnav',
      '/soluzioni_carbon_neutral': 'Solutions_topnav',
      '/gestione_impronta_carbonio': 'Carbon_topnav',
      '/crediti-di-carbonio-token-biodiversita': 'Offset_topnav',
      '/carbon_offset_API': 'Plugin_topnav',
      '/comunicazione_sostenibilita_whitelabel': 'WL_topnav',
      '/consulenza-sostenibilita-ambientale': 'Advisory_topnav',
      '/partners-strategici': 'StrategicPartners_topnav',
      ...Object.fromEntries(
        this.others.map(other=> [other, 'Other_topnav'])
      )

  }
    this.utm_medium = path.toString().includes('projects') ? 'Projects_topnav' : pathMap[path]
    this.bookADemo(this.utm_medium);
  }

  bookADemo(type: string = 'website', open: boolean = true) {
    this.identify('Book_demo_' + type, 'Hubspot');
    let meetingLink = 'https://page.greenfutureproject.com/request-demo?utm_source=website_ENG&utm_medium=' + type + '&utm_campaign=demo';
    if (localStorage.getItem('lang') === 'it') {
      meetingLink = 'https://page.greenfutureproject.com/richiedi-demo?utm_source=website_ITA&utm_medium=' + type + '&utm_campaign=demo';
    }
    if (open) {
      window.open(
        meetingLink,
        '_blank'
      );
    } else {
      return meetingLink;
    }
  }

  identify(event: string, method: string): void {
    const gtmTag = {
      event: event,
      method: method
    };
    if (!environment.production) {
      console.log(gtmTag);
    }
    this.gtmService.pushTag(gtmTag);
  }

  update() {
    this.updated = true;
  }

  close(): void {
    this.dialogRef.close();
  }
}
