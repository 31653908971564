// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// let api = 'https://greenfutureproject.com:8443/api';
// let api = 'https://greenfutureproject.com/api';
let api = 'https://staging.greenfutureproject.com:9443/api';
// let api = 'https://staging.greenfutureproject.com/api';
// let api = 'http://localhost:3000/api';

export const environment = {
  production: false,

  apiUrl: api,
  authApiUrl: api,
  publicApiUrl: api,

  loginApiToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21wYW55IjoiR2F0ZURldiIsInByb2plY3QiOiJHcmVlbiBGdXR1cmUgUHJvamVjdCIsImlkIjotMjIwNywiaWF0IjoxNTE2MjM5MDIyfQ.KdsT7Ld2mwvu6VCMl-u7G4KBrSpEnyUKpDq7xemdIHg',
  publicApiToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21wYW55IjoiR2F0ZURldiIsInByb2plY3QiOiJHcmVlbiBGdXR1cmUgUHJvamVjdCIsInNpdGUiOiJwdWJsaWMiLCJpZCI6LTIyMDcsImlhdCI6MTUxNjIzOTAyMn0.Dm3v1gqv-PrClFKyW95_Ce0ODXlEuggRdWr9u24GFB8',

  stripePublicKey: 'pk_test_51HUZX9I73UjfvGcA1CmG8l1Xcmhv85eT18qMZjs9uxMRi7EbxcgUoOOLLMRMOgHYRDTrtvarrkBCkNah0eokSoFi00v111orCh',

  facebookClientId: '1921669641329613',
  googleClientId: '340585749385-vn0deamo33rbtk9g76llr2npmbco37oa.apps.googleusercontent.com',
  googleMapsApiKey: 'AIzaSyAJ82q6LHVYJZw9nsLoUHc36UPW1QHAnGU',
  googleTagManagerId: 'GTM-TJB4F6S',
  openWeatherKey: '0b542c3b123e7eb980fe56200d0682dd',
  hubspotApiKey: 'pat-eu1-927c8fcd-57b3-45a2-881c-0ed6cd5dba3b',
  hubspotPortalId: '27009746',
  hubspotBusinessFormId: 'f9cfc6ee-ae54-4d4b-bcfc-17d02b79be49',
  hubspotIndividualFormId: '7dd81f75-8725-462e-b5e8-721337797fb1',
  hubspotApiUrl: 'https://api.hsforms.com/submissions/v3/integration/submit',
  pilioLoginUrl: 'https://test.greenfutureproject.piliogroup.com/users/verify_magic_link',
  augmentalLoginUrl: 'https://www.academy.greenfutureproject.com/login',
  url: 'https://greenfutureproject.com',
  enableImpact: true,
  showSettings: true,
  enableShare: true,
  enableSupport: true,
  showStartNowPlan: true,
  showJoinTeam: true,
  showSubscriptionPlans: true,
  showBusiness: true,
  pixelEnable: false,
  showApi: true,
  showDownloadCard: true,
  showSocialsLogin: true,
  awsBaseUrl: 'https://green-future-project.s3.eu-central-1.amazonaws.com',
  nativaApiFeatureFlag: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// b76546bebdcb8b055a838c38d0219c77
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
