import {Component, Input, OnInit} from '@angular/core';
import {ProjectTypes} from '../../../../_models/components/project-types';
import {CountersInterface} from '../../../../_models/api/counters-interface';
import {CountersDataInterface} from '../../../../_models/api/counters-data-interface';
import {CounterValues} from '../../../../_models/components/counter-values';
import {DownloadReportComponent} from '../download-report/download-report.component';
import {environment} from '../../../../../environments/environment';
//import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { ImpactCardComponent } from '../impact-card/impact-card.component';
import { faLassoSparkles } from '@fortawesome/pro-regular-svg-icons';

@Component({
    selector: 'app-real-data',
    templateUrl: './real-data.component.html',
    styleUrls: ['./real-data.component.scss']
})
export class RealDataComponent extends DownloadReportComponent implements OnInit {
    @Input() type: string;
    @Input() countersInterface: CountersInterface;
    @Input() totalCo2: number;
    @Input() co2Certified: number;
    @Input() co2NotCertified: number;
    @Input() name: string;
    @Input() id: string;
    @Input() m2: number;
    @Input() renewable: number;
    @Input() renewableUnit: string;
    @Input() trees: string;
    @Input() public: boolean = false;
    @Input() enableShare = false;
    @Input() reteclima = false;
    @Input() adnoc = false;
    @Input() aldar = false;
    @Input() tgi = false;
    @Input() delsey = false;
    @Input() whitelabel = false;

    countersData: CountersDataInterface;
    projectTypes = ProjectTypes;
    url = environment.url;
    clicked: boolean = false;
    value = 0;
    filename: string;
    cleanName: string;
    countImpactTypes = 0;
    pluralS = '';

    kmRomeNewYork = 6883.87;
    tonsCo2Km = 6700;
    flights = 0;

    constructor(public dialog: MatDialog) {
      super();
    }

    ngOnInit(): void {
      if(this.countImpactTypes > 1){
        this.pluralS = 's';
      }
      this.cleanName = this.name ? this.name.replace("'s", "") : 'N/A';
      this.filename = this.cleanName + '-data-' + this.type + '.png';
      if (this.type === 'co2') {
        this.countersData = this.countersInterface.tonnes;
        this.flights = Math.round(this.totalCo2 / 1000 * this.tonsCo2Km / this.kmRomeNewYork);
      }
      if (this.type === ProjectTypes.preservation) {
        this.countersData = this.countersInterface.preservation;
        this.value = this.roundNumber(this.countersData?.unit * CounterValues.hectare_tennisCourt);
      }
      if (this.type === ProjectTypes.restoration) {
        this.countersData = this.countersInterface.restoration;
        this.value = this.roundNumber(this.countersData?.unit * CounterValues.tree_tennisCourt);
      }
      if (this.type === ProjectTypes.renewable_energy) {
        this.countersData = this.countersInterface.renewable_energy;
        this.value = this.roundNumber(this.countersData?.unit * CounterValues.kw_household);
      }
    }

    openImpactPreview = () => {
      this.dialog.open(ImpactCardComponent, {data: {
        type: this.type,
        co2: this.totalCo2,
        m2: this.m2,
        trees: this.trees,
        renewable: this.renewable,
        renewableUnit: this.renewableUnit,
        co2Certified: this.co2Certified,
        co2NotCertified: this.co2NotCertified,
        clientName: this.cleanName,
        reteclima: this.reteclima,
        adnoc: this.adnoc,
        aldar: this.aldar,
        tgi: this.tgi
      }});
    }
}
