import { AfterViewInit, Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Navigation, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import {MatDrawerMode, MatSidenav} from '@angular/material/sidenav';
import {UsersService} from '../../_services/users/users.service';
import {StorageName} from '../../_models/components/storage-name';
import {Response} from '../../_models/api/response';
import {UsersInterface} from '../../_models/api/users/users-interface';
import {BaseComponent} from '../../pages/_base/base/base.component';
import {CompanyEmployeesInterface} from '../../_models/api/companies/company-employees-interface';
import {CompanyProfileInterface} from '../../_models/api/whitelabel/whitelabel-interface';
import {CompanyEmployeesService} from '../../_services/companies/company-employees.service';
import { WelcomeWhitelabelComponent } from '../../pages/whitelabel/components/welcome-whitelabel/welcome-whitelabel.component';
import {WhitelabelService} from '../../_services/whitelabel/whitelabel.service';
import {NavItem} from '../../_models/components/nav-item';
import { RoutingTypes } from '../../_models/components/routing-types';
import { ScriptService } from '../../_services/script.service';
import { PartnershipNames } from 'src/app/_models/components/partnership-names';
import { SubscriptionTypes } from 'src/app/_models/components/subscription-types';
import { WhitelabelPublishComponent } from 'src/app/pages/whitelabel/components/whitelabel-publish/whitelabel-publish.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { loadFontDynamically } from 'src/app/utils/css-utils';
import { randInt } from 'three/src/math/MathUtils';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('sidenav') public sidenav: MatSidenav;
  mode: MatDrawerMode = window.innerWidth >= 768 ? 'side' : 'over';;
  toggleOpen = window.innerWidth >= 768;
  user: UsersInterface;
  userCompanyRole: string;
  colorNavbar = '';
  companyEmployee: CompanyEmployeesInterface;
  menuItems: NavItem[];
  menuItemsApp: NavItem[];
  menuItemsSubscription: NavItem[];
  start: boolean = true;
  userInfoLoaded: boolean = false;
  reteclima = localStorage.getItem(StorageName.partnership) === PartnershipNames.reteclima;
  adnoc = localStorage.getItem(StorageName.partnership) === PartnershipNames.adnoc;
  aldar = localStorage.getItem(StorageName.partnership) === PartnershipNames.aldar;
  whitelabel = false;
  whitelabelData: CompanyProfileInterface;
  preview: boolean = false;
  // justLogged: boolean = true;

  constructor(
    private companyEmployeesService: CompanyEmployeesService,
    private usersService: UsersService,
    private profileService: WhitelabelService,
    public route: ActivatedRoute,
    private scriptService: ScriptService,
    private banner: MatBottomSheet,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.preview = this.router.url.includes('preview');
    if (this.preview) {
      this.banner.open(WhitelabelPublishComponent, { disableClose: true });
      var element = document.querySelector('.cdk-overlay-container');
      element.addEventListener("click", function(e) {
        var el = document.querySelector<HTMLElement>('.cdk-overlay-backdrop');
        el.style.opacity = "0";
      }, false);
    }
    this.getUserInfo().then(() =>{
        this.getUserRole().then();
        this.menuItemsApp = this.sidebarApp;
        this.menuItems = this.sidebarApp;
        this.onResize();
        this.hideNav();
        this.userInfoLoaded = true;
      }
    );
  }

  hideNav(): void {
    if (this.start) {
      this.start = false;
    } else {
      const navbar = document.querySelector('.mat-toolbar')
      navbar.classList.add('hidden');
    }
  }

  showNav(): void {
    const navbar = document.querySelector('.mat-toolbar')
    navbar.classList.remove('hidden');
  }

  ngAfterViewInit(): void {
  }

  onResize(event?): any {
    if (window.innerWidth >= 768) {
      this.mode = 'side';
      this.sidenav?.open().then(r => {
        this.showNav();
      });
    } else {
      this.mode = 'over';
      this.sidenav?.close();
    }
  }

  getUserInfo = async () => {
    const userId = localStorage.getItem(StorageName.id);
    try {
      const response: Response<UsersInterface> = await this.usersService.getUserById(userId);
      if (response?.success) {
        this.user = response.data;
        localStorage.setItem(StorageName.userData, JSON.stringify({
          firstName: this.user.firstname,
          lastName: this.user.lastname,
          email: this.user.email
        }));
        // localStorage.setItem(StorageName.id, this.user.id);
        if (!localStorage.getItem(StorageName.username)) {
          localStorage.setItem(StorageName.username, this.user.firstname);
        }

        if (!localStorage.getItem(StorageName.partnership)) {
          localStorage.setItem(StorageName.partnership, String(this.user.partnership));
        }

        if (this.user.pilio) {
          localStorage.setItem(StorageName.pilio, this.user.pilio.toString());
        }
        if (this.user.pilioLastAccess) {
          localStorage.setItem(StorageName.pilioLastAccess, this.user.pilioLastAccess.toString());
        }
        if (this.user.academyUserCode) {
          localStorage.setItem(StorageName.academy, this.user.academyUserCode);
        }
        await this.getWhitelabelProfile();
      } else {
        this.logout();
        this.showErrorResponse(response);
      }
    } catch (e) {
      this.logout();
      this.showErrorResponse(e);
    }
  }

  getUserRole = async () => {
    const userId = localStorage.getItem(StorageName.id);
    try {
      const response: Response<CompanyEmployeesInterface> = await this.companyEmployeesService.getCompanyEmployeeById(userId);
      if (response?.success) {
        this.companyEmployee = response.data;
        if (this.companyEmployee) {
          this.userCompanyRole = this.companyEmployee?.role?.slug;
          localStorage.setItem(StorageName.companyId, this.companyEmployee.companyId);
          localStorage.setItem(StorageName.companyName, this.companyEmployee.company?.name);
          localStorage.setItem(StorageName.userCompanyRole, this.userCompanyRole);
        }
      } else {
        this.showErrorResponse(response);
      }
    } catch (e) {
      this.showErrorResponse(e);
    }
  }

  private injectFontsIntoDOM(fontUrls: string[]) {
    const head = document.getElementsByTagName('head')[0];
    fontUrls.forEach((fontUrl) => {
      const link = document.createElement('link');
      link.href = fontUrl;
      link.rel = 'stylesheet';
      head.appendChild(link);
    });
  }

  checkWhitelabel(): void {
    const whitelabelWelcome = localStorage.getItem(StorageName.whitelabelWelcome);
    const justLogged = localStorage.getItem(StorageName.justLogged)
    if (!whitelabelWelcome && window.location.pathname == '/admin') {
      if (justLogged == 'true'){
        this.dialog.open(WelcomeWhitelabelComponent, {
          data: {
            userId: localStorage.getItem(StorageName.id),
            // justLogged: this.justLogged
          },
        });
      }
      
      localStorage.setItem(StorageName.pluginCheck, '1')
    }
  }

  getWhitelabelProfile = async () => {
    const companyId = localStorage.getItem(StorageName.companyId);
    try {
      const response: Response<CompanyProfileInterface> = await this.profileService.getProfileByCompanyId(companyId, this.preview ? 'draft': 'active');
      if (response?.success) {
        const { data } = response;

        if (data.status != 'inactive') {
          localStorage.setItem(StorageName.partnership, PartnershipNames.whitelabel);
          localStorage.setItem(StorageName.profile, JSON.stringify(data));
          this.whitelabel = true;
          this.whitelabelData = data;
          if (this.whitelabelData.mainGreeting) {
            if (this.whitelabelData.mainGreeting.includes('{{name}}')) {
              this.whitelabelData.mainGreeting.replace('{{name}}', localStorage.getItem(StorageName.username));
            }
          }

          this.checkWhitelabel();

          this.injectFontsIntoDOM(data.fonts.map(font => font.font));

          data.fonts.forEach((font) => {
            let fontName = '';
            let fontNameOrigin = ''
            if(font.fontfile){
              try{
                fontNameOrigin = font.fontfile.filename.split('_')[1].split('-')[0]
              }catch(e){
                fontNameOrigin = font.fontfile.filename.split('-')[0]
              }
              let fontFileFormat = font.fontfile.filename.split('.')[1]
              loadFontDynamically(fontNameOrigin, font.fontfile.url, 'file')
            }else if(font.url){
              fontNameOrigin = font.font//Math.round((Math.random() * 100)).toString()
              loadFontDynamically(fontNameOrigin, font.url, 'url')
            }else{
              fontNameOrigin = font.font
            }
            try {
              const url = new URL(fontNameOrigin);
              fontName = url.searchParams.get('family');
            } catch (e) {
              fontName = fontNameOrigin;
            }
            document.documentElement.style.setProperty(`--whitelabel-font-${font.type}`, fontName);
          })

          data.colours.forEach(color => {
            document.documentElement.style.setProperty(`--whitelabel-colour-${color.type}`, color.value);
          });

          document.documentElement.style.removeProperty(`--whitelabel-bg-image-dashboard-home`);
          document.documentElement.style.removeProperty(`--whitelabel-bg-color-dashboard-home`);

          data.backgrounds.forEach(bg => {
            if ((bg.mode === 'solid' || bg.mode === 'gradient') && bg.value) {
              document.documentElement.style.setProperty(`--whitelabel-bg-color-${bg.type}`, bg.value);
            } else if (bg.mode === 'image' && bg.image) {
              document.documentElement.style.setProperty(`--whitelabel-bg-image-${bg.type}`, `url(${bg.image.url})`);
            } else if (bg.mode === 'image' && bg.value) {
              document.documentElement.style.setProperty(`--whitelabel-bg-image-${bg.type}`, `url(${bg.value})`);
            }
          })
        }
      } else {
        this.showErrorResponse(response);
      }
    } catch (e) {
      if (e.status != 404) {
        this.showErrorResponse(e);
      }
    }
  }

  openPublish(): void {

  }
}
