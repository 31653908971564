<div class="globe">
  <ng-container>
    <ng-container *ngIf="consent; else acceptCookies">
      <model-viewer *ngIf="calculationCompleted" id="myModelViewer" src="/assets/images/models3D/earth2.glb" auto-rotate
        min-camera-orbit="auto auto auto" max-camera-orbit="auto auto auto"
        environment-image="/assets/images/models3D/hdr/globe.hdr" camera-controls disable-zoom exposure="1.26"
        shadow-intensity="0" disable-pan annotation-visibility="auto" camera-orbit="130.25deg 65.88deg 7.455m"
        (camera-change)="interactedWithCanvas($event)">
        
        <button *ngFor="let project of projects; let i = index" 
          class="Hotspot" [slot]="'hotspot_'+i"
          [ngClass]="project.type.slug"
          [attr.data-position]="project.globeData ? project.globeData.position : 0"
          [attr.data-normal]="project.globeData ? project.globeData.position : 0"
          data-visibility-attribute="visible"
          (click)="clickHotspot($event.target, project.slug)" >
          <app-hotspot-popup [backgroundType]="project.type.slug" [projectCountry]="projectCountry"
            [slugProject]="selectedSlug" [nameProject]="selectedName" [typeProject]="selectedType"
            [imageProject]="selectedImage" [isDashboardHome]="isDashboardHome">
          </app-hotspot-popup>
        </button>
      </model-viewer>
      <div *ngIf="showExperience" class="d-flex justify-content-center position-relative">
        <div class="globe-text position-absolute">
          <svg-icon class="rotation me-2" src="assets/images/public/home/rotation.svg"></svg-icon>
          <span class="text-white lato xs">{{'landingPublic.experience' | translate}}</span>
        </div>
      </div>
    </ng-container>
    <ng-template #acceptCookies>
      <div class="text-center text-white chivo md requestCookies" [ngClass]="business ? 'business' : 'individual'">
        <a (click)="requestCookies()">{{'landingPublic.cookiesLink'|translate}}</a>&nbsp;<span>{{
          'landingPublic.cookiesText' | translate}}</span>
      </div>
    </ng-template>
    <div class="progress-bar hide" slot="progress-bar">
      <div class="update-bar"></div>
    </div>
  </ng-container>
</div>