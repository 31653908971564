import { Component, OnInit } from '@angular/core';
import {RoutingTypes} from '../../_models/components/routing-types';
import {BaseComponent} from '../../pages/_base/base/base.component';
import {SocialTypes} from '../../_models/components/social-types';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Response} from '../../_models/api/response';
import {NewsletterService} from '../../_services/public/newsletter.service';
import { TagTypes } from 'src/app/_models/components/tag-types';

@Component({
  selector: 'app-footer-public',
  templateUrl: './footer-public.component.html',
  styleUrls: ['./footer-public.component.scss']
})
export class FooterPublicComponent extends BaseComponent implements OnInit {
  year: number = new Date().getFullYear();
  routingTypes = RoutingTypes;
  socialTypes = SocialTypes;
  popup: any;
  lang: string = localStorage.getItem('lang') || "en";
  reteclima: boolean = location.href.toLowerCase().includes('reteclima');
  windowWidth:any = window.innerWidth
  region: string = !!localStorage.getItem('region') ? localStorage.getItem('region') : 'all';
  selectedRegion: Region;
  selectedLang: Language;
  currency: string = !!localStorage.getItem('currency') ? localStorage.getItem('currency') : 'eur';
  selectedCurrency: Currency;
  data = new UntypedFormGroup({
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
  });
  constructor(
    private newsletterService: NewsletterService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getLocale();
  }
  get email(): UntypedFormControl {
    return this.data?.get('email') as UntypedFormControl;
  }
  goToSocial(type: string): void{
    switch (type){
      case this.socialTypes.instagram: {
        window.open('https://www.instagram.com/green.futureproject/');
        break;
      }
      case this.socialTypes.linkedin: {
        window.open('https://www.linkedin.com/company/green-future-project');
        break;
      }
      case this.socialTypes.youtube: {
        window.open('https://www.youtube.com/channel/UCoV71yXXEx7coGwGHa8bPWA');
        break;
      }
      case this.socialTypes.facebook: {
        window.open('https://www.facebook.com/greenfutureproject/');
        break;
      }
    }
  }

  getLocale(): void {
    this.selectedLang = Language[this.lang];
    this.selectedRegion = Region[this.region];
    this.selectedCurrency = Currency[this.currency];
  }

  setLocale(data): void {
    localStorage.setItem('lang', data.lang);
    localStorage.setItem('region', data.region);
    localStorage.setItem('currency', data.currency);
    this.changeRoutePath(data.lang)
    location.reload();
  }

  openLocaleModal(): void {
    this.openLocale(
      this.translate.instant('locale.title'),
      'large',
      this.translate.instant('locale.language'),
      '',
      true,
      (result) => this.setLocale(result),
      'Yes'
    );
  }

  changeRoutePath(lang: string): void {
    this.goToLocalizedUrl(this.router.url, lang);
    if (this.router.url == '/gestione_impronta_carbonio' && lang === 'en') {
      window.history.pushState({},null,'/carbon_footprint_management')
    } else if (this.router.url == '/carbon_footprint_management' && lang === 'it') {
      window.history.pushState({},null,'/gestione_impronta_carbonio')
    } else if (this.router.url == '/consulenza-sostenibilita-ambientale' && lang === 'en') {
      window.history.pushState({},null,'/environmental-sustainability-advisory')
    }  else if (this.router.url == '/environmental-sustainability-advisory' && lang === 'it') {
      window.history.pushState({},null,'/consulenza-sostenibilita-ambientale')
    }else if (this.router.url == '/carbon_offset_API' && lang === 'en') {
      window.history.pushState({},null,'/sustainability_plugin')
    }  else if (this.router.url == '/sustainability_plugin' && lang === 'it') {
      window.history.pushState({},null,'/carbon_offset_API')
    } else if (this.router.url == '/crediti-di-carbonio-token-biodiversita' && lang === 'en'){
      window.history.pushState({}, null, '/carbon-offset-biodiversity-token')
    } else if(this.router.url == '/carbon-offset-biodiversity-token' && lang === 'it'){
      window.history.pushState({}, null, '/crediti-di-carbonio-token-biodiversita')
    }
  }

  subscribeUpdate = async () => {
    if (this.email.valid) {
      const tags = [TagTypes.NEWSLETTER]
      localStorage.getItem('clientType') === '2' ? tags.push(TagTypes.BUSINESS) : tags.push(TagTypes.INDIVIDUAL);
      const params: any = {
        email: this.email.value,
        tags: tags,
        newsletter: true
      };
      try {
        const response: Response<any> = await this.newsletterService.subscribeNewsletter(params);
        if (response?.success) {
          this.identify('generate_lead', 'newsletter');
          this.popup = document.getElementById('popup2');
          this.popup.classList.add("overlayShow");
          this.showSuccess(this.translate.instant('subscribeProject.description'), this.translate.instant('subscribeProject.title'));
        } else {
          this.showErrorResponse(response);
        }
      } catch (e) {
        this.showErrorResponse(e);
      }
    }
  }

  goToTrustPilot(){
    window.open("https://it.trustpilot.com/review/greenfutureproject.com", "_blank")
  }

  close(): void{
    this.popup = document.getElementById('popup2');
    this.popup.classList.remove("overlayShow");
  }
  
}

enum Language {
  "en" = 'en',
  "it" = 'it',
  "jp" = 'jp'
}

enum Region {
  "all" = 'Global',
  "eu" = 'Europe',
  "mena" = "Middle East",
  "na" = "North America",
  "sa" = "South America",
  "asia" = "Asia Pacific"
}

enum Currency {
  "($) USD" = 'usd',
  "(€) EUR" = 'eur',
  usd = "($) USD",
  eur = "(€) EUR"
}
