<router-outlet></router-outlet>
<!-- <ngx-ui-loader [fgsTemplate]="foregroundSpinner"></ngx-ui-loader> -->

<ngx-spinner *ngIf="!isSafari" bdColor="rgba(0, 0, 0, 0.7)" template="<img src='assets/images/loader.gif'  width='256'/>"></ngx-spinner>
 
<!-- <ng-template #foregroundSpinner>
  <div class="d-flex justify-content-center align-items-center">
    <img src="assets/images/loader.gif" width="256" />
  </div>
</ng-template> -->
