<div class="h-90 bg-transparent position-relative rounded-3 text-dark welcome-whitelabel-modal" id="welcomeWhitelabel">
  <button
    class="position-absolute top-0 end-0 bg-transparent"
    mat-icon-button
    color="transparent"
    (click)="saveDontShow()"
    mat-dialog-close>
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <div class="chivo bold welcome-title mb-3 me-4">{{'whitelabel.welcome.title'|translate}}</div>
  <div class="lato sm welcome-body">{{'whitelabel.welcome.subtitle'|translate}}</div>
  <div class="d-flex mt-4 align-items-center">
    <div class="me-3 left-icon">
      <img src="assets/images/whitelabel/adminTag.png" alt="Admin Tag">
    </div>
    <span>{{'whitelabel.welcome.remember'|translate}}</span>
  </div>
  <div class="d-flex mt-4 align-items-center">
    <div class="me-3 left-icon">
      <img src="assets/images/whitelabel/whitelabel-icon.png" alt="Whitelabel">
    </div>
    <span>{{'whitelabel.welcome.findGuide'|translate}}</span>
  </div>
  <div class="d-flex mt-4 align-items-center">
    <div class="me-3 left-icon">
      <img src="assets/images/whitelabel/lookFeelTab.png" alt="Look & Feel">
    </div>
    <span>{{'whitelabel.welcome.fontsColours'|translate}}</span>
  </div>
  <div class="mt-4 d-flex align-items-center">
    <mat-checkbox name="dontShow" (change)="toggleShow($event)">{{'whitelabel.welcome.dontShow'|translate}}</mat-checkbox>
  </div>
  <button mat-raised-button mat-dialog-close color="yellow" class="mt-4 float-end" (click)="startCustomizing()">
    {{'whitelabel.welcome.start'|translate}}
  </button>
</div>
