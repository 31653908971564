export const TagTypes = {
    INDIVIDUAL: 'B2C',
    BUSINESS: 'B2B',
    SUBSCRIPTION: 'Subscription',
    GIFT: 'Gift',
    PURCHASER: 'Purchaser',
    RECEIVER: 'Receiver',
    DONATION: 'Donation',
    OFFSET: 'Offset',
    NEWSLETTER: 'Newsletter',
    CONTACT: 'Contact',
    SENDER: 'Purchaser',
    CLIENT: 'Client',
    EMPLOYEE: 'Employee',
    WHITELABEL: 'Whitelabel',
    PILIO: 'Pilio',
    WIDGET: 'Widget',
    BOOK_DEMO: 'Book a Demo',
    CONTENT: 'Content',
    ADV: 'Adv',
    EVENT: 'Event',
}