import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent } from '../../../_base/base/base.component';
import { StorageName } from 'src/app/_models/components/storage-name';

@Component({
  selector: 'app-welcome-whitelabel',
  templateUrl: './welcome-whitelabel.component.html',
  styleUrls: ['./welcome-whitelabel.component.scss'],
})
export class WelcomeWhitelabelComponent extends BaseComponent implements OnInit {
  dontShow: boolean = false;
  // justLogged: boolean = true;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  toggleShow(e): void {
    this.dontShow = e.checked;
  }

  saveDontShow(){
    if (this.dontShow) {
      localStorage.setItem(StorageName.whitelabelWelcome, '1')
    }
    localStorage.setItem(StorageName.justLogged, 'false')
  }

  startCustomizing = async () => {
    // if (this.justLogged){
    //   this.justLogged = false;
    // }
    if (this.dontShow) {
      localStorage.setItem(StorageName.whitelabelWelcome, '1')
    }
    localStorage.setItem(StorageName.justLogged, 'false')
    await this.router.navigate(['admin', 'whitelabel', 'general'])
  }

}
