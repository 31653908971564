// custom-tooltip.directive.ts
import {
  Directive,
  ElementRef,
  Input,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
  TemplateRef,
  HostListener
} from '@angular/core';
import { CustomTooltipComponent } from './custom-tooltip.component';

@Directive({
  selector: '[appCustomTooltip]'
})
export class CustomTooltipDirective {
  @Input('appCustomTooltip') tooltipTemplate!: TemplateRef<any>;

  private tooltipComponentRef?: ComponentRef<CustomTooltipComponent>;

  constructor(
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  @HostListener('mouseenter') onMouseEnter() {
    this.showTooltip();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hideTooltip();
  }

  private showTooltip() {
    const factory = this.componentFactoryResolver.resolveComponentFactory(CustomTooltipComponent);
    this.tooltipComponentRef = this.viewContainerRef.createComponent(factory);
    this.tooltipComponentRef.instance.tooltipTemplate = this.tooltipTemplate;

    this.positionTooltip();
  }

  private hideTooltip() {
    if (this.tooltipComponentRef) {
      this.tooltipComponentRef.location.nativeElement.classList.remove('tooltip-visible');
      setTimeout(() => {
        this.tooltipComponentRef!.destroy();
        this.tooltipComponentRef = undefined
      }, 300);
    }
  }

  private positionTooltip() {
    const hostElem = this.elementRef.nativeElement;
    const tooltipElem = this.tooltipComponentRef!.location.nativeElement;

    const hostRect = hostElem.getBoundingClientRect();
    const tooltipRect = tooltipElem.getBoundingClientRect();

    let top = hostRect.bottom + 10; // 10px margin
    let left = hostRect.left + (hostRect.width - tooltipRect.width) / 2;
    
    //host is on right side of screen
    if(hostRect.left > window.innerWidth/2){
      left =window.innerWidth - hostRect.right
      tooltipElem.style.right = `${left}px`
    }else{
      tooltipElem.style.left = `${left}px`
    }

    

    // Ensure tooltip is within the viewport

    if(hostRect.bottom > window.innerHeight /5*3){
      top = window.innerHeight - hostRect.top + 10
      tooltipElem.style.bottom = `${top}px`
    }else{
      tooltipElem.style.top = `${top}px`;
    }


    //tooltipElem.style.left = `${left}px`
    

    tooltipElem.classList.add('tooltip-visible');
  }
  

  // private positionTooltip() {
  //   const hostElem = this.elementRef.nativeElement;
  //   const tooltipElem = this.tooltipComponentRef!.location.nativeElement;

  //   const hostRect = hostElem.getBoundingClientRect();
  //   const tooltipRect = tooltipElem.getBoundingClientRect();

  //   tooltipElem.style.left = `${hostRect.left + (hostRect.width - tooltipRect.width) / 2}px`;
  //   tooltipElem.style.top = `${hostRect.top + window.scrollY - tooltipRect.height - 10}px`;

  //   tooltipElem.classList.add('tooltip-visible');
  // }
}
