export const WhiteLabelTypes = {
  general: {
    slug: 'general',
    sections: {
      userRoles: {
        slug: 'userRoles',
        forms: {
          addEditor: {
            slug: 'addEditor',
            fields: {}
          },
          modifyUser: {
            slug: 'modifyUser',
            fields: {}
          },
          transferAdmin: {
            slug: 'transferAdmin',
            fields: {}
          },
          removeUser: {
            slug: 'removeUser',
            fields: {}
          }
        }
      },
      socialMedia: {
        slug: 'socialMedia',
        forms: {
          modify: {
            slug: 'modify',
            fields: {}
          },
        }
      }
    }
  },
  lookAndFeel: {
    slug: 'lookAndFeel',
    sections: {
      logo: {
        slug: 'logo',
        forms: {
          modify: {
            slug: 'modify',
            fields: {}
          }
        }
      },
      fonts: {
        slug: 'fonts',
        forms: {
          modify: {
            slug: 'modify',
            fields: {}
          }
        }
      },
      colours: {
        slug: 'colours',
        forms: {
          modify: {
            slug: 'modify',
            fields: {}
          }
        }
      },
      backgrounds: {
        slug: 'backgrounds',
        forms: {
          modify: {
            slug: 'modify',
            fields: {}
          }
        }
      },
/*       lightMode: {
        slug: 'lightMode',
        forms: {
          modify: {
            slug: 'modify',
            fields: {}
          }
        }
      }, */
      greeting: {
        slug: 'greeting',
        forms: {
          modify: {
            slug: 'modify',
            fields: {}
          }
        }
      }
    }

  },
  projectMetrics: {
    slug: 'projectMetrics',
    sections: {
      projects:{
        slug: 'projectsGFP'
      }
    }
  }
}

export const ModalTypes: any = {
  DELETE: 'removeUser',
  TRANSFER: 'transferAdminRole',
  RESET: {
    DEFAULT: 'resetDefault',
    CURRENT: 'resetCurrent'
  },
  PUBLISH: 'publish'
}
