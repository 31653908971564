<div class="summaryItem">
  <div *ngIf="integrationType !== 'nativa'">
    <div *ngFor="let item of items | keyvalue,  let i = index" class="my-2">
      <mat-accordion *ngIf="!offset">
        <mat-expansion-panel [ngStyle]="setColor(getColor(item, summaryType))">
          <mat-expansion-panel-header>
            <mat-panel-title class="me-0">
              <div class="d-flex justify-content-between align-items-baseline px-2">
                <div>
                  <span class="mat-body-1" innerHtml="{{'orderSummary.' + item.key |translate}}"></span>
                </div>
                <div>
                  <ng-container *ngIf="item.key === projectTypesName.preservation, else noAcre">
                    <span class="mat-body-1" [innerHtml]="getPreservationValue(getTotalQuantity(item.value, summaryType))"></span>
                  </ng-container>
                  <ng-template #noAcre>
                    <span class="mat-body-1">
                      {{getTotalQuantity(item.value, summaryType)}}
                      {{getUnitStr(item.value.length ? item.value[0] : item, item.key, getTotalQuantity(item.value, summaryType))}}
                    </span>
                  </ng-template>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngFor="let x of item.value">
            <div class="d-flex justify-content-between align-items-baseline px-2">
              <span class="mat-caption">
                {{getProjectName(x) | titlecase}}
              </span>
              <div>
                <ng-container *ngIf="item.key !== projectTypesName.preservation, else preservationValue">
                  <span class="mat-caption">
                    {{getQuantity(x, summaryType)}} {{getUnitStr(x, item.key, getQuantity(x, summaryType))}}
                  </span>
                </ng-container>
                <ng-template #preservationValue>
                  <span class="mat-caption">
                    {{getPreservationValue(getQuantity(x, summaryType))}}
                  </span>
                </ng-template>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div id="co2" [ngClass]="{'d-none': checkProjectType()}">
      <div class="d-flex justify-content-between align-items-baseline px-2">
        <span class="mat-body-1" [innerHtml]="translate.instant('orderSummary.CO2')"></span>
        <span class="mat-body-1" *ngIf="totalCO2 >= 1000">
          <span *ngIf="totalCO2 && (totalCO2/1000) == 1">{{'orderSummary.ton' | translate: {value: (roundNumber(totalCO2/1000, 2))} }}</span>
          <span *ngIf="totalCO2 && (totalCO2/1000) > 1">{{'orderSummary.tons' | translate: {value: (roundNumber(totalCO2/1000, 2))} }}</span>
        </span>
        <span class="mat-body-1" *ngIf="totalCO2 < 1000">{{'orderSummary.kg' | translate: {value: humanize(roundNumber(totalCO2, 2))} }}</span>
      </div>
    </div>
    <div class="my-2">
      <app-price-breakdown [summaryType]="summaryType" [countryPolicy]="countryPolicy" *ngIf="showPriceBreakdown"></app-price-breakdown>
    </div>
  </div>

  <div *ngIf="integrationType === 'nativa'">
    <div *ngIf="!freeAvailable">
      <div id="co2">
        <div class="d-flex gap-4 justify-content-between align-items-baseline px-1">
          <span class="mat-body-1 fw-semibold">{{'nativa.orderSummary.sectionOne.heading' |translate}}</span>
          <span class="mat-body-1" *ngIf="totalCO2 && (totalCO2/1000) == 1">{{'orderSummary.ton' | translate: {value: (roundNumber(totalCO2/1000, 2))} }}</span>
          <span class="mat-body-1" *ngIf="totalCO2 && (totalCO2/1000) > 1">{{'orderSummary.tons' | translate: {value: (roundNumber(totalCO2/1000, 2))} }}</span>
        </div>
      </div>

      <div class="mt-2 normal-summary-item">
        <div class="d-flex gap-4 justify-content-between align-items-baseline px-1">
          <span class="mat-body-1 fw-semibold">{{'nativa.orderSummary.sectionTwo.heading' |translate}}</span>
          <span class="mat-body-1" *ngIf="totalCO2 && (totalCO2/1000) == 1">{{'orderSummary.credit' | translate: {value: (roundNumber(totalCO2/1000, 2))} }}</span>
          <span class="mat-body-1" *ngIf="totalCO2 && (totalCO2/1000) > 1">{{'orderSummary.credits' | translate: {value: (roundNumber(totalCO2/1000, 2))} }}</span>
        </div>
      </div>
    </div>

    <div *ngIf="freeAvailable">
      <mat-accordion>
        <mat-expansion-panel class="dark-border">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="d-flex justify-content-between gap-3">
                <div class="mat-body-1 fw-semibold">
                  {{'nativa.orderSummary.sectionOne.heading' |translate}}
                </div>
                <div class="mat-body-1">
                  {{'orderSummary.tons' | translate: { value: totalCO2 + freeQuantity } }}
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <hr class="mt-0 h-0" style="border-top: 1px solid #8a8a8a">
            <div class="d-flex gap-4 justify-content-between align-items-baseline px-1">
              <span class="mat-body-1 fw-semibold">{{'nativa.orderSummary.sectionOne.totalBought' |translate}}</span>
              <span class="mat-body-1">{{ totalCO2 }}</span>
            </div>
          </div>
          <div>
            <div class="d-flex gap-4 justify-content-between align-items-baseline px-1">
              <span class="mat-body-1 fw-semibold">{{'nativa.orderSummary.sectionOne.totalIncluded' |translate}}</span>
              <span class="mat-body-1">{{ freeQuantity }}</span>
            </div>
          </div>
        </mat-expansion-panel>

        <span>&nbsp;</span>

        <mat-expansion-panel class="light-border">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="d-flex justify-content-between gap-3">
                <div class="mat-body-1 fw-semibold">
                  {{'nativa.orderSummary.sectionTwo.heading' |translate}}
                </div>
                <div class="mat-body-1">
                  {{'orderSummary.credits' | translate: {value: totalCO2 + freeQuantity} }}
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="">
            <hr class="mt-0 h-0" style="border-top: 1px solid #8a8a8a">
            <div class="d-flex gap-4 justify-content-between align-items-baseline px-1">
              <span class="mat-body-1 fw-semibold">{{'nativa.orderSummary.sectionTwo.totalBought' |translate}}</span>
              <span class="mat-body-1">{{ totalCO2 }}</span>
            </div>
          </div>
          <div class="">
            <div class="d-flex gap-4 justify-content-between align-items-baseline px-1">
              <span class="mat-body-1 fw-semibold">{{'nativa.orderSummary.sectionTwo.totalIncluded' |translate}}</span>
              <span class="mat-body-1">{{ freeQuantity }}</span>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="my-2  normal-summary-item">
      <div class="d-flex gap-4 justify-content-between align-items-baseline px-1">
        <span class="mat-body-1 fw-semibold">{{'nativa.project' |translate}}</span>
        <span class="mat-body-1">{{ 'nativa.projectName' | translate }}</span>
      </div>
    </div>

    <div class="my-2 light-border">
      <app-price-breakdown [summaryType]="summaryType" [integrationType]= "integrationType" [countryPolicy]="countryPolicy"></app-price-breakdown>
    </div>
  </div>
</div>
