export const CartTypes = {
  SINGLE_DONATION:  'singleDonation',
  GIFT: 'gift',
  SUBSCRIPTION: 'subscriptions',
};

export const CheckoutTypes = {
  SUBSCRIPTION: 'subscription',
  GIFT: 'gift',
  DONATION: 'donation',
  OFFSET: 'offset',
  PILIO: 'pilio',
  SUSTAINABILITY: 'sustainability',
  WHITELABEL: 'whitelabel'
}
