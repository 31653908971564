import { Component, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgxColorsComponent } from 'ngx-colors';
import { BaseComponent } from 'src/app/pages/_base/base/base.component';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers:[
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ColorPickerComponent,
      multi: true
    }
  ]
})
export class ColorPickerComponent extends BaseComponent implements ControlValueAccessor {

  @Input()label: string;
  disabled = false;
  colorCode: string;
  control: FormControl = new FormControl('#000000')

  constructor(){
    super()
    this.control.valueChanges.subscribe(value => {
      this.onChange(value)
      this.colorCode = value
    })
  }

  onContainerClicked(){
    document.getElementById('color-picker')!.click();
  }

  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};


  writeValue(obj: any): void {
    this.control.setValue(obj);
    this.colorCode = obj
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
