<div class="infoModal">
  <div mat-dialog-title>
    <div class="position-relative">
      <div class="text-end mb-2 closeButton">
        <button mat-icon-button color="transparent" [mat-dialog-close]="false">
          <mat-icon class="close-icon">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12" id="share">
          <div class="d-flex justify-content-center">
            <div class="inviteFriendsContainer p-0 p-md-3">
              <div class="text-center">
                <svg-icon src="assets/images/svg/man.svg"></svg-icon>
                <p class="mat-subheading-1 mt-2">{{'newShareFriends.title'|translate}}</p>
                <span class="w-75 mat-body-2">{{'newShareFriends.subtitle'|translate}}</span>
              </div>
              <div>
                <mat-form-field appearance="none">
                  <mat-chip-list #chipList>
                    <div class="mailList">
                      <mat-chip [color]="(item.invalid)?'warn':''" selected
                                *ngFor="let item of emailList; let i = index"
                                [selectable]="true"
                                [removable]="removable"
                                (removed)="removeEmail(item)">
                        <span class="mat-body-1">{{item.value}}</span>
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                      </mat-chip>
                      <input autocomplete="off" class="text-start mat-chip-input mat-input-element"
                             placeholder="{{placeholderValue}}"
                             [matChipInputFor]="chipList"
                             [matChipInputSeparatorKeyCodes]="separatorKeys"
                             [matChipInputAddOnBlur]="true"
                             (matChipInputTokenEnd)="add($event)"/>
                    </div>
                  </mat-chip-list>
                  <mat-error>
                    <span>{{'share.invalidEmail'| translate}}</span>
                  </mat-error>
                </mat-form-field>
                <div class="mb-3 text-start" hidden>
                  <svg-icon src="assets/images/svg/upload.svg" [svgClass]="'align-left'"></svg-icon>
                  <a class="ms-2 mat-caption upload">{{'share.csv' | translate}}</a>
                </div>
              </div>
              <button [disabled]="buttonDisabled" (click)="invite()" mat-raised-button color="yellow">
                {{'share.invite' | translate}}
              </button>
            </div>
          </div>
        </div>
        <app-progress class="mt-4"
                      [type]="progressTypes.numberInsideTextBelow"
                      [items]="items"
                      [circleColor]="'white'">
        </app-progress>
      </div>
    </div>
  </div>
</div>
