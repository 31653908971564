<div class="realData px-md-4 pb-2" [ngClass]="type" [ngClass]="{'reteclima': reteclima, 'adnoc': adnoc, 'aldar': aldar, 'delsey': delsey, 'whitelabel': whitelabel}">
  <div class="container">
    <div class="row g-2">
      <div class="col-12 d-flex align-items-center justify-content-between mt-5">
        <div class="text-white main-title">
          <span class="title-2" [ngClass]="{'whitelabel-titles': whitelabel}">{{'projectDetails.yourImpactFull'|translate:{name: name,pluralS:pluralS} }}</span>
          <p class="body" [ngClass]="{'whitelabel-body': whitelabel}" *ngIf="!public">{{'projectDetails.yourImpactShare'|translate}}</p>
        </div>
        <div class="impact-buttons">
          <button mat-icon-button color="yellow" (click)="openImpactPreview()" [hidden]="public" [disabled]="clicked">
            <svg-icon src="assets/images/svg/download.svg"></svg-icon>
          </button>
        </div>
      </div>
      <div class="col-12 col-lg-6 px-0">
        <div class="statistics p-4" [ngClass]="{'rounded-0': delsey}" *ngIf="countersData">
          <div class="row g-2 align-items-start">
            <div class="col-12 col-xl-6">
              <div class="statistic half p-3" [ngClass]="{'rounded-0': delsey}">
                <div class="d-flex align-items-start justify-content-between">
                  <img src="assets/images/icons/co2.svg" alt="CO2" />
                  <app-info [title]="translate.instant('yourImpact.infoTitle')" [body]="translate.instant('yourImpact.infoBody')" [positionY]="'bottom'" [positionX]="'right'" *ngIf="!aldar"></app-info>
                  <app-info [title]="translate.instant('yourImpact.infoTitle')" [body]="translate.instant('yourImpact.infoBodyAldar')" [positionY]="'bottom'" [positionX]="'right'" *ngIf="aldar"></app-info>
                </div>
                <ng-container *ngIf="type != 'co2'; else co2Total">
                  <div class="mt-4" *ngIf="(countersData.certified + countersData.notCertified) >= 1000">
                    <div class="title-2 bold number">{{ humanize(roundNumber((countersData.certified + countersData.notCertified) / 1000, 2)) }}</div>
                    <div class="body bold unit">{{'admin.tons'|translate }}</div>
                  </div>
                  <div class="mt-4" *ngIf="(countersData.certified + countersData.notCertified) < 1000">
                    <div class="title-2 bold number">{{ humanize(roundNumber(countersData.certified + countersData.notCertified, 2)) }}</div>
                    <div class="body bold unit">{{'admin.kg'|translate }}</div>
                  </div>
                </ng-container>
                <ng-template #co2Total>
                  <div class="mt-4" *ngIf="totalCo2 >= 1000">
                    <div class="title-2 bold number">{{ humanize(roundNumber(totalCo2 / 1000, 2)) }}</div>
                    <div class="body bold unit">{{'admin.tons'|translate }}</div>
                  </div>
                  <div class="mt-4" *ngIf="totalCo2 < 1000">
                    <div class="title-2 bold number">{{ humanize(totalCo2) }}</div>
                    <div class="body bold unit">{{'admin.kg'|translate }}</div>
                  </div>
                </ng-template>
                <p class="body m-0 description">
                  {{'admin.total' | translate}}
                  CO<sub>2</sub>e
                  {{'admin.offset' | translate}}
                </p>
              </div>
            </div>
            <div class="col-12 col-xl-6">
              <div class="statistic p-3" [ngClass]="{'rounded-0': delsey}">
                <ng-container *ngIf="type == 'co2'">
                  <div class="d-flex align-items-center">
                    <img src="assets/images/icons/certified.svg" alt="Certified" />
                    <div class="ms-2 w-100">
                      <div class="d-flex justify-content-between align-items-start">
                        <div *ngIf="co2Certified >= 1000">
                          <div class="title-3 bold number">{{ humanize(roundNumber(co2Certified / 1000, 2)) }}</div>
                          <div class="body bold unit">{{'admin.tons'|translate }}</div>
                        </div>
                        <div *ngIf="co2Certified < 1000">
                          <div class="body bold number">{{ humanize(co2Certified) }}</div>
                          <div class="body bold unit">{{'admin.kg'|translate }}</div>
                        </div>
                        <app-info [title]="translate.instant('yourImpact.certifiedTitle')" [body]="translate.instant('yourImpact.certifiedBody')" [positionY]="'bottom'" [positionX]="'right'">
                        </app-info>
                      </div>
                      <p class="body m-0 description">
                        {{'yourImpact.tonsCertifiedDescription'|translate }}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mt-3">
                    <img src="assets/images/icons/science.svg" alt="Science" />
                    <div class="ms-2 w-100">
                      <div class="d-flex justify-content-between align-items-start">
                        <div *ngIf="co2NotCertified >= 1000">
                          <div class="title-3 bold number">{{ humanize(roundNumber(co2NotCertified / 1000, 2)) }}</div>
                          <div class="body bold unit">{{'admin.tons'|translate }}</div>
                        </div>
                        <div *ngIf="co2NotCertified < 1000">
                          <div class="body bold number">{{ humanize(co2NotCertified) }}</div>
                          <div class="body bold unit">{{'admin.kg'|translate }}</div>
                        </div>
                        <app-info [title]="translate.instant('yourImpact.scienceTitle')" [body]="translate.instant('yourImpact.scienceBody')" [positionY]="'bottom'" [positionX]="'right'">
                        </app-info>
                      </div>
                      <p class="body m-0 description">
                        {{'yourImpact.scienceBackedDescription'|translate }}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="type == projectTypes.restoration">
                  <img src="assets/images/icons/restoration.svg" alt="Restoration" />
                  <div class="title-2 bold mt-4 data restoration value">
                    {{ humanize(roundNumber(countersData.unit)) }}
                  </div>
                  <div class="body bold data restoration unit" *ngIf="countersData.unit == 1">
                    {{'admin.tree' | translate}}
                  </div>
                  <div class="body bold data restoration unit" *ngIf="countersData.unit != 1">
                    {{'admin.trees' | translate}}
                  </div>
                  <p class="body m-0 description">
                    {{'admin.planted'|translate }}
                  </p>
                </ng-container>
                <ng-container *ngIf="type == projectTypes.preservation">
                  <img src="assets/images/icons/preservation.svg" alt="Preservation" />
                  <ng-container *ngIf="countersData.unit >= 1; else m2Total">
                    <div class="title-2 bold mt-4 data preservation value">
                      {{ humanize(roundNumber(countersData.unit, 2)) }}
                    </div>
                    <div class="body bold data preservation unit" *ngIf="countersData.unit > 1">
                      {{'admin.hectares' | translate}}
                    </div>
                    <div class="body bold data preservation unit" *ngIf="countersData.unit == 1">
                      {{'admin.hectare' | translate}}
                    </div>
                  </ng-container>
                  <ng-template #m2Total>
                    <div class="title-2 bold mt-4 data preservation value">
                      {{ humanize(roundNumber(countersData.unit * 10000)) }}
                    </div>
                    <div class="body bold data preservation unit">m<sup>2</sup></div>
                  </ng-template>
                  <p class="body m-0 description">{{'admin.protected' | translate}}</p>
                </ng-container>
                <ng-container *ngIf="type == projectTypes.renewable_energy">
                  <div class="renewable-disclaimer-container">
                  <img src="assets/images/icons/renewable.svg" alt="Renewable Energy" />
                  <app-widget-tooltip-disclaimer></app-widget-tooltip-disclaimer>

                </div>
                  <div class="title-2 bold mt-4 data renewable_energy value">
                    {{ humanize(renewable) }}
                  </div>
                  <div class="body bold data renewable_energy unit">{{renewableUnit}}</div>
                  <p class="body m-0 description">
                    {{'admin.produced'|translate }}
                  </p>
                </ng-container>
              </div>
            </div>
            <div class="col-12">
              <div class="statistic p-3" [ngClass]="{'rounded-0': delsey}">
                <ng-container *ngIf="type == 'co2'">
                  <div class="d-flex justify-content-between">
                    <div class="body text-white description" innerHTML="{{'realData.youHaveOffsetted' | translate:{name:cleanName} }} {{'realData.sameAmountOf' | translate}} {{'realData.co2Produced' | translate}}"></div>
                    <app-info [title]="translate.instant('yourImpact.flightsTitle')" [body]="translate.instant('yourImpact.flightsBody')" [positionY]="'bottom'" [positionX]="'right'"></app-info>
                  </div>
                  <p class="title-2 mt-3 mb-2 text-white offsetEqual" *ngIf="!aldar">{{'realData.flightsRomeNewYork'|translate:{value: humanize(flights)} }}</p>
                  <p class="title-2 mt-3 mb-2 text-white" *ngIf="aldar">{{'realData.flightsAbuDhabiLondon'|translate:{value: humanize(flights)} }}</p>
                </ng-container>
                <ng-container *ngIf="type == projectTypes.restoration">
                  <div class="d-flex justify-content-between">
                    <div class="body text-white description">
                      {{'realData.youHaveReforested' | translate:{name:cleanName} }} {{'realData.anAreaSameSizeAs' | translate}}
                    </div>
                    <app-info [title]="translate.instant('yourImpact.tennisTitle')" [body]="translate.instant('yourImpact.tennisBody')" [positionY]="'bottom'" [positionX]="'right'"></app-info>
                  </div>
                  <p class="title-2 mt-3 mb-2 data offsetEqual {{type}}">{{'realData.tennisCourts'|translate:{value: humanize(value)} }}</p>
                </ng-container>
                <ng-container *ngIf="type == projectTypes.preservation">
                  <div class="d-flex justify-content-between">
                    <div class="body text-white description">
                      {{'realData.youHaveProtected' | translate:{name:cleanName} }} {{'realData.anAreaSameSizeAs' | translate}}
                    </div>
                    <app-info [title]="translate.instant('yourImpact.tennisTitle')" [body]="translate.instant('yourImpact.tennisAreaBody')" [positionY]="'bottom'" [positionX]="'right'"></app-info>
                  </div>

                  <p class="title-2 mt-3 mb-2 data offsetEqual {{type}}">{{'realData.tennisCourts'|translate:{value: humanize(value)} }}</p>
                </ng-container>
                <ng-container *ngIf="type == projectTypes.renewable_energy">
                  <div class="d-flex justify-content-between">
                    <div class="body text-white description">
                      {{'realData.youHaveProduced' | translate:{name:cleanName} }} {{'realData.sameAmountOf' | translate}} {{'realData.energyPower' | translate}}
                    </div>
                    <app-info [title]="translate.instant('yourImpact.householdsTitle')" [body]="translate.instant('yourImpact.householdsBody')" [positionY]="'bottom'" [positionX]="'right'"></app-info>
                  </div>
                  <p class="title-2 mt-3 mb-2 data offsetEqual {{type}}">{{'realData.households'|translate:{value: humanize(value)} }}</p>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="statistics mt-0" *ngIf="delsey" innerHtml="{{'realData.comingSoon' | translate}}"></div> -->
      </div>
      <div class="col-12 col-lg-6">
        <div class="mapsImg d-flex justify-content-center align-items-center">
          <ng-container *ngIf="type == 'co2'">
            <img [ngStyle]="{'margin-top': '30px'}" src="assets/images/co2Slider.png" alt="health earth"/>
          </ng-container>
          <ng-container *ngIf="type == projectTypes.preservation">
            <img [ngStyle]="{'margin-top': '50px'}" src="assets/images/tennisball.png" alt="health earth"/>
          </ng-container>
          <ng-container *ngIf="type == projectTypes.restoration">
            <img [ngStyle]="{'margin-top': '50px'}" src="assets/images/tennisCourt.png" alt="health earth"/>
          </ng-container>
          <ng-container *ngIf="type == projectTypes.renewable_energy">
            <img [ngStyle]="{'margin-top': '90px'}" src="assets/images/houseImpact.png" alt="health earth"/>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
