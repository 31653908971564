import { Component, Inject, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../../_base/base/base.component';
import { UsersService } from 'src/app/_services/users/users.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalTypes } from 'src/app/_models/components/whitelabel-types';
import { WhitelabelService } from 'src/app/_services/whitelabel/whitelabel.service';
import { UntypedFormControl } from '@angular/forms';
import { StorageName } from 'src/app/_models/components/storage-name';

@Component({
  selector: 'app-whitelabel-modal',
  templateUrl: './whitelabel-modal.component.html',
  styleUrls: ['./whitelabel-modal.component.scss'],
})
export class WhitelabelModalComponent extends BaseComponent implements OnInit {
  @Input() type: string;
  email: UntypedFormControl = new UntypedFormControl();
  types = ModalTypes;

  constructor(
    private userService: UsersService,
    private profileService: WhitelabelService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
  }

  ngOnInit(): void {
    this.type = this.data.type ?? this.type;
  }

  async transferAdmin(): Promise<void> {
    try {
      await this.userService.transferAdmin(this.data.id, this.email.value);
    } catch (e) {
      this.showError(e.message);
    }
  }

  async deleteUser(): Promise<void> {
    try {
      await this.userService.deleteUser(this.data.id);
      location.reload();
    } catch (e) {
      this.showError(e.message);
    }
  }

  async reset(): Promise<void> {
    try {
      const response = await this.profileService.resetProfileByCompanyId(this.data.id, this.data.resetType);
      localStorage.setItem(StorageName.whitelabelSaved, "0");
      location.reload();
    } catch (e) {
      this.showError(e.message);
    }
  }

  async publish(): Promise<void> {
    try {
      const response = await this.profileService.publishProfileByCompanyId(this.data.id);
      localStorage.setItem(StorageName.whitelabelSaved, "0");
      let href = this.router.url;
      href = href.replaceAll("/preview","");
      location.href = href;
    } catch (e) {
      this.showError(e.message);
    }
  }
}
