<div class="container socialLogins">
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="facebook">
        <button mat-raised-button id="facebook">
          <svg-icon src="assets/images/svg/facebook_new.svg"></svg-icon>
          {{'login.facebook'|translate}}
        </button>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="google">
        <button mat-raised-button id="google" #google>
          <svg-icon src="assets/images/svg/google.svg"></svg-icon>
          {{'login.google'|translate}}</button>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="twitter">
        <button mat-raised-button id="twitter">
          <svg-icon src="assets/images/svg/twitter.svg"></svg-icon>
          {{'login.twitter'|translate}}</button>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="linkedin">
        <button mat-raised-button id="linkedin">
          <svg-icon src="assets/images/svg/linkedin.svg"></svg-icon>
          {{'login.linkedin'|translate}}</button>
      </div>
    </div>
  </div>

</div>

