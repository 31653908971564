import { RoutingTypes } from './routing-types';

export const PageTypes = {
  PUBLIC: {
    HOME: {
      slug: 'home',
      keywords: [
        'climate action',
        'co2 emissions',
        'environmental sustainability',
        'esg strategy',
        'sustainable business',
        'achieve net zero',
        'climate positive',
        'climate strategy',
        'environmental strategy',
        'esg dashboard',
        'measure sustainability',
        'reduce co2 emissions',
        'regenerative business',
        'sustainability dashboard',
        'sustainability platform',
        'company esg strategy',
        'corporate esg strategy',
        'emissions reduction strategy',
        'monitor sustainability'
      ],
      route: RoutingTypes.HOME_PUBLIC
    },
    ABOUT: {
      slug: 'about',
      keywords: [],
      route: RoutingTypes.ABOUT
    },
    SOLUTIONS: {
      EN: {
        slug: 'solutions',
        keywords: [
          'climate action',
          'co2 emissions',
          'environmental sustainability',
          'esg strategy',
          'sustainable business',
          'achieve net zero',
          'climate positive',
          'climate strategy',
          'environmental strategy',
          'esg dashboard',
          'measure sustainability',
          'reduce co2 emissions',
          'regenerative business',
          'sustainability dashboard',
          'sustainability platform',
          'company esg strategy',
          'corporate esg strategy',
          'emissions reduction strategy',
          'monitor sustainability',
        ],
        route: RoutingTypes.SOLUTIONS
      },
      IT: {
        slug: 'solutions',
        keywords: [
          'carbon neutral',
          'azienda sostenibile',
          'decarbonizzazione',
          'riduzione emissioni co2',
          'servizi esg',
          'servizi per la sostenibilità',
          'software per la sostenibilità',
          'soluzioni esg',
          'soluzioni per il clima',
          'soluzioni per la sostenibilità',
          'compensazione delle emissioni di carbonio',
          'servizi per le emissioni di carbonio',
          'software per la decarbonizzazione',
          'soluzioni net zero',
          'soluzioni per evitare le emissioni di carbonio',
          'soluzioni per la decarbonizzazione',
          'soluzioni per la riduzione delle emissioni',
          'soluzioni per le emissioni di carbonio',
        ],
        route: RoutingTypes.SOLUTIONS_IT
      },
      FOOTPRINT: {
        EN: {
          slug: 'footprint',
          keywords: [
            'Energy Efficiency',
            'energy management',
            'monitor energy consumption',
            'save energy ',
            'ghg protocol',
            'ghg emissions,',
            'Carbon Footprint,',
            'carbon accounting',
            'carbon emission',
            'reduce energy consumption',
            'carbon footrpint reduction',
            'carbon tracker',
            'Scope 1, Scope 2, Scope 3,',
            'net zero tracker',
            'net zero building,',
            'carbon footprint calculator',
            'decarbonisation targets',
          ],
          route: RoutingTypes.ENERGY
        },
        IT: {
          slug: 'footprint',
          keywords: [
            'carbon footprint',
            'efficientamento energetico',
            'efficienza energetica',
            'scope 1 2 3',
            'emissioni di carbonio',
            'ghg',
            'emissioni di gas serra',
            'ridurre le emissioni di co2',
            'calcolare le emissioni di co2',
            'contabilità del carbonio',
            'emissioni di co2 per settore',
            'gestione dell energia',
            'migliorare l efficienza energetica',
            'ridurre i consumi energetici',
            'ridurre l impronta di carbonio',
            'ridurre le emissioni',
            'riduzione del carbonio',
            'riduzione delle emissioni',
            'riduzione delle emissioni di carbonio',
            'riduzione delle emissioni di co2',
            'riduzione di co2',
            'sistema di gestione dell energia',
            'software di gestione dell energia',
            'aziende net zero',
            'calcolare le emissioni',
          ],
          route: RoutingTypes.ENERGY_IT
        }
      },
      OFFSET: {
        EN: {
          slug: 'carbonCreditsBiodiversityTokens',
          keywords: [
            'carbon credits',
            'carbon offset',
            'nature based solutions',
            'biodiversity credits',
            'carbon offset certification',
            'certified carbon credits',
            'co2 offset',
            'offset carbon footprint',
            'offset emissions',
            'carbon absorption',
            'carbon avoidance',
            'carbon reduction certification',
            'high quality carbon credits',
            'high quality carbon offsets',
            'offset co2 emissions',
            'remove carbon',
            'biodiversity tokens',
            'high quality co2 offsets'
          ],
          route: RoutingTypes.CARBON_CREDITS
        },
        IT: {
          slug: 'carbonCreditsBiodiversityTokens',
          keywords: [
            'crediti di carbonio',
            'crediti co2',
            'nature based solutions',
            'compensazione co2',
            'compensare emissioni co2',
            'compensazione di co2',
            'compensazioni di carbonio',
            'crediti di carbonio certificati',
            'soluzioni basate sulla natura',
            'compensare le emissioni di co2',
            'compensazione dell impronta di carbonio',
            'compensazione di emissioni di co2',
            'compensazioni certificate di co2',
            'crediti di carbonio di alta qualità',
            'crediti per la biodiversità',
            'tokens di biodiversità'
          ],
          route: RoutingTypes.CARBON_CREDITS_IT
        }
      },
      ADVISORY: {
        EN: {
          slug: 'advisory',
          keywords: [
            'esg advisory',
            'esg consultancy',
            'sustainability advisory',
            'sustainability consultancy',
            'environmental advisory',
            'environmental consultancy',
            'esg services',
            'esg goals',
            'esg roadmap',
            'esg targets',
            'sustainability services',
            'sustainability goals',
            'sustainability targets',
            'environmental services',
            'environmental goals',
            'environmental targets',
            'net zero goals',
            'net zero target',
            'lca',
            'life cycle assessment',
            'epd',
            'Environmental Product Declaration',
            'life cycle models',
            'Cradle-to-grave',
            'Cradle-to-gate',
            'Cradle-to-cradle',
            'closed-loop recycling',
            'Gate-to-gate',
            'Well-to-wheel',
            'Well-to-tank',
            'Tank-to-wheels',
            'organization carbon footprint',
            'company carbon footprint',
            'corporate carbon footprint',
            'product carbon footprint',
            'sustainable event',
            'esg certification',
            'esg compliance',
            'esg conformity',
            'environmental certification',
            'sustainability certification',
            'esg assessment',
            'sustainability assessment',
            'materiality analysis',
            'double materiality assessment',
            'DMA',
            'BIA',
            'B Corp certification',
            'B Corp assessment',
            'B Corp score',
            'regulatory compliance',
            'esg standard',
            'GHG standard',
            'ISO standard',
            'ISO 14040',
            'ISO 14044',
            'ISO 14064',
            'ISO 14067',
            'UNI EN ISO 14044:2006',
            'UNI EN ISO 14064',
            'UNI EN ISO 14067:2018',
            'Global warming potential',
            'Environment Product Declaration',
            'EPD',
            'Carbon accounting',
            'CO2 accounting',
            'GHG accounting'
          ],
          route: RoutingTypes.ESG
        },
        IT: {
          slug: 'advisory',
          keywords: [
            'consulenza esg',
            'servizi esg',
            'servizi sostenibilità',
            'obiettivi esg',
            'obiettivi ambientali',
            'obiettivi di sostenibilità',
            'obiettivi net zero',
            'percorso di sostenibilità',
            'percorso net zero',
            'roadmap esg',
            'strategia esg',
            'calcolo lca',
            'lca',
            'lifecyle assessment',
            'epd',
            'dichiarazione ambientale di prodotto',
            'modelli ciclo di vita',
            'carbon footprint organizzazione',
            'impronta carbonica organizzazione',
            'carbon footprint prodotto',
            'impronta carbonica prodotto',
            'carbon footprint evento',
            'impronta carbonica evento',
            'eventi sostenibili',
            'assessment esg',
            'assessment sostenibilità',
            'analisi doppia materialità',
            'matrice di materialità',
            'matrice di doppia materialità',
            'BIA',
            'B Impact Assessment',
            'Certificazione B Corp',
            'Punteggio B Corp',
            'certificazione ambientale',
            'certificazione di sostenibilità',
            'compliance esg',
            'Conformit’ esg',
            'standard esg',
            'standard GHG',
            'standard ISO',
            'ISO 14040',
            'ISO 14044',
            'ISO 14064',
            'ISO 14067',
            'UNI EN ISO 14044:2006',
            'UNI EN ISO 14064',
            'UNI EN ISO 14067:2018',
            'Dichiarazione Ambientale di Prodotto',
            'EPD',
            'Carbon accounting',
            'CO2 accounting',
            'GHG accounting'
          ],
          route: RoutingTypes.ESG_IT
        }
      },
      NFT: {
        slug: 'nft',
        keywords: [],
        route: RoutingTypes.NFT
      },
      GIFTS: {
        LIST: {
          slug: 'gifts',
          keywords: [],
          route: RoutingTypes.GIFT
        },
        DETAIL: {
          slug: 'gifts',
          keywords: [],
          route: RoutingTypes.GIFT
        },
        CART: {
          slug: 'gifts',
          keywords: [],
          route: RoutingTypes.GIFT
        },
        CHECKOUT: {
          slug: 'gifts',
          keywords: [],
          route: RoutingTypes.GIFT
        },
      },
      SUBSCRIPTIONS: {
        B2C: {
          INFO: {
            slug: 'subscriptionB2C',
            keywords: [],
            route: RoutingTypes.INDIVIDUAL_SUBSCRIPTION
          },
          CHECKOUT: {
            slug: 'subscriptionB2C',
            keywords: [],
            route: RoutingTypes.INDIVIDUAL_SUBSCRIPTION_CHECKOUT
          }
        },
        B2B: {
          CLIMATE: {
            INFO: {
              slug: 'subscriptionB2B',
              keywords: [
                'business climate positive subscription',
                'carbon footprint employee',
                'carbon offset contribution',
                'carbon reduction subscription',
                'climate positive employees',
                'climate positive subscription',
                'climate subscription',
                'company climate positive subscription',
                'compensate employees co2 emissions',
                'corporate climate positive subscription',
                'net zero employees',
                'offset employees carbon footprint',
                'offset subscription',
                'reduce co2 subscription',
                'reduce emissions subscription',
                'reduce employee carbon footprint',
                'abbonamento aziendale climate positive',
                'abbonamento business climate positive',
                'abbonamento carbon offset',
                'abbonamento riduzione emissioni',
                'compensare l impronta di carbonio dei dipendenti',
                'compensare le emissioni di co2 dei dipendenti',
                'dipendenti impatto zero',
                'forza lavoro impatto zero',
                'ridurre l impronta di carbonio dei dipendenti',
              ],
              route: RoutingTypes.BUSINESS_SUBSCRIPTION
            },
            CHECKOUT: {
              slug: 'subscriptionB2B',
              keywords: [],
              route: RoutingTypes.LEADER_CHECKOUT
            },
          },
          API: {
            INFO: {
              slug: 'api',
              keywords: [],
              route: RoutingTypes.API
            },
            CHECKOUT: {
              slug: 'api',
              keywords: [],
              route: RoutingTypes.PIONEER_CHECKOUT
            },
          },
        }
      },
      DONATIONS: {
        CHECKOUT: {
          slug: 'donations',
          keywords: [],
          route: RoutingTypes.SINGLE_DONATIONS
        },
        OFFSET: {
          slug: 'offset',
          keywords: [
            'carbon credits',
            'carbon offsets',
            'nature based solutions',
            'biodiversity credits',
            'carbon offset certification',
            'certified carbon credits',
            'co2 offset',
            'offset carbon footprint',
            'offset emissions',
            'carbon absorption',
            'carbon avoidance',
            'carbon reduction certification',
            'high quality carbon credits',
            'high quality carbon offsets',
            'offset co2 emissions',
            'remove carbon',
            'biodiversity tokens',
            'high quality co2 offsets',
            'crediti di carbonio',
            'crediti co2',
            'nature based solutions',
            'compensazione co2',
            'compensare emissioni co2',
            'compensazione di co2',
            'compensazioni di carbonio',
            'crediti di carbonio certificati',
            'soluzioni basate sulla natura',
            'compensare le emissioni di co2',
            'compensazione dell impronta di carbonio',
            'compensazione di emissioni di co2',
            'compensazioni certificate di co2',
            'crediti di carbonio di alta qualità',
            'crediti per la biodiversità',
            'tokens di biodiversità',
          ],
          route: RoutingTypes.OFFSET
        },
      },
      PLATFORM: {
        EN: {
          slug: 'platform',
          keywords: [
            'workforce engagement',
            'workers engagement',
            'talent engagement',
            'employee engagement tools',
            'workers engagement tools',
            'workforce engagement tools',
            'talent engagement tools',
            'best place to work',
            'personal carbon footprint',
            'individual carbon footprint',
            'individual carbon offset',
            'personal carbon offset',
            'carbon offsetting for individuals',
            'carbon footprint employee',
            'carbon offset contribution',
            'carbon reduction subscription',
            'employee sustainability',
            'workforce sustainability',
            'workers sustainability',
            'employee sustainability initiatives',
            'sustainability in hr',
            'green hr',
            'workers benefits',
            'workforce benefits',
            'employee benefits',
            'talent benefits',
            'workers retention',
            'workforce retention',
            'employee retention',
            'talent retention',
            'workers retention strategies',
            'workforce retention strategies',
            'employee retention strategies',
            'talent retention strategies',
            'perks at work',
            'work and benefit',
            'retaining employees',
            'retaining workers',
            'retaining talents',
            'ways to increase employee retention',
            'ways to increase workers retention',
            'ways to increase workforce retention',
            'ways to increase talents retention',
            'workers sustainability benefits',
            'workforce sustainability benefits',
            'employee sustainability benefits',
            'talents sustainability benefits',
            'net zero workforce',
            'net zero employee',
            'net zero employees',
            'net zero workers',
            'net zero skills',
            'compensate employees co2 emissions',
            'offset employees carbon footprint',
            'offset subscription',
            'reduce co2 subscription',
            'reduce emissions subscription',
            'reduce employee carbon footprint'
            ],
          route: RoutingTypes.ESG_PLATFORM
        },
        IT: {
          slug: 'platform',
          keywords: [
            'impegno dei dipendenti',
            'engagement dipendenti',
            'engagement lavorativo',
            'engagement lavoro',
            'hr green',
            'benefici per i lavoratori',
            'fidelizzazione dei dipendenti',
            'fidelizzare i dipendenti',
            'trattenere i talenti',
            'engagement aziendale',
            'fringe benefit',
            'benefit aziendali',
            'abbonamento carbon offset',
            'abbonamento riduzione emissioni',
            'compensare l impronta di carbonio dei dipendenti',
            'compensare le emissioni di co2 dei dipendenti',
            'dipendenti impatto zero',
            'forza lavoro impatto zero',
            'ridurre l impronta di carbonio dei dipendent'
            ],
          route: RoutingTypes.ESG_PLATFORM_IT
        }
      }
    },
    TRAINING: {
      EN: {
        slug: 'training',
        keywords: [
          'education',
          'ESG education',
          'sustainability education',
          'CSR education',
          'training',
          'ESG training',
          'sustainability training',
          'CSR training',
          'academy',
          'sustainability academy',
          'CSR academy',
          'employees upskilling',
          'online courses',
          'ESG courses',
          'sustainability courses',
          'CSR courses',
          'webinar',
          'ESG webinar',
          'sustainability webinar',
          'CSR webinar',
          'workshop',
          'ESG workshop',
          'sustainability workshop',
          'CSR workshop',
          'ESG competencies',
          'sustainability competencies',
          'CSR competencies',
          'team building',
          'interactive learning',
          'sustainability knowledge',
          'ESG knowledge',
          'CSR knowledge',
          'masterclass',
          'employee engagement programs',
          'ESG vocabulary'
        ],
        route: RoutingTypes.TRAINING
      },
      IT: {
        slug: 'training',
        keywords: [
          'formazione',
          'formazione sostenibilità',
         'formazione sostenibilità ambientale',
          'formazione sostenibilità d’impresa',
          'programmi di formazione',
          'training',
          'training ESG',
          'training sostenibilità',
          'training sostenibilità ambientale',
          'training sostenibilità d’impresa',
          'ESG academy',
          'academy sulla sostenibilità',
          'academy sostenibilità ambientale',
          'academy sostenibilità d’impresa',
          'corsi online',
          'corsi ESG',
          'corsi sostenibilità',
          'corsi aggiornamento',
          'corsi sostenibilità ambientale',
          'corsi sostenibilità d’impresa',
          'webinar',
          'webinar ESG',
          'webinar sostenibilità',
          'webinar sostenibilità ambientale',
          'webinar sostenibilità d’impresa',
          'workshop',
          'workshop ESG',
          'workshop sostenibilità',
          'workshop sostenibilità ambientale',
          'workshop sostenibilità d’impresa',
          'competenze ESG',
          'competenze sostenibilità',
          'edutainment',
          'team building aziendale',
          'glossario ESG'
        ],
        route: RoutingTypes.TRAINING_IT
      }
    },
    PROJECTS: {
      ALL: {
        slug: 'projects',
        keywords: [
          'biodiversity',
          'conservation',
          'ecosystem',
          'environmental',
          'nbs',
          'un sustainable development goals',
          'carbon offset',
          'carbon sequestration',
          'climate action',
          'nature based solutions',
          'reforestation',
          'verra',
          'biodiversity hotspot',
          'environmental projects',
          'increase biodiversity',
          'regeneration',
          'regenerative projects',
          'sustainability projects',
          'carbon avoidance projects',
          'climate action project',
          'climate projects',
          'promote biodiversity',
          'certified environmental projects',
          'offsetting project',
          'biodiversità',
          'ecosistema',
          'nbs',
          'ambientale',
          'conservazione',
          'nature based solutions',
          'rigenerazione',
          'verra',
          'hotspot di biodiversità',
          'progetti ambientali',
          'progetti di sostenibilità',
          'riforestazione',
          'azione per il clima',
          'compensazione del carbonio',
          'obiettivi di sviluppo sostenibile dell onu',
          'sequestro del carbonio',
          'soluzioni basate sulla natura',
          'aumentare la biodiversità',
          'progetti ambientali certificati',
          'progetti di compensazione',
        ],
        route: RoutingTypes.PROJECTS_PUBLIC
      },
      RESTORATION: {
        slug: 'restoration',
        keywords: [
          'biodiversity hotspot restoration',
          'biodiversity regeneration',
          'biodiversity restoration',
          'corporate forest',
          'ecosystem restoration',
          'forest regeneration',
          'forest restoration',
          'planting a tree',
          'planting mangroves',
          'reforestation',
          'reforestation project',
          'capture CO2',
          'catturare co2',
          'piantare un albero',
          'riforestazione',
          'rimboschimento',
          'foresta aziendale',
          'progetto di riforestazione',
          'piantare mangrovie',
          'rigenerazione dell ecosistema',
          'rigenerazione della biodiversità',
          'rigenerazione forestale',
          'ripristino della biodiversità',
          'ripristino di hotspot di biodiversità',
        ],
        route: RoutingTypes.RESTORATION
      },
      PRESERVATION: {
        slug: 'preservation',
        keywords: [
          'biodiversity preservation',
          'biodiversity protection',
          'conservation',
          'ecosystem preservation',
          'ecosystem protection',
          'forest conservation',
          'forest preservation',
          'forest protection',
          'nature conservation',
          'preservation',
          'conservazione della biodiversità',
          'protezione della biodiversità',
          'conservazione',
          'conservazione dell\'ecosistema',
          'protezione dell\'ecosistema',
          'conservazione delle foreste',
          'protezione delle foreste',
          'conservazione della natura',
          'conservazione',
        ],
        route: RoutingTypes.PRESERVATION
      },
      RENEWABLE: {
        slug: 'renewable',
        keywords: [
          'renewable carbon',
          'renewable energy',
          'renewable energy carbon avoidance',
          'renewable energy certificates',
          'renewables',
          'renewables projects',
          'solar energy',
          'solar renewable energy',
          'wind energy',
          'wind renewable energy',
          'windfarm',
          'energia rinnovabile',
          'certificati di energia rinnovabile',
          'progetti di energia rinnovabile',
          'energie rinnovabili',
          'energia solare',
          'energia rinnovabile solare',
          'energia eolica',
          'energia rinnovabile eolica',
        ],
        route: RoutingTypes.RENEWABLE
      },
      MARINE: {
        slug: 'marine',
        keywords: [
          'blue carbon',
          'corals',
          'mediterranean sea',
          'blue economy',
          'marine conservation',
          'marine protected areas',
          'posidonia oceanica',
          'coral nurseries',
          'marine biodiversity',
          'marine ecosystem',
          'marine forests',
          'marine habitats',
          'planting seedlings',
          'seagrass beds',
          'carbon sequestration capacity',
          'grow corals',
          'life below sea',
          'marine restoration',
          'marine reforestation',
          'marine regeneration',
          'coralli',
          'blue carbon',
          'blue economy',
          'rigenerare le barriere coralline',
          'ecosistema marino',
          'biodiversità marina',
          'economia blu',
          'conservazione marina',
          'coltivare i coralli',
          'degrado della barriera corallin',
          ' rigenerare le barriere coralline,',
          'protezione delle specie marine, ',
          'habitat marini,',
          'posidonia oceanica',
          ' aree marine protette',
          'foreste marine, ',
          'riforestazione marina, ',
          'vita sottomarina',
        ],
        route: RoutingTypes.MARINE
      },
      WASTE_REMOVAL: {
        slug: 'wasteRemoval',
        keywords: [],
        route: RoutingTypes.WASTE_REMOVAL
      },
      DETAIL: {
        slug: '',
        title: '',
        description: '',
        keywords: [],
        route: RoutingTypes.PROJECTS_PUBLIC
      },
      LIVE: {
        slug: 'live',
        keywords: [],
        route: RoutingTypes.PROJECTS_PUBLIC
      },
    },
    PARTNERS: {
      slug: 'partners',
      keywords: [],
      route: RoutingTypes.PARTNERS,
      ALLIANCE: {
        slug: 'alliance',
        keywords: [],
        route: RoutingTypes.DECARBONISATION_TECH_ALLIANCE
      },
      STRATEGIC: {
        EN: {
          slug: 'strategic',
          keywords: [
            "Strategic partner",
            "Sustainability SaaS partner",
            "Net Zero partner",
            "Green Future Project partners Technology partner",
            "Certification partner",
            "Association partner",
            "Consulting partner",
            "Reselling partner",
            "Sustainability consulting",
            "Net Zero consulting",
            "B2B sustainability solutions"
          ],
          route: RoutingTypes.STRATEGIC_PARTNERS
        },
        IT: {
          slug: 'strategic',
          keywords: [
            "Partner sostenibilità",
            "Rivenditore Sostenibilità",
            "partner SaaS per la sostenibilità",
            "partner Net Zero",
            "partner Green Future Project",
            "Partner tecnologico",
            "partner di certificazione",
            "consulenza sulla sostenibilità",
            "consulenza Net Zero",
            "soluzioni di sostenibilità B2B"
          ],
          route: RoutingTypes.STRATEGIC_PARTNERS_IT
        }
      }
    },
    FAQ: {
      slug: 'faq',
      keywords: [],
      route: RoutingTypes.FAQ
    },
    WELCOME: {
      slug: 'welcome',
      keywords: [],
      route: RoutingTypes.WELCOME
    },
    PREMIUM: {
      COMPANY: {
        slug: 'company',
        keywords: [],
        route: RoutingTypes.COMPANY_PUBLIC
      },
      IMPACT: {
        slug: 'impact',
        keywords: [],
        route: RoutingTypes.COMPANY_PUBLIC
      },
    },
    SUCCESS: {
      slug: 'success',
      keywords: [],
      route: RoutingTypes.SUCCESS
    },
    PRIVACY: {
      slug: 'privacy',
      keywords: [],
      route: RoutingTypes.PRIVACY_POLICY_PUBLIC
    },
    TERMS: {
      slug: 'terms',
      keywords: [],
      route: RoutingTypes.TERMS_AND_CONDITIONS
    },
    COOKIES: {
      slug: 'cookies',
      keywords: [],
      route: RoutingTypes.COOKIE_POLICY
    },
    SUSTAINABILITY: {
      EN: {
        slug: 'sustainability',
        keywords: [
          'sustainable technology',
          'carbon neutral products',
          'eco friendly shop',
          'environmental friendly product',
          'net zero product',
          'reduce environmental impact',
          'shopping sustainable',
          'sustainability ecommerce',
          'sustainable store',
          'carbon neutral checkout',
          'carbon neutral ecommerce',
          'climate api',
          'climate positive products',
          'conscious ecommerce',
          'increase ecommerce sales',
          'Increase conversion',
          'net zero store',
          'net zero website',
          'sustainability api',
          'carbon neutral plugin',
          'sustainability plugin',
          'shopify plugin',
          'shopify app store',
          'shopify apps',
          'shopify partners',
          'shopify pos',
          'shopify website',
          'shopify stores',
          'shopify developer',
          'shopify api',
          'shopify b2b',
          'shopify site',
          'prestashop addons',
        ],
        route: RoutingTypes.SUSTAINABILITY
      },
      IT: {
        slug: 'sustainability',
        keywords: [
          'prodotti ecosostenibili',
          'prodotti sostenibili',
          'aumentare vendite ecommerce',
          'eco friendly shop',
          'negozio sostenibile',
          'prodotto ecologico',
          'servizi ecologici',
          'tecnologia sostenibile',
          'vendite ecommerce',
          'e commerce sostenibile',
          'ecommerce sostenibile',
          'negozio ecologico',
          'shop ecosostenibile',
          'shop sostenibile',
          'shopping sostenibile',
          'sito web sostenibile',
          'api sostenibilità',
          'checkout sostenibile',
          'conversioni shop online',
          'plugin sostenibilità',
          'ridurre impatto ambientale',
          'sostenibilità ecommerce',
          'shopify plugin',
          'shopify app store',
          'negozio shopify',
          'shopify apps',
          'shopify partners',
          'shopify pos',
          'shopify developer',
          'shopify api',
          'shopify b2b',
          'shopify sito',
          'prestashop addons',
        ],
        route: RoutingTypes.SUSTAINABILITY
      }
    },
    WHITELABEL: {
      EN: {
        slug: 'whitelabel',
        keywords: [],
        route: RoutingTypes.WHITELABEL
      },
      IT: {
        slug: 'whitelabel',
        keywords: [],
        route: RoutingTypes.WHITELABEL_IT
      }
    },
    REPORTING: {
      EN: {
        slug: 'reporting',
        keywords: [
          'esg report',
          'impact report',
          'sustainability assessment',
          'sustainability plan',
          'sustainability performance',
          'esg performance',
          'esg reporting',
          'sustainability reporting',
          'sustainable development plan',
          'sustainability programme',
          'GHG protocol',
          'corporate sustainability reporting directive',
          'CSRD',
          'ESRS',
          'european sustainability reporting standards',
          'GRI',
          'global reporting initiative',
          'GRI standards',
          'IFRS',
          'Integrated Reporting Framework',
          'UN Sustainable Development Goals',
          'SDGs',
          'Carbon accounting',
          'CO2 accounting',
          'GHG accounting',
          'Environmental accounting',
          'Disclosure',
          'Climate disclosure',
          'ESG strategy disclosure',
          'Decarbonization targets',
          'Decarbonization roadmap',
          'Decarbonisation targets',
          'Decarbonisation roadmap',
          'regulatory emissions reporting',
          'decarbonization management',
          'decarbonisation management',
          'Reporting Consulting',
          'Reporting advisory',
          'GRI reporting',
          'Climate risk reporting',
        ],
        route: RoutingTypes.SUSTAINABILITY_REPORTING
      },
      IT: {
        slug: 'reporting',
        keywords: [
          'report esg',
          'assessment di sostenibilità',
          'report di sostenibilità',
          'piano di sostenibilità',
          'report d’impatto',
          'performance di sostenibilità',
          'rendicontazione di sostenibilità',
          'reportistica esg',
          'reportistica sostenibilità',
          'rendicontazione esg',
          'piano di sviluppo sostenibile',
          'rapporto di sostenibilità',
          'report di sostenibilità',
          'reporting di sostenibilità',
          'protocollo GHG',
          'CSRD',
          'direttiva europea reportistica di sostenibilità',
          'Direttiva europea CSRD',
          'ESRS',
          'Standard di rendicontazione sulla sostenibilità',
          'GRI',
          'Standard GRI',
          'guida standardizzata per la rendicontazione ESG',
          'Obiettivi di Sviluppo Sostenibile dell’ONU',
          'SDGs',
          'Carbon accounting',
          'CO2 accounting',
          'GHG accounting',
          'Obiettivi di decarbonizzazione',
          'Consulenza reporting',
          'Reportistica GRI',
          'Tabella di marcia per la decarbonizzazione',
          'Roadmap per la decarbonizzazione',
          'gestione processo decarbonizzazione',
          'Reportistica rischio climatico'
        ],
        route: RoutingTypes.SUSTAINABILITY_REPORTING_IT
      }
    },
    ENGAGEMENT: {
      EN: {
        slug: "engagement",
        keywords: [
          'employee engagement',
          'stakeholders management',
          'stakeholder management',
          'stakeholder engagement',
          'community engagement',
          'CSR',
          'corporate responsibility',
          'corporate social responsibility',
          'engagement strategy',
          'stakeholders relations',
          'stakeholder relations',
          'inclusive engagement',
          'esg strategy engagement',
          'governance',
          'corporate governance',
          'employee wellbeing',
          'employee motivation',
          'work culture',
          'company culture',
          'workplace wellness',
          'HR',
          'human resources engagement',
          'employee retention',
          'engaged workforce',
          'talents management',
          'talents engagement',
          'talents acquisition',
          'workplace environment',
          'positive workplace',
          'best place to work',
          'change management',
          'internal branding',
          'organizational engagement',
          'investor relations',
          'investor relations strategy',
          'investor engagement',
          'business ethics',
          'social impact',
          'environmental stewardship',
          'supply chain engagement'
          ],
        route: RoutingTypes.ENGAGEMENT
      },
      IT: {
        slug: "engagement",
        keywords: [
          'engagement aziendale',
          'employee engagement',
          'engagement dei dipendenti',
          'gestione degli stakeholder',
          'CSR',
          'responsabilità aziendale',
          'responsabilità sociale d\'impresa',
          'strategia di coinvolgimento',
          'relazioni con gli stakeholder',
          'coinvolgimento inclusivo',
          'impegno per la strategia esg',
          'governance',
          'governance aziendale',
          'coinvolgimento dei dipendenti',
          'benessere dei dipendenti',
          'motivazione dei dipendenti',
          'cultura del lavoro',
          'cultura aziendale',
          'benessere sul posto di lavoro',
          'risorse umane',
          'coinvolgimento delle risorse umane',
          'fidelizzazione dei dipendenti',
          'coinvolgimento forza lavoro',
          'gestione dei talenti',
          'coinvolgimento dei talenti',
          'acquisizione dei talenti',
          'ambiente di lavoro',
          'ambiente di lavoro positivo',
          'gestione del cambiamento',
          'branding interno',
          'impegno organizzativo',
          'relazioni con gli investitori',
          'strategia di relazioni con gli investitori',
          'coinvolgimento degli investitori',
          'etica aziendale',
          'impatto sociale',
          'coinvolgimento fornitori',
          'coinvolgimento della catena di fornitura',
          'coinvolgimento della catena di approvvigionamento',
          'team coinvolto',
          'coinvolgimento del personale'
          ],
        route: RoutingTypes.ENGAGEMENT_IT
      }
    }
  },
  ADMIN: {
    HOME: {
      slug: 'admin',
      keywords: [],
      route: RoutingTypes.ADMIN
    },
    DASHBOARD: {
      slug: 'dashboard',
      keywords: [],
      route: RoutingTypes.DASHBOARD
    },
    PROJECTS: {
      LIST: {
        slug: 'projects',
        keywords: [],
        route: RoutingTypes.PROJECTS_ADMIN
      },
      IMPACT: {
        slug: 'project',
        keywords: [],
        route: RoutingTypes.PROJECTS_ADMIN
      },
    }
  }
}
