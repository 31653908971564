import { Component, Input } from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { BaseComponent } from 'src/app/pages/_base/base/base.component';
import { ImageTypes } from 'src/app/_models/components/image-types';
import { ImagesService } from 'src/app/_services/images.service';
import {Response} from '../../_models/api/response';
import {ImagesInterface} from '../../_models/api/images-interface';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileInputComponent,
      multi: true
    }
  ]
})
export class FileInputComponent extends BaseComponent implements ControlValueAccessor{
  
  @Input() style: string;
  
  //@Input() imageTypes: typeof ImageTypes[keyof typeof ImageTypes];
 //@Input() imageFormControl: UntypedFormControl;
  file: ImagesInterface
  imageUrl: string;
  disabled: boolean = false
  constructor(
    private imagesService: ImagesService
  ) {
    super();
  }



  public files: NgxFileDropEntry[] = [];

  ngOnInit(): void {
  }

  public dropped(files: NgxFileDropEntry[]): void {
    this.files = files;
    this.imageUrl = null
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file(async (file: File) => {
          // Here you can access the real file
          if(file.type.includes('image')){
            const arrayBuffer = await file.arrayBuffer()
            const blob = new Blob([arrayBuffer], { type: 'image/jpeg' }); // Adjust the MIME type as needed
            this.imageUrl = URL.createObjectURL(blob);
          }
          
          this.onChange({
            file: file,
            fileEntity: null
          })
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  public fileOver(event): void {
  }

  public fileLeave(event): void {
  }


  onChange: (value: any) => void = () => { };
  onTouched: () => void = () => { };


  writeValue(value: {file: any, fileEntity: ImagesInterface}): void {
    this.file = value.file
    this.imageUrl = value.fileEntity.url
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void { }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
