import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../_base/base/base.component';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { WhitelabelModalComponent } from '../whitelabel-modal/whitelabel-modal.component';
import { StorageName } from 'src/app/_models/components/storage-name';
import { CompanyProfileInterface } from 'src/app/_models/api/whitelabel/whitelabel-interface';
import { PartnershipNames } from 'src/app/_models/components/partnership-names';
import { ModalTypes } from 'src/app/_models/components/whitelabel-types';

@Component({
  selector: 'app-whitelabel-publish',
  templateUrl: './whitelabel-publish.component.html',
  styleUrls: ['./whitelabel-publish.component.scss'],
})
export class WhitelabelPublishComponent extends BaseComponent implements OnInit {
  preview: boolean = false;
  profile: CompanyProfileInterface;
  companyId: string;

  constructor(
    private bannerRef: MatBottomSheetRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.preview = this.router.url.includes('preview');
    this.companyId = localStorage.getItem(StorageName.companyId);
    if (localStorage.getItem(StorageName.partnership) == PartnershipNames.whitelabel) {
      this.profile = JSON.parse(localStorage.getItem(StorageName.profile)) as CompanyProfileInterface;
    }
  }

  reset = async(type: string = 'current') => {
    this.dialog.open(WhitelabelModalComponent, {
      data: {
        type: type == 'current' ? ModalTypes.RESET.CURRENT : ModalTypes.RESET.DEFAULT,
        id: this.companyId,
        resetType: type,
      }
    });
    this.bannerRef.dismiss();
  }

  publish = async() => {
    this.dialog.open(WhitelabelModalComponent, {
      data: {
        type: ModalTypes.PUBLISH,
        id: this.companyId
      }
    });
    this.bannerRef.dismiss();
  }

  goToWhitelabelSettings = async() => {
    window.history.back()
    //await this.router.navigate(['admin/whitelabel/general']);
  }
}
