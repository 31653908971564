<div class="gfp-tooltip-wrapper" [ngClass]="" [ngClass]="{'reteclima': reteclima, 'adnoc': adnoc, 'aldar': aldar, 'tgi': tgi, 'delsey': partner == 'delsey', 'whitelabel': whitelabel}">
  <div class="icon" [appCustomTooltip]="tooltipTemplate">
    <svg-icon [ngClass]="invert=='y' ? 'invert' : ''" class="info" src="{{icon}}"
      [svgClass]="'align-center'" [svgStyle]="{'height.px':'19', 'width.px':'20'}">
    </svg-icon>
  </div>
  
</div>

<ng-template #tooltipTemplate>
  <div class="gfp-tooltip">
    <div class="gfp-tooltip-content">
      <div>
        <h2 class="mb-2 lato" [innerHtml]="title"></h2>
        <p class="mb-0 lato" [innerHtml]="body"></p>
      </div>
      <div *ngIf="title2 || body2">
        <h2 class="mb-2 lato" [innerHtml]="title2"></h2>
        <p class="mb-0 lato" [innerHtml]="body2"></p>
      </div>
    </div>
    <!-- <div class="tooltip-action d-md-flex mt-3" *ngIf="link">
      <button class="me-3" (click)="toggleTooltip()" mat-stroked-button color="green">
        {{'gotIt' | translate}}
      </button>
      <button (click)="goToLink()" mat-raised-button color="yellow">
        {{'aboutPublic.learn' | translate}}
      </button>
    </div> -->
<!--     <div class="tooltip-arrow"></div>
 -->  </div>
</ng-template>
