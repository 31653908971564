export const ClimatePioneerTypes = {
  automatic : 'automatic',
  manual : 'manual'
};

export const ClimatePioneerModels = {
  impactPerItem: 'impactPerItem',
  impactPerOrder: 'impactPerOrder',
  revenuePercentage: 'revenuePercentage'
}

export const CommunityContributionModels = {
  optIn: 'opt-in',
  optOut: 'opt-out'
}
