<div class="modalEdit">
  <h2 mat-dialog-title>{{'modalEdit.edit'|translate}}</h2>
  <mat-dialog-content>
    <div class="container" ngxStripeCardGroup>
      <div class="row paymentInput">
        <div class="col-12">
          <div class="my-3">
            <ngx-stripe-card
              [options]="cardOptions"
              [elementsOptions]="elementsOptions"></ngx-stripe-card>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-end">
    <button mat-button [mat-dialog-close]="false">{{'modalEdit.cancel'|translate}}</button>
    <button (click)="send()" [disabled]="disabled" mat-button cdkFocusInitial>{{'modalEdit.add'|translate}}</button>
  </mat-dialog-actions>
</div>
