import { Injectable } from '@angular/core';
import { BillingAddressInterface } from 'src/app/_models/billing-address-interface';

@Injectable({
  providedIn: 'root'
})
export class PaymentInfosService {

  constructor() { }

  currentPaymentInfo?: PaymentInfo

  setInfos(infos: PaymentInfo){
    this.currentPaymentInfo = infos
  }

  clearInfos(){
    this.currentPaymentInfo = null
  }
}

export interface PaymentInfo{
  billingAddress?: BillingAddressInterface;
  cart?: any[]
  taxPercent?: number;
}
