import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BaseComponent} from '../../pages/_base/base/base.component';
import { StorageName } from 'src/app/_models/components/storage-name';
import { PartnershipNames } from 'src/app/_models/components/partnership-names';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})

export class InfoComponent extends BaseComponent implements OnInit {
  visible = false;
  bubble: any;
  @Input() icon: string = `${this.cdn}/icons/tooltip.svg`;
  @Input() selector: string = 'default';
  @Input() theme: string = 'dark';
  @Input() title: string;
  @Input() body: string;
  @Input() title2: string;
  @Input() body2: string;
  @Input() link: string;
  @Input() positionX: string = 'left';
  @Input() positionY: string = 'bottom';
  @Input() invert: string;
  @Input() partner: string;
  reteclima = localStorage.getItem(StorageName.partnership) === PartnershipNames.reteclima;
  adnoc = localStorage.getItem(StorageName.partnership) === PartnershipNames.adnoc;
  aldar = localStorage.getItem(StorageName.partnership) === PartnershipNames.aldar;
  tgi = localStorage.getItem(StorageName.partnership) === PartnershipNames.tgi;
  whitelabel = localStorage.getItem(StorageName.partnership) === PartnershipNames.whitelabel;

  username: string;

  tooltipVisible: boolean = false;
  maxWidth: number;

  constructor(
    public router: Router,
) {
    super();
  }

  ngOnInit(): void {
    this.bubble = document.getElementById('info-bubble-' + this.selector);
    this.setSize();
  }

  ngAfterContentChecked() {
    this.setSize();
  }

  toggleTooltip(): void {
    this.tooltipVisible = !this.tooltipVisible;
  }

  setSize() {
    if (window.innerWidth < 1000) {
      this.positionX = 'left';
      // this.positionY = 'bottom';
    }
  }

  onResize(event) {
    this.setSize();
  }

  show(): void {
    this.visible = true;
    this.bubble?.classList.add('visible');
  }

  hide(): void {
    this.visible = false;
    this.bubble?.classList.remove('visible');
  }

  display(): boolean {
    this.bubble = document.getElementById('info-bubble-' + this.selector)
    if (this.visible) {
      this.hide();
    } else {
      this.show();
    }
    return this.visible;
  }

  goToLink = async () => {
    if (this.link) {
      await this.router.navigate([this.link]);
    }
  }
}
