<!-- <div class="widget-wrapper">
  <div class="tooltip" [ngClass]="visible ? 'visible' : ''">
    <span>I'm in the tooltip</span>
  </div>
  <svg-icon class="tooltip-icon" src="{{icon}}" (click)="toggleVisibility()"
            [svgClass]="'align-center'" [svgStyle]="{'height.px':'20', 'width.px':'20'}"></svg-icon>
</div> -->

<div class="gfp-widget-tooltip" [ngClass]="{'whitelabel': whitelabel}" [appCustomTooltip]="tooltipTemplate">
  <div class="icon">
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0C4.47768 0 0 4.47768 0 10C0 15.5223 4.47768 20 10 20C15.5223 20 20 15.5223 20 10C20 4.47768 15.5223 0 10 0ZM10 18.3036C5.41518 18.3036 1.69643 14.5848 1.69643 10C1.69643 5.41518 5.41518 1.69643 10 1.69643C14.5848 1.69643 18.3036 5.41518 18.3036 10C18.3036 14.5848 14.5848 18.3036 10 18.3036Z"/>
      <path d="M12.4907 5.63993C11.8211 5.05287 10.9372 4.73145 9.99965 4.73145C9.06215 4.73145 8.17822 5.05511 7.50858 5.63993C6.81215 6.2493 6.42822 7.0685 6.42822 7.94573V8.11537C6.42822 8.21359 6.50858 8.29395 6.60679 8.29395H7.67822C7.77644 8.29395 7.85679 8.21359 7.85679 8.11537V7.94573C7.85679 6.96136 8.81885 6.16002 9.99965 6.16002C11.1805 6.16002 12.1425 6.96136 12.1425 7.94573C12.1425 8.63993 11.6514 9.27609 10.8903 9.5685C10.4171 9.7493 10.0153 10.0663 9.72733 10.4814C9.43492 10.9056 9.28313 11.4145 9.28313 11.9301V12.41C9.28313 12.5082 9.36349 12.5886 9.46171 12.5886H10.5331C10.6313 12.5886 10.7117 12.5082 10.7117 12.41V11.9033C10.7129 11.6866 10.7793 11.4753 10.9023 11.2969C11.0254 11.1185 11.1993 10.9814 11.4014 10.9033C12.7184 10.3966 13.5688 9.23591 13.5688 7.94573C13.5711 7.0685 13.1872 6.2493 12.4907 5.63993ZM9.10679 14.91C9.10679 15.1468 9.20086 15.3739 9.36831 15.5414C9.53575 15.7088 9.76285 15.8029 9.99965 15.8029C10.2365 15.8029 10.4636 15.7088 10.631 15.5414C10.7984 15.3739 10.8925 15.1468 10.8925 14.91C10.8925 14.6732 10.7984 14.4461 10.631 14.2787C10.4636 14.1112 10.2365 14.0172 9.99965 14.0172C9.76285 14.0172 9.53575 14.1112 9.36831 14.2787C9.20086 14.4461 9.10679 14.6732 9.10679 14.91Z"/>
    </svg>
  </div>
</div>
  <ng-template #tooltipTemplate>
    <div class="gfp-tooltip p-4" [class.visible]="tooltipVisible" [style.max-width.px]="maxWidth" (window:resize)="onResize()">
      <div class="gfp-tooltip-content">
        <div>
          <h2 class="mb-2 lato" [innerHtml]=" 'widgetTooltip.scienceBacked.heading' | translate "></h2>
          <p class="mb-0 lato" [innerHtml]="'widgetTooltip.scienceBacked.explanation' | translate "></p>
        </div>
        <div>
          <h2 class="mb-2 lato" [innerHtml]="'widgetTooltip.certified.heading' | translate "></h2>
          <p class="mb-0 lato" [innerHtml]="'widgetTooltip.certified.explanation' | translate "></p>
        </div>
      </div>
      <div class="tooltip-arrow"></div>
    </div>
  </ng-template>
