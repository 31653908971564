<div class="h-90 bg-transparent position-relative rounded-3 text-dark whitelabel-modal" id="whitelabelModal">
  <div class="chivo bold modal-title mb-3 me-4">{{'whitelabel.modal.' + type + '.title'|translate}}</div>
  <div class="lato sm modal-body">{{'whitelabel.modal.' + type + '.body'|translate}}</div>
  <div class="d-flex align-items-center justify-content-end mt-4">
    <button mat-stroked-button mat-dialog-close color="green" class="me-3">
      {{'whitelabel.modal.cancel'|translate}}
    </button>
    <button mat-raised-button mat-dialog-close color="warn" (click)="deleteUser()" *ngIf="type==types.DELETE">
      {{'whitelabel.modal.' + type + '.button'|translate}}
    </button>
    <button mat-raised-button mat-dialog-close color="yellow" (click)="transferAdmin()" *ngIf="type==types.TRANSFER">
      {{'whitelabel.modal.' + type + '.button'|translate}}
    </button>
    <button mat-raised-button mat-dialog-close color="yellow" (click)="reset()" *ngIf="type==types.RESET.CURRENT || type==types.RESET.DEFAULT">
      {{'whitelabel.modal.' + type + '.button'|translate}}
    </button>
    <button mat-raised-button mat-dialog-close color="yellow" (click)="publish()" *ngIf="type==types.PUBLISH">
      {{'whitelabel.modal.' + type + '.button'|translate}}
    </button>
  </div>
</div>
