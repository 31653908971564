import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/pages/_base/base/base.component';
import { CounterValues } from 'src/app/_models/components/counter-values';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import domtoimage from 'dom-to-image-more';
import { ImpactCardService } from 'src/app/_services/impact-card/impact-card.service';
import { ShareButtonsDialogComponent } from 'src/app/dialogs/share-buttons-dialog/share-buttons-dialog.component';
import { StorageName } from 'src/app/_models/components/storage-name';
import { PartnershipNames } from 'src/app/_models/components/partnership-names';

export interface UploadUrlResponse {
  data: {
    url: string,
    key: string
  },
  success: boolean
}

@Component({
  selector: 'app-impact-card',
  templateUrl: './impact-card.component.html',
  styleUrls: ['./impact-card.component.scss']
})
export class ImpactCardComponent extends BaseComponent implements OnInit {
  @Input() impactType: string;
  @Input() area: any;
  @Input() co2: any;
  @Input() trees: number;
  @Input() renewable: number;
  @Input() renewableUnit: string;  
  @Input() co2NotCertified: any;
  @Input() co2Certified: any;
  @Input() clientName: string;
  @Input() reteclima = false;
  @Input() adnoc = false;
  @Input() aldar = false;
  @Input() tgi = false;
  whitelabel = localStorage.getItem(StorageName.partnership) === PartnershipNames.whitelabel;

  lines: string[];
  co2Str: string;
  areaStr: string;
  tennisCourtsArea: number;
  tennisCourtsTrees: number;
  flights: number;
  households: number;
  dialogData: any;
  downloadClicked: boolean = false;
  shareClicked: boolean = false;
  filename: string;
  uploadURL: any;
  awsObjectKey: string;
  filenames:any[]= [
    {file:'total_Summary.jpeg'},
    {file:'carbon_offset.jpeg'},
    {file:'restoration.jpeg'},
    {file:'preservation.jpeg'},
    {file:'renewable_energy.jpeg'}
  ]
  windowWidth:any = window.innerWidth;
  pluralS = '';

  constructor(@Inject(MAT_DIALOG_DATA) public dialogTypeData: any, private impactCardService: ImpactCardService) {
    super();
    this.dialogData = dialogTypeData;
}

  ngOnInit(): void {
    if (this.dialogData && this.dialogData.type) {
      this.impactType = this.dialogData.type;
      this.clientName = this.dialogData.clientName;
    }
    this.area = this.convertAreaUnits(this.dialogData.m2);
    this.areaStr = this.humanize(this.area.value) + ` ${this.area.unit} ${this.translate.instant('admin.protected')}`
    this.co2 = this.convertCo2Units(this.dialogData.co2);
    this.co2Str = this.humanize(this.co2.value) + ` ${this.co2.unit} ${this.translate.instant('admin.co2Offset')}`
    this.trees = this.dialogData?.trees;
    this.renewable = this.dialogData?.renewable;
    this.renewableUnit = this.dialogData?.renewableUnit;
    this.co2Certified = this.convertCo2Units(this.dialogData.co2Certified);
    this.co2NotCertified = this.convertCo2Units(this.dialogData.co2NotCertified);
    this.tennisCourtsArea = this.dialogData.m2 / 10000 * CounterValues.hectare_tennisCourt;
    this.tennisCourtsTrees = this.trees * CounterValues.tree_tennisCourt;
    this.flights = Math.round(this.dialogData.co2 / 1000 * CounterValues.tonne_flight);
    if (this.renewableUnit == "mWh")
      this.households = this.renewable * 1000 * CounterValues.kw_household;
    else
      this.households = this.renewable * CounterValues.kw_household;
    this.filename = this.impactType + '.jpeg';
    this.impactCardService.getUploadUrl().then((response: UploadUrlResponse) => {
      this.uploadURL = response.data.url;
      this.awsObjectKey = response.data.key;
    });
    this.reteclima = this.dialogData.reteclima;
    this.adnoc = this.dialogData.adnoc;
    this.aldar = this.dialogData.aldar;
    this.tgi = this.dialogData.tgi;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowWidth = event.target.innerWidth;
  }

  async downloadAll() {
    document.querySelectorAll('.impact_card').forEach((node: any, i: number) => {
      const filename = this.filenames[i].file;
      this.downloadClicked = true;
      this.domToBase64(node).then((dataUrl) => {
        const url = document.createElement('a');
        url.download = filename;
        url.href = dataUrl;
        url.click();
        this.downloadClicked = false;
      });
    });
  }

  async download(id:number) {
    const filename = this.filenames[id].file;
    this.downloadClicked = true;
    let node: any = document.querySelectorAll('.impact_card');
    this.domToBase64(node[id]).then((dataUrl) => {
      let url = document.createElement('a');
      url.download = filename;
      url.href = dataUrl;
      url.click();
    }).then(() => {
      this.downloadClicked = false;
    })
}

  async share() {
    const filename = this.filename;
    this.shareClicked = true;
    let node: HTMLElement = document.querySelector('.impact_card');
    const file = await this.domToBase64(node).then(async (dataUrl) => {
      return await fetch(dataUrl)
        .then(res => res.blob())
        .then(blob => new File([blob], filename ,{ type: "image/jpeg" }))
    });
    await this.impactCardService.uploadImage(this.uploadURL, file).then((response) => {
      this.dialog.open(ShareButtonsDialogComponent, {
        data: {
          url: `${this.cdn}/${this.awsObjectKey}`,
        }
      });
    }).then(() => {
      this.shareClicked = false;
    })
  }

  private async domToBase64(node: any) {
    const scale = 2;
    return await domtoimage.toJpeg(node, {
      width: node.clientWidth*scale,
      height: node.clientHeight*scale,
      style: {
        transform: 'scale('+scale+')',
        transformOrigin: 'top left'
      }
    })
  }

  private convertCo2Units(co2: number): any {
    if (co2 > 1000) {
      return {
        unit: this.translate.instant('admin.tons'),
        value: co2 / 1000 }
    } else {
      return { unit: 'kg', value: co2 }
    }
  }

  private convertAreaUnits(area: number): any {
    if(area > 10000){
      return {
        unit: this.translate.instant('admin.hectares'),
        value: area / 10000
      }
    }else{
      return {
        unit: this.translate.instant('admin.m2'),
        value: area
      }
    }
  }
}
