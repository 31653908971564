<mat-accordion *ngIf="integrationType !== 'nativa'" >
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="mat-body-1">
          {{'nativa.orderSummary.sectionThree.heading' | translate }}
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="d-flex justify-content-between">
      <span class="mat-caption">{{'orderSummary.baseAmount' | translate }}</span>
      <span class="mat-caption">{{'orderSummary.€' | translate : { value: priceBreakDown['baseAmount'] | number: '1.2-2'} }}</span>
    </div>
    <div class="d-flex justify-content-between">
      <span class="mat-caption">{{ 'orderSummary.taxAmount' | translate }}</span>
      <span class="mat-caption">{{'orderSummary.€' | translate : { value: priceBreakDown['taxAmount'] | number: '1.2-2'} }}</span>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="integrationType === 'nativa'">
  <mat-expansion-panel [expanded]="true" class="bg-expansion-panel">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="mat-body-1 fw-semibold">
          {{'nativa.orderSummary.sectionThree.heading' |translate}}
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="">
      <hr class="mt-0 h-0" style="border-top: 1px solid #8a8a8a">
      <div class="d-flex gap-4 justify-content-between align-items-baseline px-1">
        <span class="mat-body-1 fw-semibold">{{'nativa.orderSummary.sectionThree.totalPrice' |translate}}</span>
        <span class="mat-body-1">{{'orderSummary.€' | translate : { value: priceBreakDown['baseAmount'] | number: '1.2-2'} }}</span>
      </div>
    </div>
    <div class="">
      <div class="d-flex gap-4 justify-content-between align-items-baseline px-1">
        <span class="mat-body-1 fw-semibold">{{'nativa.orderSummary.sectionThree.taxAmount' |translate}}</span>
        <span class="mat-body-1">{{'orderSummary.€' | translate : { value: priceBreakDown['taxAmount'] | number: '1.2-2'} }}</span>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>