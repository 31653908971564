import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ModelViewerElement } from '@google/model-viewer';
import { BaseComponent } from '../../pages/_base/base/base.component';
import { ProjectSlugs } from '../../_models/components/project-slugs';
import { ProjectsInterface } from '../../_models/api/projects/projects-interface';
import { ProjectsService } from 'src/app/_services/projects/projects.service';
import { Response } from '../../_models/api/response';
import { StorageName } from '../../_models/components/storage-name';
import { PartnershipNames } from 'src/app/_models/components/partnership-names';
import { CompanyProfileInterface } from 'src/app/_models/api/whitelabel/whitelabel-interface';

@Component({
  selector: 'app-globe',
  templateUrl: './globe.component.html',
  styleUrls: ['./globe.component.scss']
})
export class GlobeComponent extends BaseComponent implements OnInit {
  calculationCompleted: boolean = false
  showExperience: boolean = true;
  @Input() projects: ProjectsInterface[];
  @Input() profile: CompanyProfileInterface | null = null;
  @Input() isDashboardHome: boolean = false;
  @Input() isAdmin: boolean = false;
  @Input() reteclima = false;
  @Input() adnoc = false;
  @Input() aldar = false;
  @Input() tgi = false;
  @Input() delsey = false;
  selected: ProjectsInterface;

  projectCountry: string;
  selectedSlug: string;
  selectedName: string;
  selectedType: string;
  selectedImage: string;

  yas = ProjectSlugs.YAS;
  sagar = ProjectSlugs.SAGAR;

  @Input() page: string = 'landing';

  consent: boolean = false;
  // business: boolean = localStorage.getItem('clientType') === '2';
  business: boolean = true;

  partnership = localStorage.getItem(StorageName.partnership);

  currentTermlyKey = localStorage.getItem('TERMLY_API_CACHE');
  termlyKey;
  intervalId;

  projectsSlugs: string[] = [""];

  constructor(private projectService: ProjectsService,) {
    super();
  }

  ngOnInit(): void {
    if (!this.delsey && !this.isDashboardHome){
      this.aldar = this.partnership === PartnershipNames.aldar;
      this.tgi = this.partnership === PartnershipNames.tgi;
      this.delsey = this.partnership === PartnershipNames.delsey;
    }
    
    this.loadProjects().then(res => {
      this.mapProjectsForGlobePositionCalculations()
    }
    );
    this.showButtons(this.currentTermlyKey);
    let self = this;
    this.intervalId = setInterval(function () {
      if (!self.consent) {
        self.currentTermlyKey = localStorage.getItem('TERMLY_API_CACHE');
        if (self.termlyKey != self.currentTermlyKey) self.termlyKey = self.currentTermlyKey;
        self.showButtons(self.termlyKey);
      } else {
        window.clearInterval(self.intervalId);
      }
    }, 2000);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    const modelViewer = document.getElementById('myModelViewer');
    if (event.target.innerWidth <= 600) {
      modelViewer.setAttribute('disable-zoom', '');
    } else {
      modelViewer.removeAttribute('disable-zoom');
    }
  }

  openProject = async (slug: string): Promise<void> => {
    await this.router.navigate(['admin/projects', slug]);
  }

  interactedWithCanvas(event: any) {
    if (event.detail.source === 'user-interaction') {
      this.showExperience = false
    }
  }

  clickHotspot(event, slug): void {
    const selectedLang = localStorage.getItem('lang');
    const modelViewer: ModelViewerElement = event.parentNode;
    const hotspot = event.getElementsByClassName('hotspotPopUp')[0];
    if (hotspot !== undefined) {
      if (hotspot.classList.value.includes('active')) {
        hotspot.classList.remove('active');
        modelViewer.autoRotate = true;
      } else {
        const hotspots = modelViewer.getElementsByClassName('hotspotPopUp');
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < hotspots.length; i++) {
          if (hotspots[i].classList.value.includes('active')) {
            hotspots[i].classList.remove('active');
          }
        }
        hotspot.classList.add('active');
        modelViewer.autoRotate = false;
      }
    }
    this.projects.forEach((project) => {
      if (project.slug == slug) {
        this.selected = project;
      }
    });
    if (selectedLang == 'it') {
      this.projectCountry = this.selected.country.name_it;
      this.selectedName = this.selected.name_it;
      this.selectedType = this.selected.type.name_it;
    } else if (selectedLang == 'jp') {
      this.projectCountry = this.selected.country.name_jp;
      this.selectedName = this.selected.name_jp;
      this.selectedType = this.selected.type.name_jp;
    } else {
      this.projectCountry = this.selected.country.name;
      this.selectedName = this.selected.name;
      this.selectedType = this.selected.type.name;
    }
    this.selectedSlug = this.selected.slug;
    this.selectedImage = this.getBackground(this.selected);
  }



  loadProjects = async () => {
    try {
      if (!this.projects || this.projects.length == 0) {
        let response: Response<ProjectsInterface[]>;
        if (this.aldar)
          response = await this.projectService.getPublicProjectBySlug(this.yas);
        else if (this.tgi)
          response = await this.projectService.getPublicProjectBySlug(this.sagar);
        else
          response = await this.projectService.getPublicProjects();
        if (response?.success) {
          if (Array.isArray(response.data))
            this.projects = response.data;
          else
            this.projects = [response.data];
        } else {
          this.showErrorResponse(response);
        }
      }
      this.projectsSlugs = this.projects.map(p => p.slug);
    } catch (e) {
      this.showErrorResponse(e);
    }
  }

  getBackground = (activeProject: ProjectsInterface): any => {
    const bgJson: any = {};
    bgJson.background = `url('${activeProject.image.url}')`;
    return bgJson;
  }

  showButtons(termlyKey): void {
    if (termlyKey) {
      let termly = JSON.parse(termlyKey);
      if (termly.TERMLY_COOKIE_CONSENT && termly.TERMLY_COOKIE_CONSENT["value"]["unclassified"]) {
        this.consent = true;
        window.clearInterval(this.intervalId);
      }
    }
  }

  requestCookies(): void {
    localStorage.removeItem('TERMLY_API_CACHE');
    window.location.reload();
  }


  private mapProjectsForGlobePositionCalculations() {
    this.projects = this.projects.map(project => {
      return {
        ...project,
        globeData: {
          position: this.latLonToXYZ(project.projectData.latitude, project.projectData.longitude)
        }
      }
    });

    this.calculationCompleted = true
  }

  latLonToXYZ(latitude: number, longitude: number): string {

    const radius = 1; // Ensure this matches your model's scale
    const phi = latitude * (Math.PI / 180); // Latitude conversion to radians
    const lambda = longitude * -1 * (Math.PI / 180); // Longitude conversion to radians

    // Cartesian coordinates calculation
    const x = radius * Math.cos(phi) * Math.cos(lambda);
    const y = radius * Math.sin(phi); // This correctly maps latitude to the vertical axis
    const z = radius * Math.cos(phi) * Math.sin(lambda);

    return `${x}m ${y}m ${z}m`;
  }
}
