<div id="impact_card_preview_wrapper" [ngClass]="{'reteclima': reteclima, 'tgi': tgi, 'whitelabel': whitelabel}">
  <carousel [height]="720"
    [cellWidth]="windowWidth > 992 ? 540 : 300"
    [width]="windowWidth > 992 ? 694 : 370"
    [cellsToShow]="1">
    <div class="carousel-cell" [ngClass]="{ 'w-auto': windowWidth < 412 }">
      <div [style]="windowWidth > 992 ? 'width:542px;' : 'width:302px;'">
        <h1 class="fw-semibold fs-2 black">
          {{"yourImpact.previewYourImpactCards" | translate}}
        </h1>
      <mat-icon class="close"  mat-dialog-close >clear</mat-icon>
      </div>
      <div #total class="impact_card img_summary pt-lg-4 mb-5 bg-cover pt-2"
        [ngClass]="{ 'w-300 h-300': windowWidth < 992 }">
        <div id="top_bar" class="d-flex justify-content-between align-items-center my-0 mx-4">
          <span class="your_impact flex-shrink-1">{{'projectDetails.yourImpactFull'|translate:{name: clientName,pluralS:pluralS} | uppercase}}</span>
          <div class="logoSection d-flex">
            <img class="icon partnerLogo" src="assets/images/tgi/prameya_logo_small.png" *ngIf="tgi"/>
            <svg-icon class="icon partnerLogo" src="assets/images/icons/reteclima_logo.svg" *ngIf="reteclima"></svg-icon>
            <span class="verticalDivider" *ngIf="tgi || reteclima"></span>
            <svg-icon class="icon" src="assets/images/svg/logoTitle.svg"></svg-icon>
          </div>
        </div>
        <div class="fw-light p-1 p-lg-4 mt-1 chivo summary-main">
          <span [class]=" windowWidth > 992 ?'fw-light fs-4 lh-1' : 'body'"
            [innerHtml]="translate.instant('dashboard.co2Offset')">
          </span>
          <div class="d-flex gap-2 my-lg-3 summary-main-subtitle align-items-center">
            <img src="assets/images/icons/co2.svg" alt="" />
            <span [class]="windowWidth > 992 ? 'title-2 fw-lighter' : 'title-4 fw-lighter'">
              <span class="fw-normal">{{humanize(co2.value)}} {{co2.unit}}</span>
            </span>
          </div>
          <div class="d-flex gap-lg-5 gap-1 my-1 totals my-lg-3 ">
            <div class="d-flex gap-2 align-items-end">
              <img class="icon" src="assets/images/icons/science.svg" alt="" />
              <div class="d-flex flex-column">
                <span [class]="windowWidth > 992 ? 'title-3 fw-lighter' : 'title-4 fw-lighter'">
                  <span class="fw-normal">{{humanize(co2NotCertified.value)}} {{co2NotCertified.unit}}</span>
                </span>
                <span  class="title-4 fs-6 fw-lighter">
                  {{"yourImpact.scienceBackedDescription" | translate}}
                </span>
              </div>
            </div>
            <div class="d-flex gap-2 align-items-end">
              <img class="icon"
                src="assets/images/icons/certified.svg"
                alt=""/>
              <div class="d-flex flex-column">
                <span [class]="windowWidth > 992 ? 'title-3 fw-lighter' : 'title-4 fw-lighter'">
                  <span class="fw-normal">{{humanize(co2Certified.value)}} {{co2Certified.unit}}</span>
                </span>
                <span class="title-4 fw-lighter">
                  {{"yourImpact.tonsCertifiedDescription" | translate}}
                </span>
              </div>
            </div>
          </div>
          <hr class="my-1 my-lg-4"/>
          <span [class]=" windowWidth > 992 ?'fw-light fs-4 lh-1' : 'body'">
            {{"yourImpact.howYouOffsetIt" | translate}}
          </span>
          <div class="d-flex gap-lg-5 gap-1 my-1 totals my-lg-3">
            <div class="d-flex gap-2 align-items-end">
              <img class="icon" src="assets/images/icons/restoration.svg" alt="{{'projectType.restoration'|translate}}" />
              <div class="d-flex flex-column">
                <span [class]="windowWidth > 992 ? 'title-3 fw-lighter fs-6' : 'title-4 fw-lighter'">
                  {{"yourImpact.byplanting" | translate}}
                </span>
                <span class="title-4 fw-lighter">
                  <span class="fw-normal">{{humanize(trees)}}</span> {{"admin.trees" | translate}}
                </span>
              </div>
            </div>
            <div class="d-flex gap-2 align-items-end">
              <img
                class="icon"
                src="assets/images/icons/preservation.svg"
                alt=""
              />
              <div class="d-flex flex-column">
                <span [class]="windowWidth > 992 ? 'title-3 fw-lighter  fs-6' : 'title-4 fw-lighter'">
                  {{"yourImpact.byprotecting" | translate}}
                </span>
                <span class="title-4 fw-lighter">
                  <span class="fw-normal">{{humanize(area.value)}} </span>
                  <span [innerHtml]="area.unit"></span>
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex gap-2 totals my-0 align-items-end">
            <img class="icon" src="assets/images/icons/renewable.svg" alt="" />
           <div class="d-flex flex-column">
            <span [class]="windowWidth > 992 ? 'title-3 fw-lighter fs-6' : 'title-4 fw-lighter fs-6'">
              {{"yourImpact.byproducing" | translate}}
            </span>
            <span class="title-4"><span class="fw-normal">{{humanize(renewable)}}</span> {{renewableUnit}}</span>
           </div>
          </div>
        </div>
        <div class="d-flex justify-content-end px-2 mt-1">
          <div class="d-flex gap-2 align-items-center offset">
              <span [class]="windowWidth > 992 ? 'fs-5 fw-light': 'fs-7 fw-light'">{{
                "yourImpact.offsetBreakdown" | translate
              }}</span>
              <div><svg-icon class="icon" src="assets/images/icons/long-arrow.svg"></svg-icon></div>
          </div>
        </div>
      </div>
      <div class="d-flex gap-4 justify-content-center" [ngClass]="{ 'flex-column':windowWidth < 992 }">
        <button mat-raised-button [disabled]="shareClicked"
          color="yellow"
          (click)="download(0)">
          <img class="download-icon mx-1" src="assets/images/icons/arrow-down.svg" alt="">
          {{"yourImpact.downloadImpactCard" | translate}}
        </button>
        <button mat-stroked-button
          [disabled]="downloadClicked"
          color="green"
          (click)="downloadAll()">
        {{"yourImpact.downloadAllImpactCards" | translate}}
        </button>
      </div>
    </div>

    <div class="carousel-cell" [ngClass]="{ 'w-auto': windowWidth < 412 }">
      <div [style]="windowWidth > 992 ? 'width:542px;' : 'width:302px;'">
        <h1 class="fw-semibold fs-2">{{
          "yourImpact.previewYourImpactCards" | translate
        }}</h1>
      <mat-icon class="close"  mat-dialog-close >clear</mat-icon>
      </div>
      <div class="impact_card img_carbon_offset mb-5 pt-4"
        [ngClass]="{ 'w-300 h-300': windowWidth < 992 }">
        <div class="overlay" *ngIf="whitelabel">
          <img src="../../../../../assets/images/impact-cards/Carbon Offset.png"/>
        </div>
        <div id="top_bar" class="d-flex justify-content-between align-items-center my-0 mx-4">
          <span class="your_impact flex-shrink-1">{{'projectDetails.yourImpactFull'|translate:{name: clientName,pluralS:pluralS} | uppercase}}</span>
          <div class="impact_type_parent">
            <span class="impact_type bg_carbon_offset lato" [innerHTML]="'breadcrumb.co2Offset' | translate"></span>
          </div>
          <div class="logoSection d-flex">
            <img class="icon partnerLogo" src="assets/images/tgi/prameya_logo_small.png" *ngIf="tgi"/>
            <svg-icon class="icon partnerLogo" src="assets/images/icons/reteclima_logo.svg" *ngIf="reteclima"></svg-icon>
            <span class="verticalDivider" *ngIf="tgi || reteclima"></span>
            <svg-icon class="icon" src="assets/images/svg/logoTitle.svg"></svg-icon>
          </div>
        </div>
        <div id="lines" class="d-flex flex-column m-4">
          <div [class]="windowWidth > 992 ? 'line' : 'title-3'"
            [innerHtml]="co2Str"></div>
          <div [class]="windowWidth > 992 ? 'line' : 'title-3'">
            {{ "impactCard.carbon_offset.line2" | translate }}
          </div>
          <div [class]="windowWidth > 992 ? 'example_line mt-3 text_carbon_offset' : 'title-3 text_carbon_offset'">
            {{
              "impactCard.carbon_offset.line3"
                | translate : { value: humanize(flights) }
            }}
          </div>
          <div [class]="windowWidth > 992 ? 'example_line text_carbon_offset' : 'title-3 text_carbon_offset'" *ngIf="!aldar">
            {{ "impactCard.carbon_offset.line4" | translate }}
          </div>
          <div [class]="windowWidth > 992 ? 'example_line text_carbon_offset' : 'title-3 text_carbon_offset'" *ngIf="aldar">
            {{ "impactCard.carbon_offset.line4AbuDhabi" | translate }}
          </div>
          <div [class]="windowWidth > 992 ? 'smaller_line text_carbon_offset' : 'title-3 text_carbon_offset'">
            {{ "impactCard.carbon_offset.line5" | translate }}
          </div>
        </div>
      </div>
      <div class="d-flex gap-4 justify-content-center" [ngClass]="{ 'flex-column':windowWidth < 992 }">
        <button
          mat-raised-button
          [disabled]="shareClicked"
          color="yellow"
          (click)="download(1)"
        >
      <img class="download-icon mx-1" src="assets/images/icons/arrow-down.svg" alt="">
      {{
        "yourImpact.downloadImpactCard" | translate
      }}
      </button>
        <button
          mat-stroked-button
          [disabled]="downloadClicked"
          color="green"
          (click)="downloadAll()"
        >
        {{
          "yourImpact.downloadAllImpactCards" | translate
        }}
        </button>
      </div>
    </div>

    <div class="carousel-cell" [ngClass]="{ 'w-auto': windowWidth < 412 }">
      <div [style]="windowWidth > 992 ? 'width:542px;' : 'width:302px;'">
        <h1 class="fw-semibold fs-2">{{
          "yourImpact.previewYourImpactCards" | translate
        }}</h1>
      <mat-icon class="close"  mat-dialog-close >clear</mat-icon>
      </div>
      <div
        class="impact_card img_restoration mb-5 pt-4"
        [ngClass]="{ 'w-300 h-300': windowWidth < 992 }"
      >
      <div class="overlay" *ngIf="whitelabel">
        <img class="img-restoration" src="../../../../../assets/images/impact-cards/Restoration.png"/>
      </div>
        <div id="top_bar" class="d-flex justify-content-between align-items-center my-0 mx-4">
          <span class="your_impact flex-shrink-1">{{'projectDetails.yourImpactFull'|translate:{name: clientName,pluralS:pluralS} | uppercase}}</span>
          <div class="impact_type_parent">
            <span class="impact_type bg_restoration lato" [innerHTML]="'breadcrumb.restoration' | translate"></span>
          </div>
          <div class="logoSection d-flex">
            <img class="icon partnerLogo" src="assets/images/tgi/prameya_logo_small.png" *ngIf="tgi"/>
            <svg-icon class="icon partnerLogo" src="assets/images/icons/reteclima_logo.svg" *ngIf="reteclima"></svg-icon>
            <span class="verticalDivider" *ngIf="tgi || reteclima"></span>
            <svg-icon class="icon" src="assets/images/svg/logoTitle.svg"></svg-icon>
          </div>
        </div>
        <div id="lines" class="d-flex flex-column m-4">
          <div [class]="windowWidth > 992 ? 'line' : 'title-3'">
            {{
              "impactCard.restoration.line1"
                | translate : { value: humanize(trees) }
            }}
          </div>
          <div [class]="windowWidth > 992 ? 'line' : 'title-3'">
            {{ "impactCard.restoration.line2" | translate }}
          </div>
          <div [class]="windowWidth > 992 ? 'example_line mt-3 text_restoration' : 'title-3 text_restoration'">
            {{
              "impactCard.restoration.line3"
                | translate : { value: humanize(tennisCourtsTrees) }
            }}
          </div>
        </div>
      </div>
      <div class="d-flex gap-4 justify-content-center" [ngClass]="{ 'flex-column':windowWidth < 992 }">
        <button
          mat-raised-button
          [disabled]="shareClicked"
          color="yellow"
          (click)="download(2)"
        >
      <img class="download-icon mx-1" src="assets/images/icons/arrow-down.svg" alt="">
      {{
        "yourImpact.downloadImpactCard" | translate
      }}
      </button>
        <button
          mat-stroked-button
          [disabled]="downloadClicked"
          color="green"
          (click)="downloadAll()"
        >
        {{
          "yourImpact.downloadAllImpactCards" | translate
        }}
        </button>
      </div>
    </div>

    <div class="carousel-cell" [ngClass]="{ 'w-auto': windowWidth < 412 }">
      <div [style]="windowWidth > 992 ? 'width:542px;' : 'width:302px;'">
        <h1 class="fw-semibold fs-2">
          {{"yourImpact.previewYourImpactCards" | translate}}
        </h1>
      <mat-icon class="close"  mat-dialog-close >clear</mat-icon>
      </div>
      <div class="impact_card img_preservation mb-5 pt-4"
        [ngClass]="{ 'w-300 h-300': windowWidth < 992 }">
        <div class="overlay" *ngIf="whitelabel">
          <img class="img-preservation" src="../../../../../assets/images/impact-cards/Preservation.png"/>
        </div>
        <div id="top_bar" class="d-flex justify-content-between align-items-center my-0 mx-4">
          <span class="your_impact flex-shrink-1">{{'projectDetails.yourImpactFull'|translate:{name: clientName,pluralS:pluralS} | uppercase}}</span>
          <div class="impact_type_parent">
            <span class="impact_type bg_preservation lato" [innerHTML]="'breadcrumb.preservation' | translate"></span>
          </div>
          <div class="logoSection d-flex">
            <img class="icon partnerLogo" src="assets/images/tgi/prameya_logo_small.png" *ngIf="tgi"/>
            <svg-icon class="icon partnerLogo" src="assets/images/icons/reteclima_logo.svg" *ngIf="reteclima"></svg-icon>
            <span class="verticalDivider" *ngIf="tgi || reteclima"></span>
            <svg-icon class="icon" src="assets/images/svg/logoTitle.svg"></svg-icon>
          </div>
        </div>
        <div id="lines" class="d-flex flex-column m-4">
          <div>
            <div [class]="windowWidth > 992 ? 'line' : 'title-3'" [innerHtml]="areaStr"></div>
          </div>
          <div [class]="windowWidth > 992 ? 'line' : 'title-3'">
            {{ "impactCard.preservation.line2" | translate }}
          </div>
          <div [class]="windowWidth > 992 ? 'example_line mt-3 text_preservation' : 'title-3 text_preservation'">
            {{"impactCard.preservation.line3" | translate : { value: humanize(tennisCourtsArea) } }}
          </div>
        </div>
      </div>
      <div class="d-flex gap-4 justify-content-center" [ngClass]="{ 'flex-column':windowWidth < 992 }">
        <button
          mat-raised-button
          [disabled]="shareClicked"
          color="yellow"
          (click)="download(3)"
        >
      <img class="download-icon mx-1" src="assets/images/icons/arrow-down.svg" alt="">
      {{
        "yourImpact.downloadImpactCard" | translate
      }}
      </button>
        <button
          mat-stroked-button
          [disabled]="downloadClicked"
          color="green"
          (click)="downloadAll()"
        >
        {{
          "yourImpact.downloadAllImpactCards" | translate
        }}
        </button>
      </div>
    </div>

    <div class="carousel-cell" [ngClass]="{ 'w-auto': windowWidth < 412 }">
      <div [style]="windowWidth > 992 ? 'width:542px;' : 'width:302px;'">
        <h1 class="fw-semibold fs-2">{{
          "yourImpact.previewYourImpactCards" | translate
        }}</h1>
      <mat-icon class="close"  mat-dialog-close >clear</mat-icon>
      </div>
      <div
        class="impact_card img_renewable mb-5 pt-4"
        [ngClass]="{ 'w-300 h-300': windowWidth < 992 }"
      >
      <div class="overlay" *ngIf="whitelabel">
        <img src="../../../../../assets/images/impact-cards/Renewable Energy.png"/>
      </div>
        <div id="top_bar" class="d-flex justify-content-between align-items-center my-0 mx-4">
          <span class="your_impact flex-shrink-1">{{'projectDetails.yourImpactFull'|translate:{name: clientName,pluralS:pluralS} | uppercase}}</span>
          <div class="impact_type_parent">
            <span class="impact_type bg_renewable lato" [innerHTML]="'breadcrumb.renewable_energy' | translate"></span>
          </div>
          <div class="logoSection d-flex">
            <img class="icon partnerLogo" src="assets/images/tgi/prameya_logo_small.png" *ngIf="tgi"/>
            <svg-icon class="icon partnerLogo" src="assets/images/icons/reteclima_logo.svg" *ngIf="reteclima"></svg-icon>
            <span class="verticalDivider" *ngIf="tgi || reteclima"></span>
            <svg-icon class="icon" src="assets/images/svg/logoTitle.svg"></svg-icon>
          </div>
        </div>
        <div id="lines" class="d-flex flex-column m-4">
          <div [class]="windowWidth > 992 ? 'line' : 'title-3'">{{ "impactCard.renewable.line1" | translate }}</div>
          <div [class]="windowWidth > 992 ? 'line' : 'title-3'">{{ "impactCard.renewable.line2" | translate }}</div>
          <div [class]="windowWidth > 992 ? 'example_line mt-3 text_renewable' : 'title-3 text_renewable'">
            {{
              "impactCard.renewable.line3"
                | translate : { value: humanize(households) }
            }}
          </div>
          <div [class]="windowWidth > 992 ? 'smaller_line text_renewable' : 'title-3 text_renewable'">
            {{ "impactCard.renewable.line4" | translate }}
          </div>
        </div>
      </div>
      <div class="d-flex gap-4 justify-content-center" [ngClass]="{ 'flex-column':windowWidth < 992 }">
        <button
          role="button"
          mat-raised-button
          [disabled]="shareClicked"
          color="yellow"
          (click)="download(4)"
        >
      <img class="download-icon mx-1" src="assets/images/icons/arrow-down.svg" alt="">
      {{
        "yourImpact.downloadImpactCard" | translate
      }}
      </button>
        <button
          role="button"
          mat-stroked-button
          [disabled]="downloadClicked"
          color="green"
          (click)="downloadAll()"
        >
        {{
          "yourImpact.downloadAllImpactCards" | translate
        }}
        </button>
      </div>
    </div>
  </carousel>
</div>
