<div class="sidebar-wrapper" [ngClass]="{'reteclima': reteclima, 'adnoc': adnoc, 'aldar': aldar, 'tgi': tgi, 'whitelabel': whitelabel}">
  <div *ngIf="reteclima" class="d-flex flex-column align-items-start justify-content-evenly py-3 px-4">
    <img src="/assets/images/icons/reteclima_logo.svg">
    <hr class="w-100">
    <span [ngStyle]="{'font-size': '10px'}">{{ 'sidebar.reteclima.inPartnership' | translate }}</span>
  </div>
  <div class="p-3" *ngIf="adnoc">
    <a href="https://adnocdrilling.ae/" target="_blank"><img class="sidebar-logo" src="/assets/images/icons/adnoc_logo.svg" alt="ADNOC"></a>
  </div>
  <div class="pt-3 pb-0 px-4 d-flex justify-content-end" *ngIf="aldar">
    <a href="https://www.aldar.com/" target="_blank"><img class="sidebar-logo" src="/assets/images/icons/aldar_logo.svg" alt="ALDAR"></a>
  </div>
  <div class="pt-3 pb-0 px-4 d-flex justify-content-center" *ngIf="tgi">
    <a href="http://www.prameyaitalia.it/" class="w-100" target="_blank"><img class="sidebar-logo w-100" src="/assets/images/tgi/prameya_logo_new.png" alt="PRAMEYA"></a>
  </div>
  <div class="pt-3 d-flex justify-content-center gfp-logo" *ngIf="whitelabel && whitelabelData.logo">
    <a><img class="sidebar-logo" [src]="whitelabelData.logo?.url" alt="Logo"></a>
  </div>
  <div class="pt-3 d-flex justify-content-center gfp-logo" *ngIf="!publicView && !adnoc && !aldar && !tgi && (!whitelabel || (whitelabel && !whitelabelData.logo))">
    <a href="/"><img class="sidebar-logo" src="/assets/images/gfp-logo-sidebar.png" alt="GFP"></a>
  </div>
  <div class="">
    <mat-nav-list>
      <app-menu-item *ngFor="let item of menuItemsApp" [item]="item"
                      (toggleItemSidenav)="toggleSidenav.emit()"></app-menu-item>
      <mat-divider class="my-3"></mat-divider>
      <ng-container *ngIf="publicView">
        <app-menu-item *ngFor="let item of menuItemsGen" [item]="item" (toggleItemSidenav)="toggleSidenav.emit()" [menuItemsGen]="menuItemsGen">
        </app-menu-item>
      </ng-container>
    </mat-nav-list>
    <div>
      <div class="d-flex flex-column justify-content-center gap-3 mx-3" *ngIf="windowWidth <= 768">
        <ng-container *ngIf="username || authenticated, else noLog">
          <button mat-stroked-button color="lime" class="w-100" (click)="toggleSidenav.emit()" [routerLink]="routingTypes.ADMIN" *ngIf="publicView"><svg-icon src="../../../assets/images/icons/home.svg"></svg-icon> {{username}}</button>
          <button mat-raised-button color="yellow" [matMenuTriggerFor]="menu">
            <svg-icon src="../../../assets/images/icons/headset.svg"></svg-icon>
              {{'landingPublic.menu.talkToExpert'|translate}}        
          </button>
          <mat-menu #menu="matMenu" class="mt-2">
            <a mat-menu-item *ngIf="lang!='jp'" href="tel:+393756578850" class="chivo">
              <svg-icon src="../../../assets/images/icons/phone.svg"></svg-icon>
              +39 375 657 8850
            </a>
            <button mat-menu-item (click)="bookADemo('HP_topnav')" class="chivo">
              <svg-icon src="../../../assets/images/icons/calendar.svg"></svg-icon>
              {{'landingPublic.menu.bookADemo' | translate}}
            </button>
          </mat-menu>
        </ng-container>
        <ng-template #noLog>
          <button mat-stroked-button color="lime" class="w-100" (click)="toggleSidenav.emit()" [routerLink]="routingTypes.LOGIN">
            <svg-icon src="../../../assets/images/icons/thunder.svg"></svg-icon>
            {{'landingPublic.menu.access' | translate}}
          </button>
          <button mat-raised-button color="yellow" [matMenuTriggerFor]="menu">
            <svg-icon src="../../../assets/images/icons/headset.svg"></svg-icon>
              {{'landingPublic.menu.talkToExpert'|translate}}        
          </button>
          <mat-menu #menu="matMenu" class="mt-2">
            <a mat-menu-item *ngIf="lang!='jp'" href="tel:+393756578850" class="chivo">
              <svg-icon src="../../../assets/images/icons/phone.svg"></svg-icon>
              +39 375 657 8850
            </a>
            <button mat-menu-item (click)="bookADemo('HP_topnav')" class="chivo">
              <svg-icon src="../../../assets/images/icons/calendar.svg"></svg-icon>
              {{'landingPublic.menu.bookADemo' | translate}}
            </button>
          </mat-menu>
        </ng-template>
      </div>
      <div class="mt-2 mt-md-0 sidebar-footer">
        <div class="configbar d-flex justify-content-evenly mt-3 mt-md-0 mx-3 d-none d-md-flex" (click)="openLocaleModal()">
          <div class="d-flex align-items-center pe-2">
            <img src="assets/images/{{lang}}.svg" class="flag" />
          </div>

          <div class="d-flex align-items-center">
            <fa-icon [icon]="['far', 'globe']"></fa-icon>
            <span class="title-4 ms-2">{{selectedRegion}}</span>
          </div>

          <div class="d-flex align-items-center ps-2">
            <span class="title-4">{{selectedCurrency}}</span>
          </div>
        </div>
        <hr>
        <div class="d-flex justify-content-center mt-3 mx-3 socials" *ngIf="!adnoc && !reteclima && !aldar && (!whitelabel || whitelabelData.customSocial == false)">
          <a href="https://www.facebook.com/greenfutureproject/" target="_blank">
            <svg-icon src="assets/images/svg/sidebar/facebook.svg"></svg-icon>
          </a>
          <a href="https://www.instagram.com/green.futureproject/" target="_blank">
            <svg-icon src="assets/images/svg/sidebar/instagram.svg"></svg-icon>
          </a>
          <a href="https://www.linkedin.com/company/green-future-project" target="_blank">
            <svg-icon src="assets/images/svg/sidebar/linkedin.svg"></svg-icon>
          </a>
          <a href="https://www.youtube.com/channel/UCoV71yXXEx7coGwGHa8bPWA" target="_blank">
            <svg-icon src="assets/images/svg/sidebar/youtube.svg"></svg-icon>
          </a>
        </div>
        <div class="d-flex justify-content-between mt-3 mx-3 socials" *ngIf="aldar">
          <a href="https://www.facebook.com/aldar/" target="_blank">
            <svg-icon src="assets/images/svg/sidebar/facebook.svg"></svg-icon>
          </a>
          <a href="https://www.instagram.com/aldar/" target="_blank">
            <svg-icon src="assets/images/svg/sidebar/instagram.svg"></svg-icon>
          </a>
          <a href="https://www.linkedin.com/company/aldar_properties/" target="_blank">
            <svg-icon src="assets/images/svg/sidebar/linkedin.svg"></svg-icon>
          </a>
          <a href="https://www.youtube.com/channel/UCZpHpqgtarVPERxWuKa1a8A" target="_blank">
            <svg-icon src="assets/images/svg/sidebar/youtube.svg"></svg-icon>
          </a>
        </div>
        <div class="d-flex justify-content-between mt-3 mx-3 socials" *ngIf="adnoc">
          <a href="https://www.facebook.com/ADNOCGroupOfficial/" target="_blank">
            <svg-icon src="assets/images/svg/sidebar/facebook.svg"></svg-icon>
          </a>
          <a href="https://www.instagram.com/adnocgroup/" target="_blank">
            <svg-icon src="assets/images/svg/sidebar/instagram.svg"></svg-icon>
          </a>
          <a href="https://ae.linkedin.com/company/adnocgroup" target="_blank">
            <svg-icon src="assets/images/svg/sidebar/linkedin.svg"></svg-icon>
          </a>
          <a href="https://www.youtube.com/channel/UCUT3t_cLWisExZKLw6ex_3A" target="_blank">
            <svg-icon src="assets/images/svg/sidebar/youtube.svg"></svg-icon>
          </a>
        </div>
        <div class="d-flex justify-content-between mt-3 mx-3 socials" *ngIf="reteclima">
          <a href="https://www.facebook.com/reteclima/" target="_blank">
            <svg-icon src="assets/images/svg/sidebar/facebook.svg"></svg-icon>
          </a>
          <a href="https://www.instagram.com/reteclima/" target="_blank">
            <svg-icon src="assets/images/svg/sidebar/instagram.svg"></svg-icon>
          </a>
          <a href="https://www.linkedin.com/company/rete-clima" target="_blank">
            <svg-icon src="assets/images/svg/sidebar/linkedin.svg"></svg-icon>
          </a>
          <a href="https://www.youtube.com/@ReteClima" target="_blank">
            <svg-icon src="assets/images/svg/sidebar/youtube.svg"></svg-icon>
          </a>
        </div>
        <div class="d-flex justify-content-between mt-3 mx-3 socials" *ngIf="whitelabel && whitelabelData.customSocial">
          <ng-container *ngFor="let social of whitelabelData.socials">
            <a *ngIf="social.enabled && social.url" [href]="social.url" target="_blank">
              <svg-icon src="assets/images/svg/sidebar/{{social.platform.toLowerCase()}}.svg"></svg-icon>
            </a>
          </ng-container>
        </div>
        <div *ngIf="tgi">
          <hr>
          <div class="mx-3 my-3 d-flex flex-column gap-3 align-items-start">
            <p class="mb-0">{{'about.partners' | translate}}</p>
            <img class="footer-prameya-logo" src="/assets/images/tgi/prameya_logo.png">
            <img class="footer-tgi-logo" src="/assets/images/svg/tgi_logo.svg">
          </div>
        </div>
        <hr>
        <div class="mx-3 mb-3 mt-2 d-flex flex-column gap-3 align-items-stretch">
          <p class="mt-3 mb-1 mat-body-1 fw-bold text-center" *ngIf="!adnoc && !reteclima && !aldar && !tgi && !whitelabel">{{'footerPublic.description'|translate}}</p>
          <p class="mb-0" *ngIf="adnoc || aldar || whitelabel">{{'sidebar.poweredBy' | translate}}</p>
          <p class="mb-0" *ngIf="tgi">{{'sidebar.tgi.poweredBy' | translate}}</p>
          <div class="d-flex" *ngIf="adnoc || aldar || tgi || whitelabel">
            <a routerLink="/"><img class="footer-gfp-logo" src="/assets/images/gfp-logo-sidebar.png" alt="GFP"></a>
          </div>
          <p class="mt-1 mb-4 rights" *ngIf="!reteclima" [ngClass]="{'text-center' : !adnoc && !aldar}">{{'footer.rights'|translate: {value:year} }}</p>
          <div class="mt-1 mb-4 reteclima-info" *ngIf="reteclima">
            <h4>
              {{ 'sidebar.reteclima.footerTitle' | translate }}
            </h4>
            <p>
              {{ 'sidebar.reteclima.footerSubtitle' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
