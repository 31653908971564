<div class="px-2" id="giftCartPopup" (click)="$event.stopPropagation()">
  <div class="close">
    <button mat-icon-button color="transparent" (click)="closed.emit(true)">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </div>
  <div *ngIf="items?.length>0; else noItems">
    <div class="title my-3 ms-2">
      <svg-icon src="assets/images/svg/tick_fill.svg"></svg-icon>
      <span class="ms-2 mat-body-2">{{'gift.added'|translate}}</span>
    </div>
    <div class="singleCard">
      <div class="container">
        <div class="row gy-3">
          <ng-container *ngFor="let item of items">
            <div class="col-3">
              <img [src]="item.gift.image.url" class="w-100"/>
            </div>
            <div class="col-9">
              <div>
                <div class="d-flex justify-content-between">
                  <div>
                    <ng-container *ngFor="let p of cartService.getProjectName(item, cartTypes.GIFT); let first = first">
                      <span class="mat-body-1"><span *ngIf="!first">, </span>{{p}}</span>
                    </ng-container>
                  </div>
                  <div>
                    <div class="col-3">
                      <span
                        class="mat-body-1 fw-bold">{{'gift.€'|translate : {value: item?.gift.giftCardPack.giftCardValue | number: '1.2-2'} }}</span>
                    </div>
                  </div>
                </div>
                <div class="my-2">
                  <!--<p class="mat-body-1 fw-bold mb-2">{{'gift.gift' | translate: {value: item.unit[0], slug: item.unitSlug} }}</p>-->
                  <span class="mat-body-1 fw-bold">{{'gift.giftN' | translate}} </span>
                  <ng-container *ngFor="let p of cartService.getProjectAmount(item, cartTypes.GIFT); let first = first">
                    <span class="mat-body-1 fw-bold"><span *ngIf="!first">, </span>{{p}}</span>
                  </ng-container>
                  <span class="mat-body-1 fw-bold"> {{cartService.getUnitCard(item, cartTypes.GIFT)}}</span>
                </div>
                <app-chip-label [color]="cartService.getColorByType(item, cartTypes.GIFT)"
                                [text]="cartService.getProjectTypeName(item, cartTypes.GIFT)"></app-chip-label>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="actions my-4">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <button class="w-100 pe-0" mat-stroked-button
                    (click)="goToCart()">{{'gift.viewCart' | translate : {value: items?.length} }}</button>
          </div>
          <div class="col-6">
            <button class="w-100" mat-raised-button (click)="goToCheckOut()"
                    color="yellow">{{'gift.checkOut' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #noItems>
    <div class="mt-3 pe-5">
      <span class="mat-subheading-1">{{'gift.noItems' | translate}}</span>
    </div>
  </ng-template>
</div>
