export const RoutingTypes = {
  HOME_PUBLIC: '/',
  HOME_ADMIN: '/admin',
  WELCOME: '/welcome',
  SUCCESS: '/welcomeOnBoard',

  ABOUT: '/about',
  RESTORATION: 'projects/type/restoration',
  PRESERVATION: 'projects/type/preservation',
  RENEWABLE: 'projects/type/renewable_energy',
  MARINE: 'projects/type/marine_restoration',
  WASTE_REMOVAL: 'projects/type/waste_removal',

  PROJECTS_PUBLIC: '/projects',
  PROJECTS_ADMIN: 'admin/projects',

  SUBSCRIPTIONS: '/subscriptions',
  ACCOUNT_BILLING: '/admin/users/accountBilling',
  ADMIN_SUBSCRIPTIONS: '/admin/users/subscriptions',
  SINGLE_PAYMENTS: '/admin/users/singlePayments',
  GIFT_CARDS: '/admin/users/giftCards',
  SETTINGS: '/admin/users/settings',
  PRIVACY_POLICY: '/admin/users/privacyPolicy',
  LOGIN: '/login',
  REGISTER: '/register',
  SINGLE_DONATIONS: 'singleDonations',
  ADMIN: '/admin',
  DASHBOARD: '/admin/dashboard',
  MAIN_SUBSCRIPTIONS: 'subscriptions/main',
  COMPANY_PUBLIC: '/company',
  GIFT: 'gift',
  CART: 'gift/cart',
  CHECK_OUT: 'gift/checkOut',
  API: 'api',
  BUSINESS_SUBSCRIPTION: 'climatePositiveSubscription',
  INDIVIDUAL_SUBSCRIPTION: 'monthlyClimateSubscription',
  BUSINESS_SUB: 'subscriptions/business/',
  BUSINESS_CHECKOUT: 'subscriptions/business/:id/checkout',
  LEADER_CHECKOUT: 'subscriptions/business/climateLeader/checkout',
  PIONEER_CHECKOUT: 'subscriptions/business/climatePioneer/checkout',
  INDIVIDUAL_CHECKOUT: 'subscriptions/individual/checkout/:id',
  INDIVIDUAL_SUBSCRIPTION_CHECKOUT: 'subscriptions/individual/checkout',
  TERMS_AND_CONDITIONS: 'termsConditions',
  PRIVACY_POLICY_PUBLIC: 'privacyPolicy',
  COOKIE_POLICY: 'cookiePolicy',
  PARTNERS: 'climate-solutions-partners',
  DECARBONISATION_TECH_ALLIANCE: '/decarbonisation_tech_alliance',
  STRATEGIC_PARTNERS: '/strategic-partners',
  STRATEGIC_PARTNERS_IT: 'partner-strategici',
  FAQ: 'faq',
  CONTACT: 'faq',
  BUSINESS_API_INTEGRATION: 'business/apiIntegration',
  BUSINESS_WHY_JOIN: 'business/whyJoin',
  ECOMMERCE: 'api',
  NFT: 'nft',
  CARBON_CREDITS: 'carbon-offset-biodiversity-token',
  CARBON_CREDITS_IT: 'crediti-di-carbonio-token-biodiversita',
  OFFSET: 'checkout',
  TRAINING: '/esg-corporate-training',
  TRAINING_IT: '/formazione-aziendale-esg',
  NATIVA: 'nativa',
  NATIVA_WELCOME: 'nativa/welcome',
  ENERGY:'carbon_footprint_management',
  ENERGY_IT:'gestione_impronta_carbonio',
  ESG:'environmental-sustainability-advisory',
  ESG_IT:'consulenza-sostenibilita-ambientale',
  SOLUTIONS: 'carbon_neutral_solutions',
  SOLUTIONS_IT: 'soluzioni_carbon_neutral',
  SUSTAINABILITY:'/sustainability_plugin',
  SUSTAINABILITY_IT:'/carbon_offset_API',
  SUSTAINABILITY_JP:'/sustainability_plugin',
  SUSTAINABILITY_CHECKOUT:'/sustainability_plugin/checkout',
  SUSTAINABILITY_DASHBOARD: '/admin/sustainability',
  SUSTAINABILITY_CONTRIBUTION: '/admin/sustainability/contribution',
  SUSTAINABILITY_PROJECT: '/admin/sustainability/project',
  SUSTAINABILITY_DOCUMENTATION: '/admin/sustainability/documentation',
  SUSTAINABILITY_ASSETS: '/admin/sustainability/assets',
  SUSTAINABILITY_REPORTING: 'sustainability-reporting',
  SUSTAINABILITY_REPORTING_IT: 'piano-bilancio-sostenibilita',
  WHITELABEL_ADMIN: '/admin/whitelabel/',
  WHITELABEL_GENERAL: '/admin/whitelabel/general',
  WHITELABEL_LOOK_FEEL: '/admin/whitelabel/lookAndFeel',
  WHITELABEL_PROJECTS: '/admin/whitelabel/projectMetrics',
  WHITELABEL_CUSTOMIZATION: '/admin/whitelabel/furtherCustomization',
  WHITELABEL: '/whitelabel_environmental_communication',
  WHITELABEL_IT: '/comunicazione_sostenibilita_whitelabel',
  ENGAGEMENT: '/stakeholder-engagement',
  ENGAGEMENT_IT: '/coinvolgimento-stakeholder',
  ESG_PLATFORM: '/employee-engagement-platform',
  ESG_PLATFORM_IT: '/coinvolgimento-dipendenti'
};
