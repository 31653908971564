<div id="expertModal" class="text-start py-2">
    <div [ngClass]="modalWidth">
        <div mat-dialog-title class="d-flex justify-content-between">
            <div class="modalTitle chivo lg bold mt-5 pt-2">
                {{'landingPublic.menu.talkToExpert'|translate}}
            </div>
            <fa-icon [icon]="faClose" mat-dialog-close (click)="close()"></fa-icon>
        </div>
        <div mat-dialog-content class="my-4">
            <div class="container px-0">
                <div class="row">
                    <div class="col-12">
                        <a mat-menu-item class="chivo" *ngIf="lang!='jp'" href="tel:+393756578850">
                            <svg-icon src="../../../assets/images/icons/phone.svg"></svg-icon>
                            <span class="mx-3">+39 375 657 8850</span>
                          </a>
                    </div>
                    <div class="col-12">
                        <button mat-menu-item class="chivo" (click)="createBookADemoLink()">
                            <svg-icon src="../../../assets/images/icons/calendar.svg"></svg-icon>
                            <span class="mx-3">{{'landingPublic.menu.bookADemo' | translate}}</span>
                          </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
