import {CUSTOM_ELEMENTS_SCHEMA, forwardRef, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {ToastrModule} from 'ngx-toastr';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MomentPipe} from '../../components/pipes/moment.pipe';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {SharedService} from '../../_services/shared.service';
import {FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {AngularSvgIconModule, SvgIconRegistryService} from 'angular-svg-icon';
import {RatingComponent} from '../../components/rating/rating.component';
import {ShareFriendsComponent} from '../../components/share-friends/share-friends.component';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import {ProgressComponent} from '../../components/progress/progress.component';
import {SocialButtonsComponent} from '../../components/social-buttons/social-buttons.component';
import {DividerTextComponent} from '../../components/divider-text/divider-text.component';
import {FooterAuthenticationComponent} from '../../components/footer-authentication/footer-authentication.component';
import {BillingAddressComponent} from '../../components/billing-address/billing-address.component';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatInputComponent} from '../../components/mat-input/mat-input.component';
import {MatStepperModule} from '@angular/material/stepper';
import {YourPaymentMethodComponent} from '../../components/your-payment-method/your-payment-method.component';
import {NgxStripeModule} from 'ngx-stripe';
import {FlipCardComponent} from '../../pages/public/components/flip-card/flip-card.component';
import {MapComponent} from '../../components/map/map.component';
import {GoogleMapsModule} from '@angular/google-maps';
import {OrderSummaryComponent} from '../../components/order-summary/order-summary.component';
import {SummaryItemComponent} from '../../components/summary-item/summary-item.component';
import {ChipLabelComponent} from '../../components/chip-label/chip-label.component';
import {ProjectTypeResolver} from '../../_services/resolver/project-type.resolver';
import {ShareButtonsModule} from 'ngx-sharebuttons/buttons';
import {HighlightDivComponent} from '../../pages/public/components/highlight-div/highlight-div.component';
import {GiftPersonalizedTextComponent} from '../../pages/public/pages/gift-public/components/gift-personalized-text/gift-personalized-text.component';
import {GiftForComponent} from '../../pages/public/pages/gift-public/components/gift-for/gift-for.component';
import { GiftOccasionComponent } from '../../pages/public/pages/gift-public/components/gift-occasion/gift-occasion.component';
import { AchievementsDataComponent } from '../../pages/public/components/achievements-data/achievements-data.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {BannerComponent} from '../../pages/public/components/banner/banner.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import {MailInputComponent} from '../../components/mail-input/mail-input.component';
import {UploadLogoComponent} from "../../components/upload-logo/upload-logo.component";
import {InfoComponent} from '../../components/info/info.component';
import {HotspotPopupComponent} from '../../components/hotspot-popup/hotspot-popup.component';
import {RealDataComponent} from '../../pages/admin/components/real-data/real-data.component';
import {NgxFileDropModule} from "ngx-file-drop";
import { GlobeComponent } from '../../components/globe/globe.component';
import { EstimateClimatePioneerComponent } from '../../pages/public/pages/subscriptions-public/components/estimate-climate-pioneer/estimate-climate-pioneer.component';
import {SummaryUnitComponent} from '../../pages/public/pages/subscriptions-public/components/summary-unit/summary-unit.component';
import { PriceBreakdownComponent } from 'src/app/components/price-breakdown/price-breakdown.component';
import { WidgetTooltipComponent } from 'src/app/pages/public/components/widget-tooltip/widget-tooltip.component';
import { IvyCarouselModule } from 'angular-responsive-carousel2';
import { ColorPickerComponent } from 'src/app/components/color-picker/color-picker.component';
import { NgxColorsModule } from 'ngx-colors';
import { FileInputComponent } from 'src/app/components/file-input/file-input.component';
import { FileFormFieldComponent } from 'src/app/components/file-form-field/file-form-field.component';
import { WidgetTooltipDisclaimerComponent } from 'src/app/pages/public/components/widget-tooltip-disclaimer/widget-tooltip-disclaimer.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomTooltipDirective } from './custom-tooltip/custom-tooltip.directive';
import { CustomTooltipComponent } from './custom-tooltip/custom-tooltip.component';

@NgModule({
  declarations: [
    MomentPipe,
    RatingComponent,
    ShareFriendsComponent,
    ProgressComponent,
    SocialButtonsComponent,
    DividerTextComponent,
    FooterAuthenticationComponent,
    BillingAddressComponent,
    MatInputComponent,
    YourPaymentMethodComponent,
    FlipCardComponent,
    MapComponent,
    OrderSummaryComponent,
    SummaryItemComponent,
    ChipLabelComponent,
    HighlightDivComponent,
    GiftPersonalizedTextComponent,
    GiftForComponent,
    GiftOccasionComponent,
    BannerComponent,
    MailInputComponent,
    UploadLogoComponent,
    AchievementsDataComponent,
    GlobeComponent,
    RealDataComponent,
    InfoComponent,
    HotspotPopupComponent,
    EstimateClimatePioneerComponent,
    SummaryUnitComponent,
    PriceBreakdownComponent,
    WidgetTooltipComponent,
    WidgetTooltipDisclaimerComponent,
    FileInputComponent,
    FileFormFieldComponent,
    ColorPickerComponent,
    CustomTooltipComponent,
    CustomTooltipDirective
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatFormFieldModule,
    FontAwesomeModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    ToastrModule,
    MatCheckboxModule,
    MatListModule,
    MatSelectModule,
    MatCardModule,
    MatChipsModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    AngularSvgIconModule,
    FormsModule,
    MatRadioModule,
    MatStepperModule,
    NgxStripeModule,
    GoogleMapsModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatExpansionModule,
    NgxFileDropModule,
    IvyCarouselModule,
    NgxColorsModule,
    MatTooltipModule
  ],
  exports: [
    CommonModule,
    TranslateModule,
    MatFormFieldModule,
    FontAwesomeModule,
    MatRadioModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    ToastrModule,
    MatCheckboxModule,
    MomentPipe,
    MatListModule,
    MatSelectModule,
    MatCardModule,
    MatChipsModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    MatDialogModule,
    AngularSvgIconModule,
    RatingComponent,
    ProgressComponent,
    SocialButtonsComponent,
    DividerTextComponent,
    FooterAuthenticationComponent,
    BillingAddressComponent,
    MatInputComponent,
    YourPaymentMethodComponent,
    MatStepperModule,
    NgxStripeModule,
    FlipCardComponent,
    MapComponent,
    OrderSummaryComponent,
    SummaryItemComponent,
    ChipLabelComponent,
    ShareButtonsModule,
    HighlightDivComponent,
    GiftPersonalizedTextComponent,
    GiftForComponent,
    GiftOccasionComponent,
    MatDatepickerModule,
    BannerComponent,
    MatButtonToggleModule,
    MatExpansionModule,
    MailInputComponent,
    UploadLogoComponent,
    AchievementsDataComponent,
    GlobeComponent,
    RealDataComponent,
    InfoComponent,
    HotspotPopupComponent,
    EstimateClimatePioneerComponent,
    SummaryUnitComponent,
    PriceBreakdownComponent,
    FileInputComponent,
    FileFormFieldComponent,
    ColorPickerComponent,
    CustomTooltipDirective,
    WidgetTooltipDisclaimerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    SharedService,
    SvgIconRegistryService,
    ProjectTypeResolver,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatInputComponent),
      multi: true
    },
    { provide: MAT_DIALOG_DATA, useValue: {} }
  ]
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fal);
  }
}
