<div class="infoModal">
  <div [ngClass]="modalWidth">
    <div mat-dialog-title>
      <!--      <div class="text-end mb-2">
        <button mat-icon-button [mat-dialog-close]="false">
          <mat-icon class="close-icon">close</mat-icon>
        </button>
      </div>
      <p class="text-center mat-body-2 pb-3">{{data.title}}</p>-->
      <div class="position-relative">
        <div class="text-end mb-2 closeButton">
          <button mat-icon-button [mat-dialog-close]="false">
            <mat-icon class="close-icon">close</mat-icon>
          </button>
        </div>
        <p class="text-center mat-body-2 title">{{ data.title }}</p>
      </div>
    </div>
    <div mat-dialog-content class="my-2">
      <div class="container">
        <div class="row">
          <div class="col">
            <p class="mat-caption" style="white-space: pre-line">
              {{ data.firstText }}
            </p>
          </div>
          <div *ngIf="modalWidth !== modalTypes.small" class="col-md-6 col-12">
            <div class="mt-3 mt-md-0">
              <p class="mat-caption">{{ data.secondText }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div
        *ngIf="showButton"
        mat-dialog-actions
        class="my-2"
        [align]="modalWidth === modalTypes.small ? 'center' : 'end'"
      >
        <button mat-raised-button color="warn" [mat-dialog-close]="true">
          {{ data.buttonText }}
        </button>
      </div>
      <div
        *ngIf="data.buttonCancelLabel"
        mat-dialog-actions
        class="my-2"
        [align]="modalWidth === modalTypes.small ? 'center' : 'end'"
      >
        <button mat-raised-button [mat-dialog-close]="false">
          {{ data.buttonCancelLabel }}
        </button>
      </div>
    </div>
  </div>
</div>
