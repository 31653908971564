<div class="center" id="uploadLogo2">
    <ngx-file-drop dropZoneLabel="" (onFileDrop)="dropped($event)"
                   (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector" style="height: unset;">
        <ng-container *ngIf="imageUrl, else noPhoto">
          <div class="imageLoaded">
            <img [src]="imageUrl" alt="" (click)="openFileSelector()"/>
          </div>
        </ng-container>
        <ng-template #noPhoto>
          <button mat-icon-button color="transparent" (click)="openFileSelector()" class="upload-btn">
            <mat-icon>add_a_photo</mat-icon>
          </button>
        </ng-template>
      </ng-template>
    </ngx-file-drop>
</div>