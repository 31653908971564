<div class="hotspotPopUp p-1">
  <div class="p-1 w-100">
    <!-- <div class="project p-3 w-100" [ngStyle]="getBackground(item.project)"> -->
    <div class="project p-0" [style]="imageProject">
      <div class="p-3">
        <!-- [ngClass]="setBackgroundByProjectType(madagascar)" -->
        <div class="{{backgroundType}} position-absolute start-0 py-1 ms-3" style="border-radius: 20px; word-wrap: break-word;">
          <span class="px-2 mat-caption bold">
            <!-- {{item.project.type.name}} -->
            {{typeProject}}
          </span>
        </div>
      </div>
      <div class="mt-auto mb-0 w-100 p-3">
        <div class="w-100 text-start pb-1">
          <img src="/assets/images/svg/location_dot.svg" width="12px" class="me-2 text-white">
          <span class="tiny-body text-uppercase text-white">
            {{projectCountry}}
          </span>
        </div>
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="text-white text-start title-3 bold pe-2 w-75 overflow-hidden" style="word-wrap: break-word;">
            {{nameProject}}
          </div>
          <div class="square" (click)="openProject(slugProject)"><mat-icon>keyboard_arrow_right</mat-icon></div>
        </div>
      </div>
        <button mat-raised-button (click)="openLive(slugProject)" class="d-flex justify-content-center align-items-center mb-0 w-100" *ngIf="!isDashboardHome">
          <div class="d-flex justify-content-center align-items-center mb-0 w-100">
            <div class="antena">
            </div>
            <span class="text-white">{{'landingPublic.live' | translate}}</span>
          </div>
        </button>
    </div>
  </div>
</div>
