<div class="welcome-wrapper">
  <div class="languages">
    <mat-button-toggle-group class="langToggle">
      <mat-button-toggle id="en" (change)="setLang('en')" class="d-flex justify-content-center align-items-center">
        <svg-icon src="assets/images/en.svg" class="pe-md-2" style="border-radius: 4px;"></svg-icon>
        <span class="bold d-none d-md-inline-block">{{'locale.en' | translate}}</span>
      </mat-button-toggle>
      <mat-button-toggle id="it" (change)="setLang('it')" class="d-flex justify-content-center">
        <svg-icon src="assets/images/it.svg" class="pe-md-2" style="border-radius: 4px;"></svg-icon>
        <span class="bold d-none d-md-inline-block">{{'locale.it' | translate}}</span>
      </mat-button-toggle>
      <mat-button-toggle id="jp" (change)="setLang('jp')" class="d-flex justify-content-center">
        <svg-icon src="assets/images/jp.svg" class="pe-md-2" style="border-radius: 4px;"></svg-icon>
        <span class="bold d-none d-md-inline-block">{{'locale.jp' | translate}}</span>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="d-flex flex-column justify-content-start justify-content-md-center justify-content-lg-center mt-3">

    <div class="upper mb-4">
      <img src="/assets/images/svg/logo_full.svg">
      <div class="heading1 mt-4 mt-md-5 mt-lg-5">
        <div>{{'welcome.welcome' | translate}}</div>
        <div class="d-none d-md-block mt-3">{{'welcome.weAre' | translate}}</div>
        <div class="d-md-none mt-3">{{'welcome.weAreGFP' | translate}}</div>
      </div>
      <div class="heading2 mt-4">
        {{'welcome.the' | translate}}
      </div>
      <div class="heading3 mt-4">
        {{'welcome.weConnect' | translate}}
      </div>

    </div>

    <div class="lower">
      <div class="heading3 pe-0">
        {{'welcome.are' | translate}}
      </div>
      <button mat-raised-button color="yellow" class="mt-3 me-4" (click)="saveAs(ClientType.business)">{{'welcome.business'|translate}}</button>
      <button mat-raised-button color="yellow" class="mt-3" (click)="saveAs(ClientType.individual)">{{'welcome.individual'|translate}}</button>
    </div>
  </div>

  <div class="logo">
    <img src="/assets/images/press/B-Corp-Logo-White.png" alt="B Corp" class="me-2"/>
    <img src="/assets/images/press/B-Best-World.png">
  </div>

  <div class="globe-wrapper">
    <app-globe [page]="'welcome'"></app-globe>
  </div>
</div>

