import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../pages/_base/base/base.component';

@Component({
  selector: 'app-hotspot-popup',
  templateUrl: './hotspot-popup.component.html',
  styleUrls: ['./hotspot-popup.component.scss'],
})

export class HotspotPopupComponent extends BaseComponent implements OnInit {

  @Input() backgroundType: string;
  @Input() projectCountry: string;
  @Input() slugProject: string;
  @Input() nameProject: string;
  @Input() typeProject: string;
  @Input() imageProject: string;
  @Input() isDashboardHome: boolean | undefined;
  @Input() isAdmin: boolean = false;
  constructor(
) {
    super();
  }

  ngOnInit(): void {
  }

  openProject = async (slug: string): Promise<void> => {
    let adminUrl = this.isAdmin? "admin/" : "";
    await this.router.navigate([adminUrl + 'projects', slug]);
  }

  openLive = async (slug: string): Promise<void> => {
    let adminUrl = this.isAdmin? "admin/" : "";
    this.isDashboardHome ? await this.router.navigate(['projects', slug]) : await this.router.navigate([adminUrl + 'projects', slug, 'live']);
  }

}
