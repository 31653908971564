<div class="d-flex justify-content-center align-items-center flex-row">
  <div class="line">
    <mat-divider></mat-divider>
  </div>
  <div>
    <span class="tiny-body text-white px-2">{{text}}</span>
  </div>
  <div class="line">
    <mat-divider></mat-divider>
  </div>
</div>
