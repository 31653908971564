<div class="item" [ngClass]="cardType">
  <div class="container">
    <div class="front" [style]="retrieveStyleBackgroundPublic(image)">
      <div class="square d-lg-none d-md-none end-0 m-3" [class.individualSquare]="!business"><mat-icon>add</mat-icon></div>
      <div class="cardLayer">
        <div class="p-3">
          <p class="mb-1 small-body">
            {{caption | uppercase}}
          </p>
          <p class="title-3 bold">
            {{name}}
          </p>
        </div>
      </div>
    </div>
    <div class="overlay">
      <div class="back" [style]="retrieveStyleBackgroundPublic(image)">
        <div class="bg-filter"></div>
        <div class="px-4 py-5 d-flex align-items-start flex-column h-100">
          <!-- <p class="mb-1 small-body">
            {{caption | uppercase}}
          </p>
          <p class="title-3 bold">
            {{name}}
          </p> -->
          <p class="mb-4 small-body" [innerHTML]="description">
          </p>
          <div class="d-flex align-items-start flex-column mt-auto pb-4">
            <div class="imgSize">
              <a [href]="linkedin" target="_blank">
                <img src="/assets/images/public/about/linkedin_logo.svg" class="me-2">
              </a>
              <!-- <img src="/assets/images/public/about/insta_logo.svg"> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

