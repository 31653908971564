import {Injectable} from '@angular/core';
import {BaseService} from '../_base/base.service';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WhitelabelService extends BaseService {

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  getProfileByCompanyId = async (id: string, status: string='active') => {
    return await this.http.get(`${environment.authApiUrl}/whitelabel/${id}/${status}`, {
      headers: this.getRequestHeaders(),
    }).toPromise();
  }
  getPublicProfileByCompanyId = async (id: string, status: string='active') => {
    return await this.http.get(`${environment.authApiUrl}/whitelabel/${id}/${status}`, {
      headers: this.getPublicRequestHeaders(),
    }).toPromise();
  }
  enableProfileById = async (id: string, type: string = 'profile', params?: any) => {
    return await this.http.patch(`${environment.authApiUrl}/whitelabel/enable/${type}/${id}`, params, {
      headers: this.getRequestHeaders(),
    }).toPromise();
  }
  updateProfileById = async (id: string, type: string = 'profile', params?: any) => {
    return await this.http.patch(`${environment.authApiUrl}/whitelabel/update/${type}/${id}`, params, {
      headers: this.getRequestHeaders(),
    }).toPromise();
  }
  resetProfileByCompanyId = async (id: string, type: string, params?: any) => {
    return await this.http.patch(`${environment.authApiUrl}/whitelabel/reset/${type}/${id}`, params, {
      headers: this.getRequestHeaders(),
    }).toPromise();
  }
  publishProfileByCompanyId = async (id: string, params?: any) => {
    return await this.http.patch(`${environment.authApiUrl}/whitelabel/publish/${id}`, params, {
      headers: this.getRequestHeaders(),
    }).toPromise();
  }
  getEmployeesByCompanyId = async (id: string) => {
    return await this.http.get(`${environment.authApiUrl}/companies/${id}/employees/`, {
      headers: this.getRequestHeaders(),
    }).toPromise();
  }
}
