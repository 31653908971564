<footer class="footer" id="footer">
  <div class="container-fluid pt-5">
    <div class="row gy-4 justify-content-start">
      <div class="col-12">
        <svg-icon src="assets/images/svg/logo_full.svg"></svg-icon>
      </div>
      <div class="col-lg-3 col-12 d-md-flex flex-column justify-content-between">
        <div>
        <div class="social">
          <p class="mb-4 tiny-body fw-bold">{{'footerPublic.description'|translate}}</p>
          <svg-icon (click)="goToSocial(socialTypes.facebook)" class="me-2"
            src="assets/images/svg/facebook.svg"></svg-icon>
          <svg-icon (click)="goToSocial(socialTypes.instagram)" class="me-2"
            src="assets/images/svg/instagram.svg"></svg-icon>
          <svg-icon (click)="goToSocial(socialTypes.linkedin)" class="me-2"
            src="assets/images/svg/linkedin_footer.svg"></svg-icon>
          <svg-icon (click)="goToSocial(socialTypes.youtube)" src="assets/images/svg/youtube.svg"></svg-icon>
        </div>
        <div class="mt-4">
          <p class="tiny-body bold">
            {{'footerPublic.gfp'|translate}}
            <br>
            {{'footerPublic.vat'|translate}}
          </p>
          <a class="tiny-body" href="mailto:{{'footerPublic.email'|translate}}" class="text-nowrap tiny-body text-start" [innerHtml]="'footerPublic.email'|translate"></a>
        </div>
        </div>
        <div>
        </div>
      </div>
      <div class="col-lg-5 col-12">
        <div class="container ms-0 p-0">
          <div class="row gy-4">
            <div class="col-12 col-lg-4">
              <a class="small-body fw-bold" [innerHtml]="'footerPublic.solutions'|translate"></a>
              <a [routerLink]="lang == 'it'?routingTypes.ENERGY_IT:routingTypes.ENERGY" class="tiny-body" [innerHtml]="'footerPublic.energy'|translate"></a>
              <a [routerLink]="lang == 'it'? routingTypes.CARBON_CREDITS_IT : routingTypes.CARBON_CREDITS" class="tiny-body" [innerHtml]="'footerPublic.offset'|translate"></a>
              <a [routerLink]="lang == 'it'? routingTypes.SUSTAINABILITY_IT : lang == 'jp'? routingTypes.API : routingTypes.SUSTAINABILITY" class="tiny-body" [innerHtml]="'footerPublic.sustainability_plugin'|translate"></a>
              <a [routerLink]="lang == 'it' ? routingTypes.ESG_PLATFORM_IT:routingTypes.ESG_PLATFORM" class="tiny-body" [innerHtml]="'footerPublic.esgEngagementPlatform'|translate"></a>
              <a [routerLink]="lang == 'it'?routingTypes.WHITELABEL_IT:routingTypes.WHITELABEL" class="tiny-body" [innerHtml]="'footerPublic.whitelabel'|translate"></a>
              <a [routerLink]="lang == 'it'?routingTypes.ESG_IT:routingTypes.ESG" class="tiny-body" *ngIf="lang != 'jp'" [innerHtml]="'footerPublic.esg'|translate"></a>
              <a [routerLink]="lang == 'it'?routingTypes.SUSTAINABILITY_REPORTING_IT :routingTypes.SUSTAINABILITY_REPORTING" class="tiny-body" *ngIf="lang != 'jp'" [innerHtml]="'footerPublic.reporting'|translate"></a>
            </div>
            <div class="col-12 col-lg-4">
              <a class="small-body fw-bold" [innerHtml]="'footerPublic.projects'|translate"></a>
              <a [routerLink]="routingTypes.RESTORATION" class="tiny-body" [innerHtml]="'footerPublic.restoration'|translate"></a>
              <a [routerLink]="routingTypes.PRESERVATION" class="tiny-body" [innerHtml]="'footerPublic.preservation'|translate"></a>
              <a [routerLink]="routingTypes.RENEWABLE" class="tiny-body" [innerHtml]="'footerPublic.renewable'|translate"></a>
              <a [routerLink]="routingTypes.MARINE" class="tiny-body" [innerHtml]="'footerPublic.marine'|translate"></a>
              <a [routerLink]="routingTypes.WASTE_REMOVAL" class="tiny-body" [innerHtml]="'footerPublic.wasteRemoval' | translate"></a>
              <div class="mt-4">
                <a class="small-body text-start fw-bold" [innerHtml]="'footerPublic.partnerships'|translate"></a>
                <a [routerLink]="lang == 'it'?routingTypes.STRATEGIC_PARTNERS_IT :routingTypes.STRATEGIC_PARTNERS" class="tiny-body" [innerHtml]="'footerPublic.strategicPartners'|translate"></a>
                <a [routerLink]="routingTypes.PARTNERS" class="tiny-body" [innerHtml]="'footerPublic.climateSolutionsPartners'|translate"></a>
                <a [routerLink]="routingTypes.DECARBONISATION_TECH_ALLIANCE" class="tiny-body" [innerHtml]="'footerPublic.decarbonisationTechAlliance'|translate"></a>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <a class="small-body fw-bold" [innerHtml]="'footerPublic.about'|translate"></a>
              <a [routerLink]="routingTypes.ABOUT" class="tiny-body" [innerHtml]="'footerPublic.aboutGFP'|translate"></a>
              <a [routerLink]="routingTypes.TERMS_AND_CONDITIONS" class="tiny-body" [innerHtml]="'footerPublic.terms'|translate"></a>
              <a [routerLink]="routingTypes.PRIVACY_POLICY_PUBLIC" class="tiny-body" [innerHtml]="'footerPublic.privacy'|translate"></a>
              <a [routerLink]="routingTypes.COOKIE_POLICY" class="tiny-body" [innerHtml]="'footerPublic.cookies'|translate"></a>
              <div class="mt-4">
                <a class="small-body text-start fw-bolder" [innerHtml]="'footerPublic.contact'|translate"></a>
                <a [routerLink]="routingTypes.FAQ" class="tiny-body" [innerHtml]="'footerPublic.faq'|translate"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-12 d-flex flex-column justify-content-between">
        <!-- <div>
          <p class="small-body mb-2" [innerHtml]="'footerPublic.subscribe'|translate"></p>
        </div> -->
        
          <div id="popup2" class="overlay">
            <div class="popup">
              <div class="d-flex flex-row align-items-center">
                <div class="checkIcon text-white">
                  <mat-icon>done</mat-icon>
                </div>
                <span class="ps-3 title-1 text-black" [innerHtml]="'footerPublic.thanks'|translate"></span>
              </div>
              <a class="close" (click)="close()" style="cursor:pointer">&times;</a>
              <div class="py-3 content small-body" [innerHtml]="'footerPublic.confirm'|translate"></div>
            </div>
          </div>
          <div>
            <p class="small-body mb-2" [innerHtml]="'footerPublic.subscribe'|translate"></p>
            <app-mail-input theme="dark" [control]="email" buttonText="{{'footerPublic.subscribeButton'|translate}}"
              label="landingSubscribe.placeholder" autocomplete="off" labelError="authentication.requiredField"
              labelPatternError="authentication.pattern" [required]="false" (buttonClicked)="subscribeUpdate()">
            </app-mail-input>
          </div>
          <div [ngClass]="{'mt-4': windowWidth < 600}">
            <p>{{'footerPublic.readReviews' | translate}}</p>
            <div>
              <svg-icon class="trustpilot" [src]="lang == 'it' ? '../../../assets/images/icons/Trust_Pilot_ita.svg' : '../../../assets/images/icons/Trust_Pilot.svg'" (click)="goToTrustPilot()"></svg-icon>
            </div>
          </div>

        <div class="d-flex justify-content-start align-items-center flex-wrap" [ngClass]="{'mt-5 gap-3': windowWidth < 600}">
          <div class="mx-2">
            <a href="https://www.bcorporation.net/" target="_blank">
              <img *ngIf="windowWidth > 600" [src]="lang == 'en' ? 'assets/images/press/B-Corp-Logo-White.png':'assets/images/press/B-Corp-Logo-White-ita.png'" alt="B Corp" width="120" />
              <img *ngIf="windowWidth < 600" [src]="lang == 'en' ? 'assets/images/press/B-Corp-Logo-White-mobile.png':'assets/images/press/B-Corp-Logo-White-mobile-ita.png'" alt="B Corp" width="120" />
            </a>
          </div>
          <div class="mx-2">
            <a href="https://bcorporation.eu/best-for-the-world" target="_blank">
              <img src="assets/images/press/B-Best-World.png" alt="BWG" width="66" />
            </a>
          </div>
          <div class="mx-2">
            <a href="https://www.rina.org/en" target="_blank">
              <img src="assets/images/press/RINA.png" alt="RINA" width="66" />
            </a>
          </div>
          <div class="p-2 me-1" style="border-radius: 6px; height: 50px;">
            <a href="https://co2alizione.eco/en/home" target="_blank">
              <img src="assets/images/public/partners/coalizione.png" alt="CO2" width="100" />
            </a>
          </div>
        </div>
      </div>
      <div class="col-12">
        <mat-divider class="my-3"></mat-divider>
        <div class="row">
          <div class="col-12 col-lg-8">
            <p class="lato large-body bold my-4">
              {{'footerPublic.ourOffices' | translate}}
            </p>
            <div class="d-flex flex-column flex-md-row justify-content-between gap-3 gap-md-5">
              <div>
                <p class="lato small-body bold">Milano | Headquarter</p>
                <p class="lato tiny-body lighter">Via Vincenzo Monti 25<br> 20123 Milano, Italia</p>
              </div>
              <div>
                <p class="lato small-body bold">Trento</p>
                <p class="lato tiny-body lighter">Via dei Solteri 38<br> 38121 Trento, Italia</p>
              </div>
              <div>
                <p class="lato small-body bold">Abu Dhabi</p>
                <p class="lato tiny-body lighter">Hub71, Al Khatem Tower<br> Abu Dhabi, Emirati Arabi Uniti</p>
              </div>
              <div>
                <p class="lato small-body bold">Tokyo</p>
                <p class="lato tiny-body lighter">Itochu Building 17f, 2-5-1 Kita-Aoyama<br> Minato-ku, Tokyo, Giappone</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 pt-4 pt-lg-5 align-content-center">
          <p>{{'footerPublic.regionalSettings' | translate}}</p>
          <div class="configbar d-flex justify-content-around gap-4 w-100" [ngClass]="{'reteclima': reteclima}" (click)="openLocaleModal()">
            <div class="d-flex align-items-center">
              <img src="assets/images/{{lang}}.svg" class="flag" />
            </div>
            <div class="d-flex align-items-center">
              <img [src]="'/assets/images/svg/globe_light.svg'" height="16px" width="16px">
              <span class="title-4 ms-2">{{selectedRegion}}</span>
            </div>
            <div class="d-flex align-items-center">
              <span class="title-4">{{selectedCurrency}}</span>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <mat-divider class="my-3"></mat-divider>
        <div class="tiny-body bold text-center">
          <span [innerHtml]="'footerPublic.rights'|translate: {value: year}"></span>
        </div>
      </div>
    </div>
  </div>
</footer>
