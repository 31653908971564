import { environment } from "src/environments/environment";

export function loadFontDynamically(fontFamily, fontUrl, format?) {
  // Create a new style element
  if (format === 'file') {
    const style = document.createElement('style');
    style.type = 'text/css';
    style.id = `${fontFamily}_custom-fonts`
    const getFontFileUrl = fontUrl
    // Construct the @font-face rule
    const fontFaceRule = `
      @font-face {
        font-family: '${fontFamily}';
        src: url('${getFontFileUrl}');
        font-display: swap;
      }
    `;

    // Add the @font-face rule to the style element
    style.appendChild(document.createTextNode(fontFaceRule));


    // Append the style element to the head of the document
    document.head.appendChild(style);

    // Optionally, apply the font to body or specific elements
  }else if(format === 'url'){
    const style = document.createElement('style');
    style.type = 'text/css';
    style.id = `${fontFamily}_custom-fonts`

    const getFontFileUrl = fontUrl
    // Construct the @font-face rule
    const fontFaceRule = `
    @import url('${getFontFileUrl}')`

    style.appendChild(document.createTextNode(fontFaceRule));


    // Append the style element to the head of the document
    document.head.appendChild(style);
  }

}