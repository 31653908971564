<div class="wrapper users-layout" [ngClass]="{'reteclima':reteclima, 'adnoc': adnoc, 'aldar': aldar, 'whitelabel': whitelabel}">
  <mat-sidenav-container>
    <mat-sidenav #sidenav [mode]="mode" [opened]="toggleOpen" (openedStart)="hideNav()" (closedStart)="showNav()"
      (window:resize)="onResize($event)">

    </mat-sidenav>
    <mat-sidenav-content [ngClass]="{'reteclima':reteclima, 'adnoc': adnoc, 'aldar': aldar, 'whitelabel': whitelabel}">
      <div class="container-fluid px-0">
        <div class="background" [ngClass]="{'reteclima':reteclima, 'adnoc': adnoc, 'aldar': aldar, 'tgi': tgi, 'whitelabel': whitelabel}"></div>
        <div class="header text-white">
          <ng-container *ngIf="!sustainabilitySettings; else sustainability">
            <span class="headline bold mb-1 text-uppercase" [ngClass]="{'text-black':reteclima}">{{'settings.settings'|translate}}</span>
            <h1 class="chivo xs fw-light" [ngClass]="{'text-black':reteclima}">{{'settings.settingsSubtitle'|translate}}</h1>
            <nav mat-tab-nav-bar>
              <a mat-tab-link *ngFor="let item of navTabItems; index as i" (click)="setActiveItem(i)"
                [routerLink]="item.route" [active]="item.active">{{item.label|translate}}</a>
            </nav>
          </ng-container>
          <ng-template #sustainability>
            <span class="headline bold mb-1 text-uppercase">{{'sidebar.sustainability'|translate}}</span>
            <h1 class="lato sm fw-light">{{'sustainability.dashboard.customize'|translate}}</h1>
            <nav mat-tab-nav-bar>
              <a mat-tab-link *ngFor="let item of sustainabilityTabItems; index as i" (click)="setActiveItem(i)"
                [routerLink]="item.route" [active]="item.active">{{item.label|translate}}</a>
            </nav>
          </ng-template>
        </div>
        <router-outlet style="overflow-y: visible;"></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
