import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import {Response} from '../../_models/api/response';
import { ImagesInterface } from 'src/app/_models/api/images-interface';
import { StorageName } from 'src/app/_models/components/storage-name';
import { ImagesService } from 'src/app/_services/images.service';
import { BaseComponent } from 'src/app/pages/_base/base/base.component';

@Component({
  selector: 'app-file-form-field',
  templateUrl: './file-form-field.component.html',
  styleUrls: ['./file-form-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileFormFieldComponent,
      multi: true
    }
  ]

})
export class FileFormFieldComponent extends BaseComponent implements ControlValueAccessor {

  @Input() label: string;
  @Input() destinationFolder: string;
  @Input() type: string = 'company_logo';
  @Input() slug: string;
  @Output() emitter = new EventEmitter<string>();

  file: any;
  fileControl: FormControl = new FormControl({
    file: null,
    fileEntity: {
      url: null
    }
  })
  fileForm: FormGroup
  disabled: boolean = true;

  constructor(
    private fb: FormBuilder,
    private imageService: ImagesService
  ){
    super()
    this.fileForm = fb.group({
      id: null,
      filename: null,
      url: null,
      type: null
    })

    this.fileControl.valueChanges.subscribe(value => {
      this.onFileChange(value)
    })
  }

  onFileChange(value: {file: any, fileEntity: ImagesInterface}){
    this.fileForm.patchValue({
      id: null,
      filename: value.file.name
    })
    this.file = value.file;
    this.disabled = false;
  }

  async uploadFile() {
    try {
      if (!this.fileForm.get('id').value && !this.disabled) {
        const response: Response<ImagesInterface> = await this.imageService.upload2(
          this.file,
          this.fileForm.getRawValue().filename,
          this.destinationFolder
        );
        if (response.success) {
          this.fileForm.patchValue(response.data)
          this.onChange(this.fileForm.getRawValue());
          this.emitter.emit(response.data.id);
        }
      }
    } catch (e) {
    }
  }

  onChange: (value: any) => void = () => { };
  onTouched: () => void = () => { };


  writeValue(value: ImagesInterface): void {
   this.fileForm.patchValue(value)
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void { }

  setDisabledState?(isDisabled: boolean): void {
    //this.disabled = isDisabled;
  }
}
