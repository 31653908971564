

<form [formGroup]="fileForm" class="file-form-field-input-container" >
    <app-file-input [formControl]="fileControl"></app-file-input>
    <mat-form-field appearance="outline" id="matFormField">
        <input matInput formControlName="filename" [disabled]="true">
    </mat-form-field>
    <button mat-icon-button (click)="uploadFile()" [disabled]="disabled">
        <mat-icon>{{fileForm.get('id').value? 'check' : 'upload'}}</mat-icon>
    </button>
</form>
