import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UsersInterface } from '../../_models/api/users/users-interface';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { StorageName } from '../../_models/components/storage-name';
import { Response } from '../../_models/api/response';
import { BaseComponent } from '../../pages/_base/base/base.component';
import { UsersService } from '../../_services/users/users.service';
import { NavItem } from '../../_models/components/nav-item';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import { PartnershipNames } from 'src/app/_models/components/partnership-names';
import { CompanyProfileInterface } from 'src/app/_models/api/whitelabel/whitelabel-interface';
import { WhitelabelService } from 'src/app/_services/whitelabel/whitelabel.service';
import { loadFontDynamically } from 'src/app/utils/css-utils';

@Component({
  selector: 'app-user-layout',
  templateUrl: './user-layout.component.html',
  styleUrls: ['./user-layout.component.scss']
})
export class UserLayoutComponent extends BaseComponent implements OnInit {
  user: UsersInterface;
  mode: MatDrawerMode = 'side';
  toggleOpen = true;
  @ViewChild('sidenav') sidenav: MatSidenav;
  menuItems: NavItem[];
  menuItemsApp: NavItem[];
  start: boolean = true;
  sustainabilitySettings: boolean = false;
  reteclima = localStorage.getItem(StorageName.partnership) === PartnershipNames.reteclima;
  adnoc = localStorage.getItem(StorageName.partnership) === PartnershipNames.adnoc;
  aldar = localStorage.getItem(StorageName.partnership) === PartnershipNames.aldar;
  tgi = localStorage.getItem(StorageName.partnership) === PartnershipNames.tgi;
  whitelabel = false;
  whitelabelData: CompanyProfileInterface | null = null;
  whitelabelIconsVisible: boolean = true;
  preview: boolean = false;

  navTabItems = [
    {
      label: "settings.tabsGroup.accountAndBillings",
      route: "/admin/users/accountBilling",
      active: false
    },
    {
      label: "settings.tabsGroup.subscription",
      route: "/admin/users/subscriptions",
      active: false
    },
    {
      label: "settings.tabsGroup.singleContributions",
      route: "/admin/users/singlePayments",
      active: false
    },
    {
      label: "settings.tabsGroup.notificationsAndCommunications",
      route: "/admin/users/", //missing route?
      active: false
    },
    {
      label: "settings.tabsGroup.privacyPolicy",
      route: "/admin/users/privacyPolicy",
      active: false
    },
  ]

  sustainabilityTabItems = [
    {
      label: "settings.tabsGroup.accountAndBillings",
      route: "/admin/users/accountBilling",
      active: false
    },
    {
      label: "settings.tabsGroup.subscription",
      route: "/admin/users/subscriptions",
      active: false
    },
    {
      label: "settings.tabsGroup.singleContributions",
      route: "/admin/users/singlePayments",
      active: false
    },
    {
      label: "settings.tabsGroup.notificationsAndCommunications",
      route: "/admin/users/", //missing route?
      active: false
    },
    {
      label: "settings.tabsGroup.privacyPolicy",
      route: "/admin/users/privacyPolicy",
      active: false
    },
  ]

  constructor(
    private usersService: UsersService,
    private profileService: WhitelabelService,
    protected router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getUserInfo().then();
    this.onResize();
    this.navTabItems.map((item) => {
      item.active = this.router.url == item.route;
      return item;
    })
    this.sustainabilitySettings = this.router.url.includes('sustainability');
    this.whitelabel = localStorage.getItem(StorageName.partnership) == PartnershipNames.whitelabel;
  }

  setActiveItem(index) {
    this.navTabItems.map((item, i) => {
      item.active = index == i;
      return item;
    })
  }

  hideNav(): void {
    if (this.start) {
      this.start = false;
    } else {
      const navbar = document.querySelector('.mat-toolbar')
      navbar.classList.add('hidden');
    }
  }

  showNav(): void {
    const navbar = document.querySelector('.mat-toolbar')
    navbar.classList.remove('hidden');
  }

  onResize(event?): any {
    this.mode = window.innerWidth > 768 ? 'side' : 'over';
    if (window.innerWidth > 768) {
      this.sidenav?.open().then(r => {
        this.showNav();
      });
    } else {
      this.sidenav?.close().then(r => { });
    }
  }

  getUserInfo = async () => {
    const userId = localStorage.getItem(StorageName.id);
    try {
      const response: Response<UsersInterface> = await this.usersService.getUserById(userId);
      if (response?.success) {
        this.user = response.data;
      } else {
        this.showErrorResponse(response);
      }
    } catch (e) {
      this.showErrorResponse(e);

    }
  }

  private injectFontsIntoDOM(fontUrls: string[]) {
    const head = document.getElementsByTagName('head')[0];
    fontUrls.forEach((fontUrl) => {
      const link = document.createElement('link');
      link.href = fontUrl;
      link.rel = 'stylesheet';
      head.appendChild(link);
    });
  }

  getWhitelabelProfile = async () => {
    const companyId = localStorage.getItem(StorageName.companyId);
    try {
      const response: Response<CompanyProfileInterface> = await this.profileService.getProfileByCompanyId(companyId, this.preview ? 'draft': 'active');
      if (response?.success) {
        const { data } = response;

        if (data.status != 'inactive') {
          //localStorage.setItem(StorageName.partnership, PartnershipNames.whitelabel);
          localStorage.setItem(StorageName.profile, JSON.stringify(data));
          this.whitelabel = true;
          this.whitelabelData = data;

          this.injectFontsIntoDOM(data.fonts.map(font => font.font));

          data.fonts.forEach((font) => {
            let fontName = '';
            let fontNameOrigin = ''
            if(font.fontfile){
              try{
                fontNameOrigin = font.fontfile.filename.split('_')[1].split('-')[0]
              }catch(e){
                fontNameOrigin = font.fontfile.filename.split('-')[0]
              }
              let fontFileFormat = font.fontfile.filename.split('.')[1]
              loadFontDynamically(fontNameOrigin, font.fontfile.url, 'file')
            }else if(font.url){
              fontNameOrigin = font.font//Math.round((Math.random() * 100)).toString()
              loadFontDynamically(fontNameOrigin, font.url, 'url')
            }else{
              fontNameOrigin = font.font
            }
            try {
              const url = new URL(fontNameOrigin);
              fontName = url.searchParams.get('family');
            } catch (e) {
              fontName = fontNameOrigin;
            }
            document.documentElement.style.setProperty(`--whitelabel-font-${font.type}`, fontName);
          })

          data.colours.forEach(color => {
            document.documentElement.style.setProperty(`--whitelabel-colour-${color.type}`, color.value);
          });

          data.backgrounds.forEach(bg => {
            if ((bg.mode === 'solid' || bg.mode === 'gradient') && bg.value) {
              document.documentElement.style.setProperty(`--whitelabel-bg-color-${bg.type}`, bg.value);
            } else if (bg.mode === 'image' && bg.image) {
              document.documentElement.style.setProperty(`--whitelabel-bg-image-${bg.type}`, `url(${bg.image.url})`);
            } else if (bg.mode === 'image' && bg.value) {
              document.documentElement.style.setProperty(`--whitelabel-bg-image-${bg.type}`, `url(${bg.value})`);
            }
          })
        }
      } else {
        this.showErrorResponse(response);
      }
    } catch (e) {
      if (e.status != 404) {
        this.showErrorResponse(e);
      }
    }
  }
}
