<mat-form-field [appearance]="appearance" color="white" [ngClass]="prefix || iconPrefix ? 'with-prefix': ''" id="matInputFormField">
  <mat-label *ngIf="label">{{label | translate}}</mat-label>
  <textarea
    [maxLength]="textAreaLength"
    [style.height.px]="textAreaHeight"
    style="resize: none;"
    *ngIf="type==='textarea' else noTextArea" matInput
    [name]="label"
    [formControl]="control"
    [required]="required">
  </textarea>
  <ng-template #noTextArea>
    <ng-container *ngIf="type==='select'; else input">
      <mat-select [formControl]="control" [placeholder]="placeholder">
        <mat-option *ngFor="let option of options" [value]="option.value">{{option.text}}</mat-option>
      </mat-select>
    </ng-container>
    <ng-template #input>
      <input matInput [name]="label" [type]="type"
        [formControl]="control"
        [autocomplete]="autocomplete ?? ''"
        [required]="required"
        [min]="min"
        [max]="max"
        [step]="step"
        [placeholder]="placeholder">
    </ng-template>
  </ng-template>
  <span *ngIf="prefix" matPrefix>{{prefix}}</span>
  <mat-icon *ngIf="iconPrefix" matPrefix>{{iconPrefix}}</mat-icon>
  <span *ngIf="suffix" matSuffix>{{suffix}}</span>
  <mat-hint *ngIf="hint" style="letter-spacing: 0.05em; font-size: 0.74rem" class="text-white">{{hint| translate}}</mat-hint>
  <mat-error *ngIf="control?.errors?.required" >
    {{labelError | translate}}
  </mat-error>
  <mat-error *ngIf="control?.errors?.pattern">
    {{labelPatternError | translate}}
  </mat-error>
  <mat-error *ngIf="control?.errors?.mismatch">
    {{labelMismatchError | translate}}
  </mat-error>
  <mat-error *ngIf="control?.errors?.min">
    {{labelMinError | translate}}
  </mat-error>
  <mat-error *ngIf="control?.errors?.max">
    {{labelMaxError | translate}}
  </mat-error>
</mat-form-field>
