<div id="whitelabel-publish">
  <div class="d-flex justify-space-between align-items-center">
    <ng-container *ngIf="preview">
      <div class="me-5 left-buttons chivo sm bold">
        {{ 'whitelabel.publish.previewMode' | translate }}
      </div>
    </ng-container>
    <ng-container *ngIf="!preview">
      <div class="d-flex align-items-center me-5 left-buttons">
        <button mat-button class="me-2" color="warn" (click)="reset('default')">
          {{ 'whitelabel.publish.resetDefault' | translate }}
        </button>
        <button mat-button (click)="reset()">
          {{ 'whitelabel.publish.resetCurrent' | translate }}
        </button>
      </div>
    </ng-container>
    <div class="d-flex align-items-center">
      <a mat-stroked-button color="green" class="me-4" (click)="goToWhitelabelSettings()" *ngIf="preview">
        {{ 'whitelabel.publish.previewClose' | translate }}
      </a>
      <a mat-stroked-button color="green" class="me-4" href="/admin/preview" *ngIf="!preview">
        {{ 'whitelabel.publish.preview' | translate }}
      </a>
      <button mat-raised-button color="yellow" (click)="publish()">
        {{ 'whitelabel.publish.save' | translate }}
      </button>
    </div>
  </div>
</div>
