<div id="pilio-modal" class="gap-3">
  <div  class="d-flex justify-content-between">
    <div class="title">
      <p class="title-2 text-dark bold"> {{'sidebar.energy' | translate}}</p>
    </div>
    <fa-icon [icon]="faClose" mat-dialog-close></fa-icon>
  </div>
  <div class="d-flex gap-1 my-4 align-items-center">
    <mat-checkbox (click)="toggle($event)" class="example-margin" color="yellow"></mat-checkbox>
    <p class="chivo xs text-dark" [ngClass]="{'bold' : isChecked}">
      {{'sidebar.byTicking' | translate}}
    </p>
  </div>
  <p class="lato sm">
    {{'sidebar.usedSolely' | translate}}
    <a href="https://www.piliogroup.com/legal" target="_blank">
      {{'sidebar.here' | translate}}
    </a>
  </p>
  <div class="d-flex justify-content-end">
    <button class="shadow-none" color="yellow" mat-raised-button (click)="goToPilio()" [disabled]="!isChecked" mat-dialog-close>
      {{'sidebar.enjoyBTN' | translate}}
    </button>
  </div>
</div>
