<div class="container">
  <div class="row gx-2 gy-1">
    <div class="col-12">
      <span class="large-body">{{'gift.giftFor'| translate}}</span>
    </div>
    <div class="col-12 col-md-6">
      <app-mat-input
        [label]="translate.instant('gift.recipientName')"
        [control]="name"
        labelError="authentication.requiredField"
        [required]="true"></app-mat-input>
    </div>
    <div class="col-12 col-md-6">
      <app-mat-input
        [label]="translate.instant('gift.recipientMail')"
        [control]="email"
        autocomplete="email"
        labelError="authentication.requiredField"
        labelPatternError="authentication.pattern"
        [required]="true"></app-mat-input>
    </div>
  </div>
</div>
