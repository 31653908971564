import { BrowserModule, Meta } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarAuthComponent } from './components/navbar-auth/navbar-auth.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './_modules/shared/shared.module';
import { FooterComponent } from './components/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppInjectorService } from './_services/_base/app-injector.service';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuardService } from './_services/_base/auth-guard.service';
import { JwtModule } from '@auth0/angular-jwt';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { SharedService } from './_services/shared.service';
import { UserLayoutComponent } from './layouts/user-layout/user-layout.component';
import { SustainabilityLayoutComponent } from './layouts/sustainability-layout/sustainability-layout.component';
import { WhitelabelLayoutComponent } from './layouts/whitelabel-layout/whitelabel-layout.component';
import { ShopifyConnectionComponent } from './pages/callback/shopify-connection/shopify-connection.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { SharedDataProjectService } from './_services/projects/shared-data-project.service';
import { IvyCarouselModule } from 'angular-responsive-carousel2';
import { environment } from '../environments/environment';
import { ModalComponent } from './components/modal/modal.component';
import { LocaleComponent } from './components/locale/locale.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalEditComponent } from './components/modal-edit/modal-edit.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { BaseComponent } from './pages/_base/base/base.component';
import { PublicNavbarComponent } from './components/navbar-public/public-navbar.component';
import { FooterPublicComponent } from './components/footer-public/footer-public.component';
import { NgxStripeModule } from 'ngx-stripe';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatBadgeModule } from '@angular/material/badge';
import { ShareButtonsDialogComponent } from './dialogs/share-buttons-dialog/share-buttons-dialog.component';
import { GiftCartPopupComponent } from './pages/public/pages/gift-public/components/gift-cart-popup/gift-cart-popup.component';
import { MatNativeDateModule } from '@angular/material/core';
import { GiftEditComponent } from './pages/public/dialogs/gift-edit/gift-edit.component';
import { SidebarAccountComponent } from './components/sidebar-account/sidebar-account.component';
import { AcceptInviteComponent } from './pages/subscriptions/pages/accept-invite/accept-invite.component';
import { InviteEmployeeComponent } from './components/invite-employee/invite-employee.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { ModalPaymentMethodComponent } from './components/modal-payment-method/modal-payment-method.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { PilioModalComponent } from './components/pilio-modal/pilio-modal.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxColorsModule } from 'ngx-colors';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { ExpertDialogComponent } from './components/expert-dialog/expert-dialog.component';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerModule } from "ngx-spinner";

export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function tokenGetter(): any {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    NavbarAuthComponent,
    BaseComponent,
    FooterComponent,
    FooterPublicComponent,
    AdminLayoutComponent,
    SidebarComponent,
    NavbarComponent,
    MenuItemComponent,
    UserLayoutComponent,
    SustainabilityLayoutComponent,
    WhitelabelLayoutComponent,
    ShopifyConnectionComponent,
    ModalComponent,
    LocaleComponent,
    NotificationsComponent,
    ModalEditComponent,
    ModalPaymentMethodComponent,
    PublicLayoutComponent,
    PublicNavbarComponent,
    ShareButtonsDialogComponent,
    GiftCartPopupComponent,
    GiftEditComponent,
    SidebarAccountComponent,
    AcceptInviteComponent,
    InviteEmployeeComponent,
    WelcomeComponent,
    PilioModalComponent,
    ExpertDialogComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    NgxStripeModule.forRoot(environment.stripePublicKey),
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter,
      },
    }),
    MatSidenavModule,
    FormsModule,
    MatTooltipModule,
    MatToolbarModule,
    MatSnackBarModule,
    AngularSvgIconModule.forRoot(),
    MatMenuModule,
    MatDialogModule,
    ScrollingModule,
    MatChipsModule,
    MatStepperModule,
    IvyCarouselModule,
    GoogleMapsModule,
    MatBadgeModule,
    MatNativeDateModule,
    MatTabsModule,
    ColorPickerModule,
    NgxColorsModule,
    MatBottomSheetModule], providers: [
      AuthGuardService,
      SharedService,
      SharedDataProjectService,
      Meta,
      { provide: 'googleTagManagerId', useValue: environment.googleTagManagerId },
      { provide: MAT_DIALOG_DATA, useValue: {} },
      { provide: MatDialogRef, useValue: {} },
      provideHttpClient(withInterceptorsFromDi())
    ]
})

export class AppModule {
  constructor( injector: Injector ) {
    AppInjectorService.injector = injector;
  }
}