export const ProjectSlugs = {
  INDONESIA: 'indonesia',
  MADAGASCAR: 'madagascar',
  CANANDE: 'canande',
  INDIA: 'india',
  YANACOCHA: 'yanacocha',
  BAJO: 'bajo',
  NARUPA: 'narupa',
  JAMAICA: 'jamaica',
  VICENZA: 'vicenza',
  KALIMANTAN: 'kali',
  MAROVOLAVO: 'maro',
  TAMIL: 'tamil',
  COPALINGA: 'copa',
  TAPICHALACA: 'tapi',
  DJABISSA: 'bissa',
  MONTIFERRU: 'ferru',
  ROCCIAVIVA: 'viva',
  POSIDONIA: 'posi',
  ZORAH: 'zora',
  YAS: 'mangrove-preservation-yas-uae',
  SAGAR: 'mangrove-planting-sagar-india',
  FIEMME: 'reteclima-val-di-fiemme',
  PUSTERIA: 'reteclima-val-pusteria',
  AZUL: 'vcs-cordillera-azul'
};
